import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm} from 'react-hook-form';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import { getRequest, postRequest, putRequest } from '../../services/axiosClient';
import { useNavigate } from 'react-router-dom';
import PositionFormSection from './PositionFormSection';
import PositionFormSupervisor from './PositionFormSupervisor';
import PositionFormHeader from './PositionFormHeader';
import PositionFormLocation from './PositionFormLocation';
import PositionFormDisposition from './PositionFormDisposition';
import { setEndDates, sortEffectiveDates } from '../../components/data';
import axios from 'axios';
import PositionFormResource from './PositionFormResource';
import { useDispatch } from 'react-redux';
import { setPositionResource} from '../../redux/features/positionFormSlice';


const PositionFormProvider = props => {
    const {
        position, config, positionId, resourcePosData, supervisorPosData, payGradeData, bravoRepData, 
        ldpData, locationData, dispositionData, getPositionData, setSuccess, costData} = props;
    const baseUrl = getBaseTspmCoreURL();
    const navigate = useNavigate();
    const [apiResult, setApiResult] = useState({status:false, type: null, message:''});
    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();
    /* Setting the default values of the form. 
        rmr_position_resource
        rmr_position_hierarchy
        rmr_position_pay_grade
        rmr_position_bravo_reportable
        rmr_position_ldp
        rmr_position_location
        rmr_position_disposition_function
    */
    const { register, control, handleSubmit, formState: { errors, isDirty }, setValue, getValues,} = useForm({
        defaultValues:{
            position:position, 
            rmr_position_resource: resourcePosData,
            rmr_position_hierarchy:supervisorPosData,
            rmr_position_pay_grade: payGradeData,
            rmr_position_bravo_reportable:bravoRepData,
            rmr_position_ldp:ldpData,
            rmr_position_location:locationData,
            rmr_position_disposition_function:dispositionData,
            rmr_position_cost_center:costData
        },
        mode:'onBlur',
    });

    const onSubmit = data => {
        /* Sorting the effective dates and setting the end dates. */
        Object.keys(data).forEach(key => {
            if (key !== 'position' && key.length > 1) {
                data[key] = setEndDates(sortEffectiveDates(data[key]));
            }
        });
        handleSave(data);
    };
      
    const handleCapacitySave = async record => {
        try {
            const res = await getRequest(baseUrl +'/api/datasrcs/1/schemas/rmr/tables/rmr_resource_capacity/records?resource_wwid=' + record.resource_wwid);
            if (res.data.rowCount === 0) {
                const obj = {
                    resource_wwid: record.resource_wwid,
                    start_date: record.start_date,
                    end_date: null,
                    capacity_percentage: 1,
                };
                await postRequest(baseUrl +'/api/datasrcs/1/schemas/rmr/tables/rmr_resource_capacity/records',obj);
            }
        } catch (error) {
            handleApiError(error);
        }
    };
      
    const handleSave = async data => {
        setSaving(true);
        const apiCallArray = [];
        const positionKeys = Object.keys(data);
        let newId = '';
      
        try {
            if (positionId !== null) {
                await putRequest( baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_position/records/${positionId}`,data.position);
                const promises = positionKeys
                    .filter(key => key !== 'position')
                    .map(key =>
                        postRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/${key}/upload`, data[key])
                    );
                apiCallArray.push(...promises);
      
                if (data.rmr_position_resource.filter(item => !item.id)[0]) {
                    await handleCapacitySave(data.rmr_position_resource.filter(item => !item.id)[0]);
                }
            } else {
                const res = await postRequest( baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_position/records/',data.position);
                const id = res.data.data[0].id;
      
                newId = id;
                const promises = positionKeys
                    .filter(key => key !== 'position')
                    .map(key => {
                        data[key][0].position_id = id;
                        return postRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/${key}/records`, data[key][0]);
                    });
                apiCallArray.push(...promises);
      
                if (data.rmr_position_resource.filter(item => !item.id)[0]) {
                    await handleCapacitySave(data.rmr_position_resource[0]);
                }
            }
        } catch (error) {
            handleApiError(error);
        }
      
        try {
            await axios.all(apiCallArray);
            if (positionId === null) {
                navigate(`/positionForm/${newId}`);
            } else {
                getPositionData();
            }
            handleApiSuccess();
        } catch (error) {
            handleApiError(error);
        }
    };
      
    const handleApiError = error => {
        setApiResult({
            status: true,
            type: 'fail',
            message:'Failed to save changes, please refresh page and try again - ' +
            error.response?.statusText,
        });
        setSaving(false);
    };

    const handleApiSuccess = () => {
        sessionStorage.setItem('position', JSON.stringify({ fetched: false }));
        setSuccess(true);
        setSaving(false);
        dispatch(setPositionResource({ wwid: null, start_date: null }));
    };

    //clearing the message after 5 second
    useEffect(() => {
        const delayTime =5000;
        const timeout = setTimeout(() => {
            setApiResult({status: false, type: '', message: ''});
        }, delayTime);
        
        // clearing the timeout
        return () => {
            clearTimeout(timeout);
        };
    }, [apiResult.status]);
    
    
    const payGradeDataFields = {
        data: {
            fieldName: 'rmr_position_pay_grade',
            name:'pay_grade_id',
            label:'name',
            value: 'id',
            urlData: baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_pay_grade/records?active_flag=true',
            header: 'Pay Grade',
            addBtnName:'Add New Pay Grade Record',
            required:true
        },
        date: {
            name:'start_date',
            header:'Effective Date',
            placeholder:'Effective Date Pay Grade'
        },
        default: {
          
            position_id: positionId,
            pay_grade_id: '',
            start_date:'',
            end_date:'',
        }
    };
    const bravoRepDataFields = {
        data: {
            fieldName: 'rmr_position_bravo_reportable',
            name:'bravo_reportable_id',
            label:'name',
            value: 'id',
            urlData: baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_bravo_reportable/records?active_flag=true',
            header: 'Bravo Reportable',
            addBtnName:'Add New Bravo Reportable Record',
            required:true
        },
        date: {
            name:'start_date',
            placeholder:'Effective Date Bravo Reportable',
            header:'Effective Date'
        },
        default: {
            
            position_id: positionId,
            bravo_reportable_id: '',
            start_date:'',
            end_date:'',
        }
    };
    const ldpDataFields = {
        data: {
            fieldName: 'rmr_position_ldp',
            name:'ldp_id',
            label:'name',
            value: 'id',
            urlData: baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_ldp/records?active_flag=true',
            header: 'LDP',
            addBtnName:'Add New Ldp Record',
            required:true
        },
        date: {
            name:'start_date',
            placeholder:'Effective Date LDP',
            header:'Effective Date'
        },
        default: {
            
            position_id: positionId,
            ldp_id: '',
            start_date:'',
            end_date:'',
        }
    };
    const costDataFields = {
        data: {
            fieldName: 'rmr_position_cost_center',
            name:'cost_center_id',
            label:'name',
            value: 'id',
            urlData: '',
            header: 'Cost Center (ID & Name)',
            addBtnName:'Add New Cost Center Record',
            required:true
        },
        date: {
            name:'start_date',
            placeholder:'Effective Date Cost Center',
            header:'Effective Date'
        },
        default: {
            
            position_id: positionId,
            cost_center_id: '',
            start_date:'',
            end_date:'',
        }
    };

    return (
        <form>
            <div className="rf-nav-btns">
                {!saving ? <button className="btn btn-green" onClick={handleSubmit(onSubmit)} disabled={isDirty ? false : true}>{saving ? <LoadingAnimation type={'dot'} /> : 'Save'}</button> :
                    <button className="btn btn-green" disabled={true}><LoadingAnimation type={'dot'} /></button>}
                {apiResult.status && <span style={{color: 'red'}}>{apiResult.message}</span>}
            </div>
            <PositionFormHeader
                control={control}
                register={register}
                errors={errors}
                baseUrl={baseUrl}
                position={position}
                config={config}
                positionId={positionId}
                setValue={setValue}
                
            />
           
            <div className='position-form-table-group' data-testid='position-group-1'>
                <PositionFormResource
                    control={control}
                    defaultData={resourcePosData}
                    errors={errors}
                    positionId={positionId}
                    getValues={getValues}
                    setValue={setValue}
                />
                <PositionFormSupervisor control={control} defaultData={supervisorPosData} errors={errors} positionId={positionId} getValues={getValues}/>

            </div>
            <div className='position-form-table-group-1' data-testid='position-form-group-2'>
                <PositionFormLocation
                    control={control}
                    defaultData={locationData}
                    errors={errors}
                    positionId={positionId}
                    getValues={getValues}
                />
                <PositionFormDisposition
                    control={control}
                    errors={errors}
                    defaultData={dispositionData}
                    positionId={positionId}
                    getValues={getValues}
                />
            </div>
            
            <div className='position-form-table-group-2'>
                <PositionFormSection 
                    data={payGradeDataFields.data} 
                    date={payGradeDataFields.date} 
                    control={control}
                    defObj={payGradeDataFields.default}
                    defaultData={payGradeData}
                    errors={errors}
                    isAsync={false}
                 
                    name="rmr_pay_grade"
                    positionId={positionId}
                    getValues={getValues}
                /> 
                <PositionFormSection 
                    data={bravoRepDataFields.data} 
                    date={bravoRepDataFields.date} 
                    control={control}
                    defObj={bravoRepDataFields.default}
                    defaultData={bravoRepData}
                    errors={errors}
                    isAsync={false}
               
                    name='rmr_bravo_reportable'
                    positionId={positionId}
                    getValues={getValues}
                /> 
            </div>
            <div className='position-form-table-group-2'>
                <PositionFormSection 
                    data={costDataFields.data} 
                    date={costDataFields.date} 
                    control={control}
                    defObj={costDataFields.default}
                    defaultData={costData}
                    errors={errors}
                    isAsync={false}
       
                    name="rmr_cost_center"
                    positionId={positionId}
                    getValues={getValues}
                />
                <PositionFormSection 
                    data={ldpDataFields.data} 
                    date={ldpDataFields.date} 
                    control={control}
                    defObj={ldpDataFields.default}
                    defaultData={ldpData}
                    errors={errors}
                    isAsync={false}
       
                    name="rmr_ldp"
                    positionId={positionId}
                    getValues={getValues}
                /> 
            </div>
            <br/>
        </form>
    );
};
PositionFormProvider.propTypes = { 
    position: PropTypes.object,
    config: PropTypes.object,
    positionId: PropTypes.any,
    getPositionData: PropTypes.func,
    resourcePosData : PropTypes.array,
    supervisorPosData : PropTypes.array,
    payGradeData: PropTypes.array,
    bravoRepData: PropTypes.array,
    ldpData: PropTypes.array,
    locationData: PropTypes.array,
    dispositionData: PropTypes.array,
    setSuccess:PropTypes.func,
    costData:PropTypes.array
};

export default PositionFormProvider;