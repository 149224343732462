import React,{useEffect, useState} from 'react';
import {DataTable} from '@jauj/reactpkg-tsis-datatable';
import ErrorStatus from '../../components/common/ErrorStatus';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import { getRequest } from '../../services/axiosClient';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';


const PowerBi = () => {
    const baseUrl = getBaseTspmCoreURL();
    // state
    const [power, setPower] = useState({fetched:false, data:[]});
    const [error, setError] = useState({status:false, message:''});

    //getting power bi data
    const getPowerBi = async ()=>{
        try {
            const res =await getRequest( baseUrl + '/api/datasrcs/1/folder/rmr/customQuery/powerbi');
            const columnData = [

            ];
            // setting column definitions
            // for table
            res.data.columns.forEach(item=> {
                columnData.push({
                    accessorKey : item,
                    header: item,
                    minWidth:item=== 'comments' ? 300 : 30,
                });
            });

            //setting state
            setPower({fetched:true, data:res.data.data, colNames: res.data.columns, columns:columnData});
        } catch (err) {
            // displaying error 
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            
            setError({status:true, message:'Error with getting power bi, please refresh page or try again later' + msg});
        }
    };
    useEffect(() => {
        // getting power bi data when component loaded
        getPowerBi();
    }, []);
    
    return (
        <div>
            <h2>Power Bi</h2>
            <br/>
            <ErrorStatus error={error} setError={setError}/>
            {power.fetched ? 
                <DataTable
                    {...{
                        tableData:power.data,
                        columnConfig:power.columns, 
                        disableDownload:false,
                        defaultFileName:'Power Bi', 
                        showFooter:false,
                    }}
                />
                : <LoadingAnimation/>
            }
        </div>
    );
};

export default PowerBi;