import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getMonthNames } from '../../components/data';
import { useNavigate,NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import EditIcon from '@mui/icons-material/Edit';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#387bff',
        color: 'white',
        fontSize: '16px',
        fontWeight: '600',
        padding: 14,
        paddingLeft:5,
        paddingRight:5,
        border:'1px solid #387bff'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: 14,
        paddingLeft:5,
        paddingRight:5,
        // border:'1px solid rgba(224, 224, 224, 1)'
    },
    [`&.${tableCellClasses.td}`]: {
        padding: 14,
        // border:'1px solid rgba(224, 224, 224, 1)'
        
    },
}));

/**
     setting colors for each cell
     */
const getColor = (value, target) => {
    //sonar
    const hndred= 100;
    const overHndred =110;
    const ninety = 90;

    if(target  && (target !== 0)){
        let newValue = (value *  hndred) / target;
        const overHundredCheck = newValue > overHndred  ? 'purple' : 'green';
        return newValue < ninety ? 'red' : overHundredCheck;
    }
    if(target === 0) return 'green';
    const checkOverHundred = value > overHndred  ? 'purple' : 'green';
    return value < ninety ? 'red' : checkOverHundred;
};

const setUserData = (userRes, recovery, date) =>{
    
    const tempArr = [];
    const rowHeader = getMonthNames(date);
    const len = rowHeader.length;  

    const obj = {};
    recovery.forEach(r=> {
        obj[r.resource_wwid] = {[`app_dev_recov_tgt_pct${r.yr}`] : 0};
        obj[r.resource_wwid][`app_dev_recov_tgt_pct${r.yr}`] = r['app_dev_recov_tgt_pct'];
    });
    // here we adding allocation and recovery data to each resource
    userRes.forEach(row => {
        const all = [];
        const rec = [];
        /* Adding the month to the row. from start of initial month to end of year */
        for (let index = 0; index < len; index++) {
            const element = rowHeader[index].ymd;
            const pending = rowHeader[index].ymd + '-p';
            const actual = rowHeader[index].ymd + '-a';
            // setting other fields
            if(rowHeader[index].ymd){
                const year = 4;
                const targetValue = obj[row.resource_wwid]?.['app_dev_recov_tgt_pct' + element.slice(0,year)] || 0;
                all.push({[element]:0, date:element, color:'red'});
                rec.push({[pending]:0, date:element, [actual]:0, actual_pending :false, [element]:0, color:getColor(0, targetValue), pending:false, target:targetValue });
            }
            // setting target field
            if(rowHeader[index].target){
                all.push({[rowHeader[index].target]:'', date:rowHeader[index].target, color:'black'});
                rec.push({[rowHeader[index].target]:0, date:rowHeader[index].target, color:'black'});
            }
            //setting average field
            if(rowHeader[index].average){
                all.push({[rowHeader[index].average]:0,date:rowHeader[index].average, color:'red'});
                rec.push({[rowHeader[index].average]:0,date:rowHeader[index].average, color:'red'});
            }
        }
        
        tempArr.push({...row, allocation:all, recovery:rec});
    });

    return tempArr;
};

const refactorData = (newAlctData, tableData) =>{
    newAlctData.forEach(row => {
        // Loop through each item in the "allocation" property of tableData
        tableData.allocation.forEach(item => {
        // Check if the resource_wwid in the current row of newAlctData matches the resource_wwid in the current item
            if (`${item.resource_wwid}` === `${row.resource_wwid}`) {
                // Get the allocation percent for the current item, or set it to 0 if it's not available
                const alctPercent = item.allocation_pct || 0;
                // Get the average percent for the current item, or set it to 0 if it's not available
                const avgPercent = item.avg_pct || 0;
                // Loop through each allocation object in the "allocation" property of the current row
                row.allocation.forEach(al => {
                    // If the current allocation object has a property with the name of the current item's ymd, update it
                    if (al[item.ymd] !== undefined) {
                        al[item.ymd] = parseFloat(alctPercent);
                        // Set the color of the allocation object using the getColor function and the current allocation percent
                        al.color = getColor(alctPercent);
                    }
                    // If the current allocation object has a property with the name "avg_pct" followed by the current item's year, update it
                    if (al[`avg_pct${item.yr}`] !== undefined) {
                        al[`avg_pct${item.yr}`] = parseFloat(avgPercent);
                        // Set the color of the allocation object using the getColor function and the current average percent
                        al.color = getColor(avgPercent);
                    }
                });
            }
        });
        // Loop through each recovery item in tableData
        tableData.recovery.forEach(item => {
        // Check if resource_wwid of the row matches the resource_wwid of the recovery item
            if (`${item.resource_wwid}` === `${row.resource_wwid}`) {
                const appDevPercent = item.app_dev_recovery_pct || 0;
                const avgPct = item.avg_pct || 0;
                const roundDecimal = 100;
                // Loop through each recovery object in the row
                row.recovery.forEach(rec => {
                    // Get the app development recovery percent, target percentage and average percent values
                    const appDevRecPct = parseFloat(rec.target);       
                    // If the recovery object has the YMD property from the recovery item
                    
                    if (rec[item.ymd] !== undefined) {
                        // If the actual_pending value is 'Pending'
                        if (item.actual_pending === 'Pending') {
                          
                            // Add a pending suffix to the YMD property
                            const pen = item.ymd + '-p';
                            // Assign the app development recovery percent value to the property
                            rec[pen] = parseFloat(appDevPercent);
                            // Set actual_pending and pending values to true
                            rec.actual_pending = true;
                            rec.pending = true;
                        }
                        // If the actual_pending value is 'Actual'
                        if (item.actual_pending === 'Actual') {
                        
                            const act = item.ymd + '-a';
                            rec.color = getColor(appDevPercent, appDevRecPct * roundDecimal);
                            rec[act] = parseFloat(appDevPercent);
                            rec.actual_pending = true;
                        }
                    }
                    // if  recovery object have target value
                    if (rec[`app_dev_recov_tgt_pct${item.yr}`] !== undefined) {
                        rec[`app_dev_recov_tgt_pct${item.yr}`] = item.app_dev_recov_tgt_pct * roundDecimal;
                    }

                    // if recovery object have percent value
                    if (rec[`avg_pct${item.yr}`] !== undefined) {
                        rec[`avg_pct${item.yr}`] = parseFloat(avgPct);
                        rec.color = getColor(avgPct);
                    }
                });
            }
        });

        
    });
    return newAlctData;
};

const ResourceDatagrid = props=> {
    const {tableData, userRes, date} = props;
    const rowHeader = getMonthNames(date);
    const [resources, setResources] = useState({dataReady:false, resourceData:[]});
    const navigate = useNavigate();
    
    // refactored allocation and recovery data 
    const allocationData = () =>{
        // first we will set all month and default values
        const newAlctData = setUserData(userRes, tableData.recovery, date);
        // second we will add values we get from api to data
        const result = refactorData(newAlctData, tableData);
        
        // setting state
        setResources({dataReady:true, resourceData: result});
    };

    // navigate
    const handleEdit =user=>{
        navigate(`/resourceForm/${user.resource_wwid}`);
    };

    useEffect(() => {
        allocationData();

    }, [props]);

    //sonar
    const checkDecimal = 2;
    const fontBold2= 600;
    const fontBold1= 500;
    return (
        <> {resources.dataReady  ?  
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                sx={{
                                    minWidth: 200,
                                    position: 'sticky',
                                    left: 0,
                                    textAlign: 'center',
                                }}
                            >
                                <div style={{display: 'flex',justifyContent: 'space-around'}}>
                                    <span>
                                        Name
                                    </span>
                                    <span>Notes</span> 
                                </div>
                                    
                                    
                            </StyledTableCell>
                                
                            <StyledTableCell></StyledTableCell>
                                
                            {rowHeader.map((row, index) => (
                                <StyledTableCell sx={{ minWidth: 60, textAlign: 'center', backgroundColor:row.backgroundColor, border:`1px solid ${row.backgroundColor}` }} key={index}>
                                    {row.date}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resources.resourceData?.map((resource, index) => (
                            <React.Fragment key={resource.resource_name}>
                                <TableRow sx={{backgroundColor: index % checkDecimal !==0 ? 'rgb(245 249 255)': ''}}>
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        rowSpan={2}
                                        sx={{
                                            position: 'sticky',
                                            left: 0,
                                                
                                            zIndex: 99,
                                        }}
                                    >
                                        <span className='flex' style={{alignItems: 'center',  justifyContent:'space-between', minWidth:'400px', paddingLeft:'10px'}}>
                                            {resource.has_resources_flag === 1  ? 
                                                <>{
                                                    props.type === 'wwid' ? <NavLink to={`/updateMyResources/wwid/${resource.resource_wwid}`} className='link-resource'>
                                                        {resource.resource_name}
                                                    </NavLink> : 
                                                        <NavLink to={`/updateMyResources/positionid/${resource.resource_position}`} className='link-resource'>
                                                            {resource.resource_name}
                                                        </NavLink>} </>
                                                : <span>{resource.resource_name}</span>
                                            }
                                                
                                            <button onClick={() => handleEdit(resource)} className='btn-edit'><EditIcon sx={{fontSize:16}}/>Edit</button>
                                            <textarea className="rf-note-textarea" style={{maxWidth:'170px', minHeight: '95px', margin: '0', opacity:'0.8'}} disabled value={resource.notes_actions}/>
                                        </span>
                                            
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                            Total <br/> Allocation
                                    </StyledTableCell>
                                    {resource.allocation?.map((item, i) => (
                                        <StyledTableCell sx={{ textAlign: 'center', color:item.color, fontWeight:item.date.includes('-') ? fontBold1 : fontBold2 }} key={i} >
                                            {item[item.date] !== '' ? parseFloat(item[item.date]).toFixed(1)+ '%' : '' }
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                                <TableRow key={resource.resource_wwid} sx={{backgroundColor: index % checkDecimal !==0 ? 'rgb(245 249 255)': ''}}>
                                    <StyledTableCell align="right">
                                            App Dev Recovery
                                    </StyledTableCell>
                                    {resource.recovery?.map((item, k) => (
                                        <StyledTableCell sx={{ textAlign: 'center',fontWeight:item.date.includes('-') ? fontBold1 : fontBold2 } } key={k} >                                     
                                            {item.actual_pending ? 
                                                <div className='rs-span-data'>
                                                    <span style={{color:item.color}}>  {parseFloat(item[item.date + '-a']).toFixed(1)}% </span>
                                                    {item.pending && <span style={{fontStyle:'italic'}}> {parseFloat(item[item.date + '-p']).toFixed(1)}% </span>}
                                                </div> : 
                                                <span style={{color:item.color}}>{parseFloat(item[item.date]).toFixed(1)}%</span>
                                            }
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>:
    
            <LoadingAnimation/>
    
        }
            
        
        </>
        
    );
};

ResourceDatagrid.propTypes ={
    tableData: PropTypes.object.isRequired,
    type: PropTypes.string,
    userRes: PropTypes.array.isRequired,
    date:PropTypes.string,
    
};
export default ResourceDatagrid;
