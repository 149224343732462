import React,{useEffect} from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import logPageView from '../../utils/logPageView';
import { tableauLinks } from '../../components/data';
import './dashboard.css';
import PropTypes from 'prop-types';


const Dashboard = props => {
    /*
    get admin state and params
    */
    const {isAdmin} = props;
    const params = useParams(props);
    

    /* 
     save page visit
    */
    useEffect(() => {
        logPageView();
    }, [params]);
    
    
    return (
        <div className="dashboard-container">
            <div className="dashboard-nav-panel">
                {tableauLinks.map(link => (
                    <NavLink
                        to={`/dashboard/${link.id}`}
                        key={link.id}
                        className={({ isActive }) =>
                            isActive ? 'link link-active' : 'link'
                        }
                    >
                        {link.name}
                    </NavLink>
                ))}
                {isAdmin && (
                    <NavLink
                        to={'/dashboard/powerBi'}
                        key={'power_bi'}
                        className={({ isActive }) =>
                            isActive ? 'link link-active' : 'link'
                        }
                    >
                        Power BI
                    </NavLink>
                )}
            </div>
            <div className="dashboard-tableau-container">
                <Outlet />
            </div>
        </div>
    );
};

Dashboard.propTypes = {
    isAdmin: PropTypes.bool,
};

export default Dashboard;
