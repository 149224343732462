import React, {useState, useEffect, useCallback} from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import {  setEndDates, debounce, getFirstDayOfDate, checkResource} from '../../components/data';
import { getRequest } from '../../services/axiosClient';
import AsyncSelect from 'react-select/async';
import CustomPopup from '../../components/common/customPopup/CustomPopup';
import { useDispatch, useSelector } from 'react-redux';
import { positionResource , positionStatus, setPositionResource} from '../../redux/features/positionFormSlice';
import ErrorStatus from '../../components/common/ErrorStatus';
import CustomDatePicker from '../../components/common/datePicker/CustomDatePicker';
import { backendUrls } from '../../utils/constants';

const checkRequired = (formData, isRequired) => {
    // Get today's date
    const today = new Date();
    const tempFields = setEndDates([...formData]);
    // Create an object to store the required field status of each position
    let requiredFields = [];
    // Check if the fields should be required
    if (isRequired) {
        //iterate over the formData
        tempFields.forEach(position => {
        // Get the end date of the position
            let endDate = new Date(position.end_date);
            // Check if the end date is greater than or equal to today or it's null or empty
            if (endDate >= today || position.end_date === null || position.end_date === '') {
                // If it's a current position set the required field to true
                requiredFields.push({required:true});
            } else {
                // If it's not a current position set the required field to false
                requiredFields.push({required:false});
            }
        });
    } else {
        //if it's not required
        formData.forEach(()=> {
        // set all required fields to false
            requiredFields.push({required:false});
        });
    }
    return requiredFields;
};



const SelectResource = props => {
    const dispatch = useDispatch();
    // Access the global state for the `positionResource`
    const resource = useSelector(positionResource);
    const { field, defaultValue } = props;
  
    // get options from given url with input text added at the end
    const getOptionData = async (inputText, callback) => {
        const json = await getRequest(`${props.urlReq}${inputText}`);
        if(props.urlReq.includes('employee_status')){
            callback(json.data.data.filter(record => record.employee_status === 'ACTIVE'));
        } else {
            callback(json.data.data);
        }
    };
    
    // calling getOptionData function with delay
    const setOptionDataDebounced = useCallback(debounce(getOptionData), []);
    
    // setting label for fields
    const setOptionLabel = option => {
        const label = option[props.defaultKeyNames.label] || '';
        const value = option[props.defaultKeyNames.value] || '';
        return props.hideId ? label : `${value} - ${label}`;
    };
    
    // handle change if it is new resource we store position resource
    // in global state
    const handleChange = value => {
        field.onChange(parseInt(value[props.defaultKeyNames.value]));
        if (props.newResource) {
            dispatch(
                setPositionResource({
                    ...resource,
                    wwid: value[props.defaultKeyNames.value],
                })
            );
        }
    };
  
    return (
        <AsyncSelect
            onChange={handleChange}
            loadOptions={setOptionDataDebounced}
            cacheOptions
            getOptionLabel={setOptionLabel}
            getOptionValue={option => option[props.defaultKeyNames.value]}
            ref={field.ref}
            name={props.name}
            value={defaultValue.find(c => c[props.defaultKeyNames.value] === field.value)}
            menuPlacement="auto"
            menuPortalTarget={document.body}
        />
    );
};


const baseUrl = backendUrls.rmr_resource;


  
const PositionFormResource = props => {
    const {control, defaultData, errors, positionId, getValues,  setValue} = props;
    const [openPopup, setOpenPopup] = useState({popupStatus:false, message:{}});
    const [error, setError] = useState({status:false, message:''});
    const [requiredFields, setRequiredFields] =useState([]);
    const resource = useSelector(positionResource);
    const posStatus = useSelector(positionStatus);
    const dispatch = useDispatch();  
    const [loading, setLoading] = useState(true);
    const { fields, append, remove} = useFieldArray({
        control,
        name: 'rmr_position_resource',
    });
    
    /**
        handling date change
     */
    const handleDateChange = (e, field,tableName,index)=>{
        setLoading(true);
        const newDate = getFirstDayOfDate(e);
        // getting current dates and check if newDate exists in our field array
        const dates = getValues(tableName);
        const dateObj= {};
        dates.forEach((item,i) => {
            if(i!==index){
                dateObj[item.start_date] = 1;
            }
        });

        //we will give error based od date exist or not
        if(dateObj[newDate] === 1){
            alert(`You cannot have more than one record with the same start date ${newDate}`);
        } else{
            field.onChange(newDate);
            if((index+1) === fields.length)dispatch(setPositionResource({...resource, start_date:newDate, index}));
            const requiredObj = checkRequired(dates, posStatus);
            setRequiredFields(requiredObj);
        }
        setLoading(false); 
    };

    /**
     adding new field to form
     */
    const handleAdd = e =>{
        const defObj = {position_id: positionId, resource_wwid: '', start_date:'', end_date:''};
        e.preventDefault();
        append(defObj);
    };

    /**
     removing last field of form
     */
    const handleRemove = e=>{
        e.preventDefault();
        dispatch(setPositionResource({wwid:null, start_date:null}));
        remove(fields.length-1);
    };
    
    /* 
    Checking if the 
    required prop is true or false 
    and setting the requiredFields state 
    based on that. 
    */
    useEffect(()=>{
        setLoading(true);
        const requiredObj = checkRequired(getValues('rmr_position_resource'), posStatus);
        setRequiredFields(requiredObj);
        setLoading(false);
    },[posStatus]);

    
    useEffect(()=>{
        dispatch(setPositionResource({wwid:null, start_date:null}));
    },[]);

    const getCheckStatus = async()=>{
        
        const result = await checkResource(resource);
        if(result.errorStatus) {
            setError({status:true, message:result.message});
        }
        setOpenPopup({popupStatus:result.popupStatus, message:result.message});
        
    };

    useEffect(()=>{
        if(resource.wwid !==null && resource.start_date !==null) {

            getCheckStatus(); 
        }

    }, [resource.wwid, resource.start_date]);

    const handleClose = ()=>{
        if(positionId === null){
            setValue('rmr_position_resource.0.resource_wwid', '');
            dispatch(setPositionResource({...resource, wwid:null}));
        } else{
            setValue(`rmr_position_resource.${resource.index}.start_date`, '');
            setValue(`rmr_position_resource.${resource.index}.resource_wwid`, '');
            dispatch(setPositionResource({start_date:null, wwid:null}));
        }
        setOpenPopup({popupStatus:false, message:''});
    };

    
    return (
        <section className='position-form-section'>
            {openPopup.popupStatus && 
                <CustomPopup>
                    
                    <div className='rmr-position-popup'>
                        <h3 style={{fontSize:'18px'}}>{openPopup.message}</h3>
                        <button data-testid='delete-btn' className='btn btn-blue' style={{width:'80px'}} onClick={()=>handleClose()}>OK</button>
                    </div>
                
                </CustomPopup>}
            <ErrorStatus error={error} setError={setError}/>
            <table className='position-table-field'>
                <thead> 
                    <tr> 
                        <th className="">
                        Assigned Resource (WWID & Name)
                        </th>
                        <th style={{width: '200px'}}>
                        Effective Date
                        </th>
                    </tr>
                </thead>
                {!loading && <tbody data-testid='rmr_position_resource_table'>
                    {
                        fields.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    
                                    <Controller
                                        control={control}
                                        name={`rmr_position_resource.${index}.${'resource_wwid'}`}
                                        rules={{ required:requiredFields[index]?.required}}
                                        defaultValue={item['resource_wwid']===null ? '' : item['resource_wwid']}
                                        render={field => (
                                            <SelectResource  
                                                urlReq={baseUrl} 
                                                defaultValue={[{['display_name']:item[`${'resource_wwid'}_dv`], ['wwid']:item['resource_wwid']===null ? '' : item['resource_wwid']}]} 
                                                defaultKeyNames={{label:'display_name', value: 'wwid'}} 
                                                field={field.field} 
                                                name={`rmr_position_resource.${index}.${'resource_wwid'}`} 
                                                hideId={false}
                                                newResource={(index+1) === fields.length ? true : false}
                                            /> 
                                        )}/>
                                    {errors['rmr_position_resource']?.[index]?.['resource_wwid'] && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}

                                </td>
                                <td>
                                    
                                    <Controller
                                        name={`rmr_position_resource.${index}.${'start_date'}`}
                                        control={control}
                                        defaultValue={item['start_date']}
                                        rules={{required: true}}
                                        render={({
                                            field
                                        }) => (
                                            <CustomDatePicker
                                                isDisabled={positionId !== null ? false : true}
                                                selectedDate={field.value}
                                                onChange={handleDateChange}
                                                getValues={getValues}
                                                data={{field, tableName:'rmr_position_resource', index }}
                                            />
                                        )}/>
                                    {errors['rmr_position_resource']?.[index]?.['start_date'] && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                </td>
                            </tr>
                        
                        ))
                    }
                 
                </tbody>}
            </table>
            {positionId && <div className='posForm-btn-group'>
                <button className='btn btn-green' 
                    onClick={handleAdd}
                    disabled ={fields.length > defaultData.length ? true : false }
                >Add New Assigned Resource Record</button>
                {fields.length > defaultData.length  ?  <button className='btn btn-red' 
                    onClick={handleRemove}
                > Remove Last Record</button> :<></>
                }
            </div>}

        </section>
    );
};

PositionFormResource.propTypes = {
    control: PropTypes.object,
    defaultData: PropTypes.array,
    errors: PropTypes.object,
    positionId: PropTypes.any,
    getValues:PropTypes.func,
    setValue:PropTypes.func,
};

SelectResource.propTypes = {
    urlReq: PropTypes.string,
    defaultValue: PropTypes.any,
    defaultKeyNames: PropTypes.object,
    field:PropTypes.any,
    name:PropTypes.string,
    isMulti: PropTypes.bool,
    hideId: PropTypes.bool,
    newResource:PropTypes.bool,
};

export default PositionFormResource;