import React,{ forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { handleDateChange } from '../../data';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import './customDatePicker.css';
// CustomInput component that takes in several props 
// including value, onClick, onChange, testId and disabled
export const InputDatePicker = forwardRef(({ value, onClick, onChange, testId, isDisabled}, ref) => (
    <div style={{position:'relative'}}>
        <input 
            onClick={onClick} 
            ref={ref}
            id="rf-date"
            value={value} 
            onChange={onChange} 
            placeholder='mm/dd/yyyy'
            // test id for the input
            data-testid={testId} 
            readOnly={isDisabled} 
            disabled={isDisabled}
            className='date-picker-input'
        />
        {!isDisabled && <span className='date-picker-icon' onClick={onClick}><CalendarTodayIcon sx={{ fontSize: 14 }}/></span>}
        
    </div>
    
));

InputDatePicker.displayName = 'CustomDatePickerInput';

// Main component CustomDatePicker
const CustomDatePicker = props => {
    const {isDisabled, getValues, selectedDate, data} = props;
    // css  changes are in position.css file
    const records = getValues(data.tableName);
    const getSelected = date => date === '' ? null : parseISO(date);
    return (
        <DatePicker
            // sets the selected date
            selected={getSelected(selectedDate)} 
            // change handler for the date picker
            onChange={date => props.onChange ? props.onChange(date, data.field, data.tableName, data.index) :  handleDateChange(date, data.field, records, data.index)} 
            // custom input component
            // this is only needed for to add test id
            customInput={<InputDatePicker testId={data.tableName} value={selectedDate} isDisabled={isDisabled}/>} 
            disabled={isDisabled} 
            placeholderText='mm/dd/yyyy'
            showMonthDropdown 
            showYearDropdown
            useShortMonthInDropdown
            yearDropdownItemNumber={8}
      
        />
    );
};

// PropTypes for the Input component
InputDatePicker.propTypes = {
    value: PropTypes.string,
    onClick:PropTypes.func,
    onChange:PropTypes.func,
    testId:PropTypes.string,
    isDisabled:PropTypes.bool,
};

// PropTypes for the CustomDatePicker  component
CustomDatePicker .propTypes = {
    isDisabled: PropTypes.bool,
    selectedDate: PropTypes.string,
    getValues:PropTypes.func,
    data:PropTypes.object,
    onChange:PropTypes.any,
};

export default CustomDatePicker ;