import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import { getRequest } from '../../services/axiosClient';
import {DataTable} from '@jauj/reactpkg-tsis-datatable';
import { getFourMonth } from '../../components/data';
import { NavLink } from 'react-router-dom';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import ErrorStatus from '../../components/common/ErrorStatus';

const numberList = {
    actual_hours: 'Actual Hours',
    target_hours: 'Target Hours',
    m0_pct:true, 
    m1_pct:true,
    m2_pct:true,
    m3_pct:true,
    app_dev_recov_tgt_pct: 'App Dev Recov Target %',
    hrs:'Hours',
    recovery_pct:'Recovery %',
    app_dev_target:'App Dev Target',
    am_target:'AM Target',
    capacity:'Capacity',

};
const getErrorMsg =err=> err.request?.statusText ? ` - ${err.request.statusText}!` : '!';
const DashboardTabs = props => {
    const {url, name} =props;
    const baseUrl = getBaseTspmCoreURL();
    const [columns, setColumns] = useState();
    const [tableData, setTableData] = useState({fetched:false});
    const months = getFourMonth();
    const [error, setError] = useState({status:false, message:''});
    
    
    const firstM = 0;
    const secondM =1; 
    const thirdM = 2;
    const lastMonth = 3;
    // map list for names of table column
    // it will be used to
    const nameList = {
        resource_wwid:'Wwid',
        display_name: 'Resource',
        name_manager:'Manager',
        name_lvl_4:'VP',
        skills:'Skills',
        notes_actions: 'Notes',
        actual_hours: 'Actual Hours',
        target_hours: 'Target Hours',
        // getting month for 4 month period
        m0_pct:months[firstM], 
        m1_pct:months[secondM],
        m2_pct:months[thirdM],
        m3_pct:months[lastMonth],
        legacy: 'Legacy',
        disposition: 'Disposition',
        yr:'Year',mo:'Month', 
        app_dev_recov_tgt_pct: 'App Dev Recov Target %',
        hrs:'Hours',
        recovery_pct:'Recovery %',
        vp:'VP',
        wwid:'WWID',
        app_dev_target:'App Dev Target',
        am_target:'AM Target',
        capacity:'Capacity',
        roles:'Roles',
        technologies:'Technologies',
        initiative_id: 'Id',
        initiative_name: 'Initiative Name',
        initiative_description:'Initiative Description',
        start_date: 'Start Date',
        end_date: 'End Date',
        owner_name:'Owner Name',
        project_id : 'Project Id',
        project_name:'Project Name',
        resource_name:'Resource Name',
        'Type':'Type',
        'C Area/P Group': 'C Area/P Group',
        'Chap/Platform': 'Chap/Platform',
        'Sub Chapter': 'Sub Chapter',
        position_id:'Position ID'

    };

    const getData = async() => {
        setTableData({fetched:false});
        try {
            const colDesc = [];
            const res  = await getRequest(baseUrl + url);

            // setting column definitions and adding to columns array
            res.data.columns.forEach((item, index)=> {
                if(index === 0){
                    const def= {
                        accessorFn : row => row[item],
                        // getting header from nameList object
                        header: nameList[item],
                        id: item,
                        minWidth: 80,
                    };
                    const defMain= {
                        accessorFn : row => row[item],
                        // getting header from nameList object
                        header: nameList[item],
                        id: item,
                        minWidth: 80,
                        cell:info => (
                            <NavLink to={`/resourceForm/${info.getValue()}`} className={'initiative-link'}>{info.getValue()}</NavLink>
                        ),
                    };

                    // here only available resource list have link in its id so we add different column definition with link property
                    if(name === 'Available Resources List'){
                        colDesc.push(defMain);
                    } else{
                        colDesc.push(def);
                    }

                    
                } else {

                    // adding rest of column definitions
                    const def = {
                        accessorKey:item, 
                        // getting header from nameList object
                        header:nameList[item],
                        minWidth: 100,

                    };
                    if(numberList[item]){
                        def.dataType = 'number',
                        def.filterFn = 'numericFilter';
                    }
                    if(item === 'start_date' || item === 'end_date'){
                        def.dataType = 'date',
                        def.filterFn = 'dateFilter';
                    }
                    colDesc.push(def);
                }
                

            });
            
            // setting column and table data
            setColumns(colDesc);
            // state assign
            setTableData({fetched:true, data:res.data.data, colNames:res.data.columns});
        } catch (err) {
            //setting error
            const msg = getErrorMsg(err);
            setError({status:true, message:'Error with getting position table, please refresh page or try again later' + msg});
        }
    };
    
    useEffect(() => {

        // get table data with every link change
        getData();
    },[name]);
    // if name is Available Resources List
    // will show 3 month  header with months
    // if not will only show table name
            
    return (
        <div>

            <h2>{name === 'Available Resources List' ? 
                `Available Resources with less than 50% recovery in the current plus next 3 months (${months[firstM]} - ${months[lastMonth]})`
                : name}</h2>
            <br />
            <ErrorStatus error={error} setError={setError} />
            {tableData.fetched ? 
                <DataTable
                    {...{
                        tableData:tableData.data,
                        columnConfig:columns , 
                        disableDownload:false,
                        defaultFileName:name, 
                        showFooter:false,
                    }}
                />: <LoadingAnimation />}
        </div>
    );
};
DashboardTabs.propTypes = {
    url: PropTypes.string,
    name: PropTypes.string,

};

export default DashboardTabs;