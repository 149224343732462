import React, {useState, useEffect} from 'react';
import CustomAsyncSelect from '../../components/common/customSelect/CustomAsyncSelect';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import PropTypes from 'prop-types';
import { getRequest } from '../../services/axiosClient';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import ErrorStatus from '../../components/common/ErrorStatus';

const handleSort = (selectedOption, defaultOptions, initiativeId) => {
    let dat = {deleted:[], added:[]};

    selectedOption.forEach(option=> {
        const newItem = {project_id: option.project_id, initiative_id: initiativeId};
        const delItem = `"project_id":"${option.project_id}"`;
        if(!JSON.stringify(defaultOptions).includes(delItem)){
            dat.added.push(newItem);
        }
    });

    /* Checking if the selectedOption[each.name] is not included in the option. If it is not
        included, it will push the newItem to the deleted array. If the addedItem is included
        in the added array, it will pop the addedItem. */
    defaultOptions.forEach(option => {
        const newItem = {project_id: option.project_id, id: option.id};
        const addedItem = `"project_id":"${option.project_id}"`;
        if(!JSON.stringify(selectedOption).includes(addedItem) ){
            dat.deleted.push(newItem);
        }
    });
   
    return dat;
};

const InitiativeFormProject = props => {
    const {setEdited,  biRef, edited, initiativeId} = props;
    
    const baseUrl = getBaseTspmCoreURL();
    const urlProjects= baseUrl +'/api/datasrcs/1/schemas/rmr/tables/rmr_project/records?fields-all=project_id,name&record-limit=10&search-fields=project_id,name&search-term=';
    const projectRelUrl = baseUrl +`/api/datasrcs/1/schemas/rmr/tables/rmr_project_initiative_rel/records?display-value=true&initiative_id=${initiativeId}`;
    const [error, setError] = useState({status:false, message:''});

    /* COMPONENT STATE */
    const [projects, setProjects] = useState({fetched:false});
    const [defaultOptions, setDefaultOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    
    /**
     * I'm going to get the default values from the server, and then set the default options to the new
     * project data.
     */
    const getDefaultValues = async () => {
        try {
            const res_project = await getRequest(projectRelUrl);
            const newProjectData = res_project.data.data.map(item => ({project_id:item.project_id, name:item.project_id_dv, id:item.id  }));
            setProjects({fetched: true, data:newProjectData});
            setDefaultOptions(newProjectData);

        } catch (err) {
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting default projects, please refresh page or try again later' + msg});
        }
    };

    useEffect(() => {
        /* 
        Checking if the initiativeId is not equal to 'new'. If it is not equal to 'new', it will
        call the getDefaultValues function. 
        */
        if(initiativeId !== 'new'){
            getDefaultValues();
        }
    }, []);
    
    

    /**
     * If the user has not edited the form, set the edited state to true and set the selectedOption
     * state to the data passed in.
     */
    const handleOptionChange = data=>{
        if(!edited) setEdited( prevState => ({...prevState, project: true}));
        
        setSelectedOption([...data]);
    };

    
    const handleSortFunc = ()=> handleSort(selectedOption, defaultOptions, initiativeId);
   
    

    // assigning functions to object 
    // we will call it from upper component
    biRef.getProjectOptions = handleSortFunc;
    biRef.getProjectDefaultValues = getDefaultValues;

    if(initiativeId === 'new'){
        return  <div>
            <label className='initiative-form-label'>
                <span>Projects</span>
                
                <CustomAsyncSelect 
                    urlReq={urlProjects} 
                    defaultKeyNames={{label:'name', value:'project_id'}} 
                    name='project_id'
                    isMulti={true}
                    handleChange={handleOptionChange}
                /> 
            </label>
        </div>;
    }
    
    return (
        <div>
            <label className='initiative-form-label' data-testid='initiative-demand-project'>
                <span>Projects</span>
                <ErrorStatus error={error} setError={setError}/>
                {projects.fetched ? 
                    <CustomAsyncSelect 
                        urlReq={urlProjects} 
                        defaultKeyNames={{label:'name', value:'project_id'}} 
                        name='project_id'  
                        isMulti={true}  
                        defValue={projects.data} 
                        handleChange={handleOptionChange}
                    /> : <div className='rf-select-placeholder-div' style={{width: '300px', height: '45px'}}> <LoadingAnimation type={'dot'}/></div>}    
            </label>
        </div>
    );
};


InitiativeFormProject.propTypes = {
    biRef: PropTypes.object,
    edited: PropTypes.bool,
    setEdited: PropTypes.func,
    initiativeId: PropTypes.string
};


export default InitiativeFormProject;