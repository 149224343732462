import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {DataTable} from '@jauj/reactpkg-tsis-datatable';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';

const setRowData = (sowData, month) => {
    // default column definition
    const colData = [

        {
            accessorKey: 'sow_number',
            disableFilter: true,
            header: 'SOW',
            minWidth: 130,
            footer: 'Total',
            cell:info => (
                info.row.original.url ? <a href={info.row.original.url} className={'initiative-link'} target='_blank' rel="noreferrer">{info.getValue()}</a> : info.getValue()
            ),
        }, {
            accessorKey: 'project_id',
            disableFilter: true,
            header: 'Project ID',
            minWidth: 130,
            footer: ''
        }];
    const newSowData = [];
    const hashObj = {};
    let startMonth = 12;
    const maxLength = 13;
    const fixedDecimal = 2;
    /* Creating a new array with unique sow_id and sow_number and project_id. */
    sowData.forEach(row => {
        if (row.mo < startMonth) { startMonth = row.mo; }
        if (!hashObj[row.sow_id]) {
            newSowData.push({ sow_id: row.sow_id, sow_number: row.sow_number, project_id: row.project_id, url: row.ts_engage_url});
            hashObj[row.sow_id] = 1;
        }
    });



    newSowData.forEach(row => {
        /* Adding the month to the row. from start of initial month to end of year */
        for (let index = startMonth; index < maxLength; index++) {
            const element = month[index];
            row[element] = 0;
        }

        /* Adding the hours to the added month */
        sowData.forEach(item => {
            if (item.sow_id == row.sow_id) {
                row[month[item.mo]] = item.hours;
            }
        });
    });

    // looping through month and setting
    // columns information for sow section
    for (let index = startMonth; index < maxLength; index++) {
        const element = month[index];
        let sum = 0;
        // setting sum for total field
        newSowData.forEach(item => {
            sum = parseFloat(item[element]) + sum;
        });
     

        //defining default column info, sum is for footer area
        const colinfo = {
            accessorKey: element,
            disableFilter: true,
            header: element,
            minWidth: 85,
            footer: sum.toFixed(fixedDecimal)
        };

        // adding column definition
        colData.push(colinfo);
    }

    return {columns:colData, sowData: newSowData};
    
};

const ResourceSowSection = props => {
    const { data, type, year } = props;

    //month obj
    const month = {
        1: `${year} Jan`, 2: `${year} Feb`, 3: `${year} Mar`, 4: `${year} Apr`, 5: `${year} May`, 6: `${year} Jun`,
        7: `${year} Jul`, 8: `${year} Aug`, 9: `${year} Sep`, 10: `${year} Oct`, 11: `${year} Nov`, 12: `${year} Dec`
    };

    /* Setting the state of the component. */
    const [columns, setColumns] = useState();
    const [rows, setRows] = useState();
    const [loading, setLoading] = useState(true);

    
    useEffect(() => {
        // setting columns and loading
        const result = setRowData(data.data, month);
        setColumns(result.columns);
        setRows(result.sowData);
        setLoading(false);
    }, []);



    return (
        <div className='resource-sow-section'>
            {data.rowCount === 0 ? <>There are no SOWs with {type} hours for this resource.</> :
                <div className='resource-sow-table'>
                    <div className='resource-sow-section-title '>SOW Details: {type} Hours Allocated to Each SOW by Month</div>
                    {loading ? <LoadingAnimation type={'dot'} /> :
                       
                        <DataTable
                            {...{
                                tableData:rows,
                                columnConfig:columns, 
                                disableDownload:false,
                                defaultFileName:type, 
                                showFooter:true,
                            }}
                        />
                            
                            
                    }
                </div>}
        </div>
    );
};

ResourceSowSection.propTypes = {
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
};
export default ResourceSowSection;