import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

const ErrorStatus = props => {
    const { error, setError} = props;
    const waitTime = 7000;
    useEffect(() => {

        // we will wait 7 seconds and then remove error message
        const timeout = setTimeout(() => {
            setError({status: false, message: ''});
        }, waitTime);
        
        // clear timeout
        return () => {
            clearTimeout(timeout);
        };
    }, [error.status]);

    //if error status is false then dont show anything
    if(!error.status) {
        return (<></>);
    }
    
    return (
        <div style={{color:'red', marginBottom:'10px'}} data-testid='error-test'>{error.message}</div>
    );
};
ErrorStatus.propTypes = {
    error: PropTypes.object.isRequired,
    setError: PropTypes.func.isRequired,
};
export default ErrorStatus;