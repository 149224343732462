import React, { useEffect } from 'react';
import './home.css';
import HomeDelegates from './HomeDelegates';
import HomeResources from './HomeResources';
import { useSelector } from 'react-redux';
import { user } from '../../redux/features/userSlice';
import logPageView from '../../utils/logPageView';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';

const Home = () => {
    //getting user data from redux store
    const userData = useSelector(user);
    const baseUrl = getBaseTspmCoreURL();
    const urlResource = baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/individual_resource_view?filters=${userData.wwid}`;
    
    // saving log view data
    // it will only save once
    useEffect(() => {
        logPageView();
    },[]);
    
    return(
        <div className='rmr-home' data-testid="home-page"> 
            <HomeResources userWwid={userData.wwid} url={urlResource}/>
            <HomeDelegates userWwid={userData.wwid}/>
        </div>
    );
};

export default Home;
