import React,{useEffect} from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import './initiativeForm.css';


const InitiativeFormNavigate = () => {
    const navigate = useNavigate();
    const params = useParams();

    /* Redirecting to the 
    new page if the 
    initiativeId is
     not present. */
    useEffect(() => {
        if (!params.initiativeId) {
            navigate('/initiativeForm/new');
        }
    },[]);

    return (
        <div>
            <h1>Initiative</h1>
            <Outlet/>
        </div>
    );
};

export default InitiativeFormNavigate;