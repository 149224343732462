import React, {useEffect, useState} from 'react';
import ErrorStatus from '../../components/common/ErrorStatus';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import { getRequest } from '../../services/axiosClient';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';

const getValue = (row, field) => {
    const value = row[field] ? row[field] : '';
    return `${value}`;
};


// sorting and clearing the data
const sortProjectData = projects => {
    let projectData = [];
    let currentProject = {};
    let currentInitiative = {};
    let currentDemand = {};
    
    for (const row of projects) {
        let project_id = `${row['project_id']}`;
        let initiative_id = `${row['initiative_id']}`;
        let demand_id = `${row['demand_id']}`;
        let allocationId = `${row['initiative_demand_allocation_id']}`;
            
        // check if project id is not same as current porject id
        // if not replace current project and push it to 
        // project data array
        if (project_id !== currentProject.project_id) {
            currentProject = { project_id, initiative: [] };
            projectData.push(currentProject);
        }
    
        //same process for initiative 
        if (initiative_id !== currentInitiative.initiative_id) {
            currentInitiative = {
                initiative_id,
                initiative_name: getValue(row, 'initiative_name'),
                description: getValue(row, 'description'),
                start_date: getValue(row, 'start_date'),
                end_date: getValue(row, 'end_date'),
                demand: []
            };
            currentProject.initiative.push(currentInitiative);
        }
            
        /* This is checking if the demand_id is not the same as the currentDemand.demand_id. 
            If it is not, then it is setting the currentDemand to the new demand_id, role, skill, technology,
            resource_wwid, resource_name, and allocation. 
            Then it is pushing the currentDemand to
            the currentInitiative.demand. */
        if (demand_id !== currentDemand.demand_id) {
            currentDemand = {
                demand_id,
                role: getValue(row, 'role'),
                skill: getValue(row, 'skill'),

                technology: getValue(row, 'technology'),
                resource_wwid: getValue(row, 'resource_wwid'),
                resource_name: getValue(row, 'resource_name'),
                allocation: []
            };
            currentInitiative.demand.push(currentDemand);
        }
    
        currentDemand.allocation.push({
            allocation_id: allocationId,
            yr: getValue(row, 'year'),
            mo: getValue(row, 'month'),
            hrs: getValue(row, 'hours')
        });
    }
    
    return projectData;
  
};
const DownloadRmrData = () => {
    const baseUrl = getBaseTspmCoreURL();
    const [data, setData] = useState({fetched: false, data:[]});
    const [error, setError] = useState({status:false, message:''});

    

    /**
     * "getProjectData" is an async function that makes a get request to an API, sorts the data, and
     * then sets the state of the data to the sorted data.
     */
    const getProjectData = async ()=>{
        try {
            const res = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/v_rmr_project_id_detailed_data_api/records');
            
            const newData = sortProjectData(res.data.data);
            setData({fetched:true, data:newData});
        } catch (err) {
            const msg = err.request?.statusText ? ` - ${err.request?.statusText}!` : '!';
            setError({status:true, message:'Unable to get download data, please try later' + msg});
        }
    };
    
    useEffect(() => {
        getProjectData();
    }, []);
    
    
    /**
     * Takes the data from the API and converts it into a JSON file, 
     * assign data to download link and start download.
     */
    const handleDownload = () => {
        const indentation =2;
        const json = JSON.stringify(data.data,undefined,indentation);
        const blob = new Blob([json], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            'results.json',
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    return (
        <div>
            <ErrorStatus error={error} setError={setError}/>
            {data.fetched ? 
                <button className='btn btn-blue' onClick={handleDownload}>Download</button>  : <LoadingAnimation type={'dot'} />
            }
        </div>
    );
};

export default DownloadRmrData;