import React,{useEffect} from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';


const PositionFormNavigate = () => {
    const navigate = useNavigate();
    const params = useParams();
    
    /* Checking if the positionId is not present in the URL, then it will navigate to the URL with
    positionId 0. */
    useEffect(() => {
        if (!params.positionId) {
            navigate('/positionForm/new');
        }
    },[]);

    return (
        <div>
            <Outlet/>
        </div>
    );
};
    
    
 

export default PositionFormNavigate;