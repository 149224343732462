import React,{useState, useEffect} from 'react';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import CustomSelectForHookForm from '../../components/common/customSelect/CustomSelectForHookForm';
import { useForm, useFieldArray, Controller} from 'react-hook-form';
import CustomAsyncSelectForHookForm from '../../components/common/customSelect/CustomAsyncSelectForHookForm';
import PropTypes from 'prop-types';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InitiativeFormDemandMonthly from './InitiativeFormDemandMonthly';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { postRequest} from '../../services/axiosClient';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setInitFormSaveStatus,setInitDemandIsValid} from '../../redux/features/initiativeFormSlice';
import { backendUrls } from '../../utils/constants';

const urlWwid = backendUrls.rmr_resource;
const baseUrl =  getBaseTspmCoreURL();
// check and return deleted Month, this happens when default months left out of current date range
const checkMonthDifference = (demands, month, deleted)=>{
    const delArr = [];
    const deletedDemandIds = deleted.data.map(item => item.id);
   
    demands.forEach(demand => {
        if(deleted.status){
            // here we also check if demand id is in deleted object, if it is there then we pass
            // it will get deleted altogether
            if(!deletedDemandIds.includes(demand.id)){
                demand.allocat.forEach(item=> {
                    if(!month.includes(item.ymd)){
                        delArr.push(item.id);
                    }
                });
            }
        } else {
            demand.allocat.forEach(item=> {
                if(!month.includes(item.ymd)){
                    delArr.push(item.id);
                }
            });
            
        }
    });
    return delArr;
};

const InitiativeFormDemand = props => {
    const {demands, selectData, initiativeId, config, edited, setEdited, getDemands} = props;
    
    const monthArr = props.monthArr;
    const dispatch = useDispatch();

    /* COMPONENT STATE */
    const [hideResource, setHideResource] = useState(false);
    const [deleted, setDeleted] = useState({status:false, data:[]});

    const { register, control, handleSubmit, formState: { errors, isDirty, isValid}} = useForm({
        defaultValues: {
            demand: demands
        }
    });

    const { fields, append, remove} = useFieldArray({
        control,
        name: 'demand', 
    });

    /* Checking if the isDirty state is true, if it is, it is checking if the edited state is false, if
    it is, it is setting the edited state to true. */
    useEffect(() => {
        if(isDirty) {
            setEdited( prevState => ({...prevState, demand: true}));
        }
    }, [isDirty]);
    
   
    /**
    * When the delete button is clicked, call the remove
    * function with the index of the item to be removed.
    */
    const handleDelete =(e, index, demand)=>{
        e.preventDefault();
       
        if(demand.ids){
            const allct = demand.allocat.filter(item=> item.id).map(item=> item.id);
            setDeleted(prevState => {
                return {status:true, data:[...prevState.data, {id:demand.ids, allocation:allct}]};
            });
        }
        remove(index);
    };
    
    
    
    // main form submit
    const onSubmit = data => {
        const newMonthArr = monthArr.map(al=> al.ymd);
        const deletedMonth = checkMonthDifference(demands, newMonthArr, deleted);

        if(edited){
            handleSaveApiCall(data, deletedMonth);
        }
        
    };
   
    const handleSaveApiCall = async (data,deletedMonth)=>{

        // handle deleted values
        const apiCallArray = [];
        if(deleted.status){
            const demandDeleteArr = [];
            const allctDeleteArr = [];
            deleted.data.forEach(item=>{
                demandDeleteArr.push(item.id);
                item.allocation.forEach(al=>{
                    allctDeleteArr.push(al);
                });
            });
            try {
                await postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand_monthly_allocation/uploadDelete', allctDeleteArr).then(()=>{
                    const demandDelete = postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand/uploadDelete', demandDeleteArr);
                    apiCallArray.push(demandDelete);
                });
            } catch (error) {
                console.log(error);
            }
        }

        // check and send deleted Month, this hapens when default months left out of current date range
        if(deletedMonth.length !== 0){
            const allctDelete = postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand_monthly_allocation/uploadDelete', deletedMonth);
            apiCallArray.push(allctDelete);
        }

        //handle update part
        const updateDemandArr = {updateRecords:false, demands:[], allocations:[]};
        const newDemandArr = {newRecords:false, demands:[], allocations:[]};
        data.demand.forEach(item => {
            if(item.id !== null){
                updateDemandArr.updateRecords = true;
                updateDemandArr.demands.push({id:item.id, role_id:item.role_id, skill_id:item.skill_id, technology_id:item.technology_id, resource_wwid:item.resource_wwid, comments:item.comments});
                item.allocat.forEach(al=>{
                    if(al.id){
                        updateDemandArr.allocations.push(al);
                    }else{
                        updateDemandArr.allocations.push({...al,  initiative_demand_id:item.id});
                    }
                });
            } else{
                newDemandArr.newRecords = true;
                newDemandArr.demands.push({role_id:item.role_id, skill_id:item.skill_id, technology_id:item.technology_id, resource_wwid:item.resource_wwid, comments:item.comments, initiative_id:item.initiative_id});
                newDemandArr.allocations.push(item.allocat);
            }
        });
        
        // saving updated values
        if(updateDemandArr.updateRecords){
            const sendAllocations = postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand_monthly_allocation/upload', updateDemandArr.allocations);
            apiCallArray.push(sendAllocations);

            const sendDemands= postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand/upload', updateDemandArr.demands);
            apiCallArray.push(sendDemands);
        }
      
        //saving new records
        if(newDemandArr.newRecords){
            try {
                const newAlctArr = [];
                const sendDemands= await postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand/upload',newDemandArr.demands);
                
                sendDemands.data.data.forEach(item=> {
                    newDemandArr.allocations[item.recordIndex].forEach(al=>{
                        newAlctArr.push({...al, initiative_demand_id:item.recordId});
                    });
                });
                
                const sendAllocations =await postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand_monthly_allocation/upload', newAlctArr);
                apiCallArray.push(sendAllocations);

            } catch (error) {
                console.log(error);
            }
        }

        /* Making an array of API calls and then using axios.all to make all the calls at once. */
        axios.all(apiCallArray).then(()=> {
            setDeleted({status:false, data:[]});
            setEdited( prevState => ({...prevState, demand: false}));
            dispatch(setInitFormSaveStatus({status: true, type: 'success', message: 'Your changes have been successfully saved!'}));
            getDemands();
        })
            .catch(err => {
                const msg = err.request?.statusText ? ` - ${err?.request.statusText}!` : '!';
                dispatch(setInitFormSaveStatus({status: true, type: 'fail', message: 'Failed to save changes, please refresh page and try again' + msg}));
            });
       
    };

    const defaultDemand = {
        id:null,
        comments: '',
        initiative_id: initiativeId,
        initiative_id_dv: '',
        resource_wwid:  null,
        resource_wwid_dv:  '',
        role_id:'',
        role_id_dv:'',
        skill_id: '',
        skill_id_dv: '',
        technology_id:'',
        technology_id_dv:'',
    };

    /**
     * When the user clicks the add button, the handleAdd function will create a new object with the default
     * object, and then add a new property called allocat, which is an array of monthly allocations.
     */
    const handleAdd = e =>{
        e.preventDefault();
        const monthAll = [];
        monthArr.map(m => {
            const d= {
                
                'initiative_demand_id': null,
                'hrs': 0,
                'mo': parseInt(m.month),
                'yr': m.year,
                'ymd': `${m.year}-${m.month}-01`
            };
            monthAll.push(d);    
        });
        append( {...defaultDemand, allocat:monthAll });
    };

    /**
     * When the user clicks the copy button, append a new item to
     * the list with the same properties as the item that was clicked, except for the id, which should
     * be null.
     */
    const handleCopy =(e,copyItem) =>{
        e.preventDefault();
        append({...copyItem, id:null});
    };

    /**
     * If the error type is minLength, return text Charachter length cannot exceed. 
     * Otherwise, return text This field is required.
    */
    const errorMessage = (error, limit) => {
        if(error.type === 'maxLength'){
            return <span className='initial-form-error-msg'>
                This field length cannot exceed {limit} charachters
            </span>;
        }
        return <span className='initial-form-error-msg'>
            This field is required
        </span>;
    };

    /**
     * If the value of the is_required property of the object in the config array with the key of
     * colName is 1, return true, otherwise return false.
     */
    const getIsRequired = colName =>{
        if(config[colName].is_required === 1){
            return true;
        }
        return false;
    };

    /**
     * Hide resources
     */
    const handleHideResource = e =>{
        e.preventDefault();
        setHideResource(prevState => !prevState);
    };

    /**
     * This subMitFunc is passed to main submit to be called within same save button!
     */
    const subMitFunc = ()=>{
        handleSubmit(onSubmit)();
    };
    props.demandRef.submitDemand = subMitFunc;

    /* Handling demand form validation state, based on this we will submit initiative and project part of this form */
    useEffect(() => {
        dispatch(setInitDemandIsValid(isValid));
    }, [isValid]);

    // for sonar
    const even = 2;
    const four = 4;

    return (
        <form className='demand-form' data-testid='demand-form-test'>
            {fields.length !== 0 ? (
                <div>
                    {hideResource  ? <button onClick={handleHideResource} className='btn btn-trans' title="Show Resources" data-testid='demand-show-resource'> <AddIcon/></button> :
                        <button onClick={handleHideResource} className='btn btn-trans' title="Hide Resources" data-testid='demand-hide-resource'><RemoveIcon/></button> }
                    <table className="rf-table" style={{marginBottom:'1rem'}}>
                        <thead>
                            <tr style={{height:'60px'}}> 
                                <th style={{width:'60px'}}>Actions</th>
                                <th className='demand-table-header'>Role</th>
                                <th className='demand-table-header'>Skill</th>
                                <th className='demand-table-header'>Technology</th>
                                {monthArr.map((month,i) => (
                                    <th className='demand-table-header' key={i} style={{width:'60px'}}>
                                        <div className='demand-header-month-names'><span>{month.year}</span>     <span>{month.monthName}</span></div> 
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className='demand-table-body' data-testid="demand-table-row">
                            {fields.map((item, index) => (
                                <React.Fragment key={item.id}> 
                                    <tr className={index % even === 0 ? 'demand-row-even' : 'demand-row-odd'}> 
                                        <td>
                                            <span className='demand-table-actions'>
                                                <button className='btn btn-trans'
                                                    onClick={e=>handleDelete(e,index, item)} 
                                                    style={{paddingLeft:'5px', paddingRight:'3px'}}
                                                    title="Delete demand"
                                                    data-testid='demand-delete'
                                                >
                                                    <DeleteForeverIcon />
                                                </button>
                                                {item.id && <input 
                                                    type="hidden" 
                                                    defaultValue={item.id} 
                                                    {...register(`demand.${index}.id`)} 
                                                    name={`demand.${index}.id`}
                                                    
                                                />}
                                                <button className='btn btn-trans' 
                                                    onClick={e=>handleCopy(e,item)} 
                                                    style={{paddingLeft:'3px', paddingRight:'5px'}}
                                                    title="Copy demand"
                                                    data-testid='demand-copy'
                                                >
                                                    <ContentCopyRoundedIcon fontSize='medium'/></button>
                                            </span>                                                      
                                        </td>
                                        <td>
                                            <Controller
                                                name={`demand.${index}.role_id`}
                                                control={control}
                                                defaultValue={item.role_id}
                                                rules={{required: getIsRequired('role_id')}}
                                                render={field => (
                                                    <CustomSelectForHookForm  
                                                        options={selectData.tableArray[0].list} 
                                                        field={field} 
                                                        defValue={item.role_id} 
                                                        
                                                        name={`demand.${index}.role_id`} 
                                                        defaultKeyNames={{label:'name', value:'id'}}
                                                        hideId={true}
                                                    />
                                                )}/>
                                            {errors?.demand?.[index]?.role_id && errorMessage(errors?.demand?.[index]?.role_id)}
                                        </td>
                                        <td>
                                            <Controller
                                                name={`demand.${index}.skill_id`}
                                                control={control}
                                                defaultValue={item.skill_id}
                                                rules={{required: getIsRequired('skill_id')}}
                                                render={field => (
                                                    <CustomSelectForHookForm  
                                                        options={selectData.tableArray[1].list} 
                                                        field={field} 
                                                        defValue={item.skill_id} 
                                                        name={`demand.${index}.skill_id`} 
                                                        defaultKeyNames={{label:'name', value:'id'}} 
                                                        hideId={true}
                                                    />
                                                )}/>
                                            {errors?.demand?.[index]?.skill_id && errorMessage(errors?.demand?.[index]?.skill_id)}
                                        </td>
                                        <td>
                                            <Controller
                                                name={`demand.${index}.technology_id`}
                                                control={control}
                                                defaultValue={item.technology_id}
                                                rules={{required: getIsRequired('technology_id')}}   
                                                render={field => (
                                                    <CustomSelectForHookForm  
                                                        options={selectData.tableArray[2].list} 
                                                        field={field} 
                                                        defValue={item.technology_id} 
                                                        name={`demand.${index}.technology_id`} 
                                                        defaultKeyNames={{label:'name', value:'id'}} 
                                                        hideId={true}
                                                    />
                                                )}/>
                                            {errors?.demand?.[index]?.technology_id && errorMessage(errors?.demand?.[index]?.technology_id)}
                                        </td>
                                        <InitiativeFormDemandMonthly 
                                            register={register} 
                                            control={control} 
                                            monthArr={monthArr}
                                            nestIndex={index}
                                        />
                                    </tr>
                                    <tr 
                                        style={hideResource ? {display:'none'} : {display:'table-row'}} 
                                        className={index % even === 0 ? 'demand-row-even' : 'demand-row-odd'}
                                        data-testid='demand-resource-row-test'
                                    > 
                                        <td colSpan={four + monthArr.length} >
                                            <span className='demand-resource-row'>
                                                <label className='demand-resource-select'>
                                                    <span>Resource</span>
                                                    <div style={{width:'100%'}}>
                                                        <Controller
                                                            name={`demand.${index}.resource_wwid`}
                                                            control={control}
                                                            defaultValue={item.resource_wwid}
                                                            rules={{required: getIsRequired('resource_wwid')}}
                                                            render={field => (
                                                                <CustomAsyncSelectForHookForm  
                                                                    urlReq={urlWwid} 
                                                                    defValue={[{ [config.resource_wwid.foreign_column_name]:item.resource_wwid ? item.resource_wwid : '', 
                                                                        [config.resource_wwid.ref_display_value]: item.resource_wwid_dv}]} 
                                                                    defaultKeyNames={{label:config.resource_wwid.ref_display_value, value: config.resource_wwid.foreign_column_name}} 
                                                                    field={field} 
                                                                    name={`demand.${index}.resource_wwid`} 
                                                                    hideId={false}
                                                                />
                                                            )}/>
                                                        {errors?.demand?.[index]?.resource_wwid && errorMessage(errors?.demand?.[index]?.resource_wwid)}
                                                    </div>
                                                    
                                                    
                                                </label>

                                                <label  className='demand-comments'>
                                                    <span>Comments</span>
                                                    <textarea 
                                                        // {...register(`demand.${index}.comments`)} 
                                                        defaultValue={item.comments} 
                                                        name={`demand.${index}.comments`} 
                                                       
                                                        {...register(`demand.${index}.comments`, {required: getIsRequired('comments'), maxLength: config.comments.character_maximum_length})} 
                                                        data-testid='textbox-comment-test'
                                                        aria-label='textbox-comment-test'
                                                    />
                                                    {errors?.demand?.[index]?.comments && errorMessage(errors?.demand?.[index]?.comments, config.comments.character_maximum_length)}
                                                </label> 
                                            </span>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody> 
                    </table> 
                </div>) : <></>}
            <button
                className='btn btn-green'
                onClick={handleAdd}
                data-testid='demand-add'
            >
                Add New Initiative Demand
            </button>
            <br/>
        </form>
    );
};

InitiativeFormDemand.propTypes = {  
    demandRef:PropTypes.object,
    demands: PropTypes.array,
    selectData: PropTypes.object,
    monthArr: PropTypes.array,
    initiativeId: PropTypes.string,
    config: PropTypes.object,
    edited: PropTypes.bool,
    setEdited: PropTypes.func,
    getDemands: PropTypes.func,
};

export default InitiativeFormDemand;