import React, {useState, useCallback, useEffect} from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest } from '../../services/axiosClient';
import PropTypes from 'prop-types';
import { useFieldArray, Controller } from 'react-hook-form';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import ErrorStatus from '../../components/common/ErrorStatus';
import { debounce } from '../../components/data';
import CustomDatePicker from '../../components/common/datePicker/CustomDatePicker';


const CustomAsyncSelectForHookForm  = props => {
    const field = props.field.field;
    const valueD = props.field.field.value;

    /* This is making a call to a REST API and returning a list of objects property.
    You also need to set label and value fields to show up in select dropdown or set manually
    with getOptionLabel and getOptionValue methods, shown at 47/48 lines.
    */
    const loadOptionData = async (inputText, callback) => {
        
        const json = await getRequest(props.urlReq +'%' +inputText + '%');
        callback(json.data.data);
    };

    // load data from api
    const loadOptionDataDebounced = useCallback(
        debounce(loadOptionData),
        []
    );

    //getting option label
    const optionLabel = option=>{
        
        const selectLabel = option[props.defaultKeyNames.label] ? option[props.defaultKeyNames.label] : '';
        const selectValue = option[props.defaultKeyNames.value] ? option[props.defaultKeyNames.value] : '';
        return  props.hideId ? `${option[props.defaultKeyNames.label]}` :  `${selectValue} - ${selectLabel}`;
    };

    
    
    return (<>
        <AsyncSelect
            onChange={value => field.onChange(parseInt(value[props.defaultKeyNames.value]))}
            loadOptions={loadOptionDataDebounced}      
            cacheOptions
            getOptionLabel={option =>  optionLabel(option)}
            getOptionValue={option => option[props.defaultKeyNames.value]}
            ref={field?.ref}
            name={props?.name}
            value={props.defValue?.find(c => c[props.defaultKeyNames.value] === valueD)}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            
        /> </>
    );
};

CustomAsyncSelectForHookForm.propTypes = {
    urlReq: PropTypes.string,
    defValue: PropTypes.any,
    defaultKeyNames: PropTypes.object,
    field:PropTypes.any,
    name:PropTypes.string,
    isMulti: PropTypes.bool,
    hideId: PropTypes.bool,
};


const PositionFormSupervisor = props => {
    const {control, defaultData, errors, positionId, getValues} = props;
    // state
    const [options, setOptions] = useState([]);
    const [error, setError] = useState({status:false, message:''});
    const baseUrl = getBaseTspmCoreURL();
    // api url
    const urlData = baseUrl  + '/api/datasrcs/1/folder/rmr/customQuery/position_form_supervisior_search?filters=';
    const { fields, append, remove} = useFieldArray({
        control,
        name:'rmr_position_hierarchy',
    });
   
    /* 
    This is the default object that is used to 
    append to the form when the user clicks the add
    button. 
    */
    const defObj ={
        'position_id': parseInt(positionId),
        'parent_position_id': '',
        'start_date': '',
        'end_date': null
    };


    // adding new record to form
    const handleAdd = e=>{
        e.preventDefault();
        append(defObj);
    };

    // removing last added record from form
    const handleRemove = e=>{
        e.preventDefault();
        remove(fields.length-1);
    };

    /**
     Mapping data as {concat:'', position_id:''}
     */
    const getOptions = async () => {
        try {
            // Make an API request to get position form supervisor data
            const resSupPos = await getRequest(
                baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/position_form_supervisior_default?filters=${positionId}`
            );
      
            // Format the response data and update the options state
            const newOptions = resSupPos.data.data.map(item => {
                const supervisorName = item?.resource_wwid ? `${item.parent_position_name} | ${item.display_name} (${item.resource_wwid})` : `${item.parent_position_name}`;
                return { parent_position_id: item.parent_position_id, concat: supervisorName };
            });
            setOptions({ fetched: true, data: newOptions });
        } catch (err) {
            // Handle the error by updating the error state
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({
                status: true,
                message: 'Error with getting position supervisor data, please refresh page or try again later' + msg
            });
        }
    };
    // checking if position id is not null
    // and getting options from api
    useEffect(()=>{
        if(positionId !== null){
            getOptions();
        } else {
            setOptions({fetched:true, data:[]});
        }
    },[]);

    return (
        <section className='position-form-section' >
            <ErrorStatus error={error} setError={setError} />
            <table className='position-table-field'>
                <thead> 
                    <tr> 
                        <th className="">
                        Supervisor Position
                        </th>
                        <th style={{width: '200px'}}>
                        Effective Date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        fields.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    {options.fetched ? <Controller
                                        name={`rmr_position_hierarchy.${index}.parent_position_id`}
                                        control={control}
                                        defaultValue={item.parent_position_id}
                                        rules={{ required:false}}
                                        render={field => (
                                            <CustomAsyncSelectForHookForm  
                                                urlReq={urlData} 
                                                defValue={[{concat:options.data[index]?.parent_position_id ? options.data[index].concat : '', position_id:options.data[index] ? options.data[index].parent_position_id : item.parent_position_id}]} 
                                                defaultKeyNames={{label:'concat', value: 'position_id'}} 
                                                field={field} 
                                                name={`rmr_position_hierarchy.${index}.parent_position_id`} 
                                                hideId={false}/>
                                        )}/> 
                                        : <LoadingAnimation type={'dot'}/>}
                                </td>
                                <td>
                                    <Controller
                                        name={`rmr_position_hierarchy.${index}.start_date`}
                                        control={control}
                                        defaultValue={item.start_date}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <CustomDatePicker
                                                isDisabled={positionId !== null ? false : true}
                                                selectedDate={field.value}
                                                getValues={getValues}
                                                data={{field, tableName:'rmr_position_hierarchy', index }}
                                                onChange={false}
                                            />
                                        )}/>
                                    {errors?.rmr_position_hierarchy?.[index]?.start_date && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                </td>
                            </tr>
                        
                        ))
                    }
                 
                </tbody>
            </table>
            {positionId && <div className='posForm-btn-group'>
                <button className='btn btn-green' 
                    onClick={handleAdd}
                > Add New Supervisor Record</button>
                {fields.length > defaultData.length  ?  <button className='btn btn-red' 
                    onClick={handleRemove}
                > Remove Last Record</button> :<></>
                }
            </div>}

        </section>
    );
};
PositionFormSupervisor.propTypes = {
    control: PropTypes.object,
    defaultData: PropTypes.array,
    errors: PropTypes.object,
    positionId: PropTypes.any,
    getValues: PropTypes.func,
};

export default PositionFormSupervisor;