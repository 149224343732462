import React from 'react';
import { useParams } from 'react-router-dom';
import { getTableauId } from '../../components/data';
import DashboardTabs from './DashboardTabs';
import { Tableau } from '@jauj/reactpkg-tsis-tableau';

const DashboardNav = () => {
    let params = useParams();
    let obj = getTableauId(params.linkId);
    
    /* 
    Setting the height and 
    width of the tableau report. 
    */
    const options = {
        height: 1050,
        width:1500,
        hideTabs: obj?.hideTabs,
    };

    /* 
    Setting the filter 
    for the tableau report. 
    */

    const initialFilters = [
        { field: 'Allocation or Recovery', values: obj?.filter},
    ];
    // we will return tableau if obj.tableau is true, 
    //if not we will show table components
    return (
        <>
            {
                obj.tableau ? 
                    <Tableau
                        reportUrl={obj.url}
                        initialFilters={obj?.filter ? initialFilters : null }
                        options={options}
                    /> : 
                    <div>
                        <DashboardTabs url={obj.url} name={obj.title}/>
                    </div>
            }
        </>
    );
};

export default DashboardNav;