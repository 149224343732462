import React, { useState, useEffect} from 'react';
import Select from 'react-select';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import { getRequest} from '../../services/axiosClient';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setResourceSkills } from '../../redux/features/resourceFormSlice';
import { useDispatch } from 'react-redux';
import ErrorStatus from '../../components/common/ErrorStatus';


const ResourceSelect = props => {
    const {setEdited,  selectData, edited} = props;
    const baseUrl =props.baseUrl;
    const params = useParams();
    const dispatch = useDispatch();
    const [defaultOptions, setDefaultOptions] = useState({});
    const [selectedOption, setSelectedOption] = useState({});
    const [error, setError] = useState({status:false, message:''});
    const [loading, setLoading] = useState(false);
    
    /**
     * get role, skill, technology table data
     * and set it to redux store,
     * it will reduce api calls
     * if we visit page again 
     */
    // A helper function to fetch and process data
    const fetchDataAndProcess = async (url, index, idName) => {
        const response = await getRequest(url);
        return response.data.data.map(item => {
            let newData = selectData[index].list.filter(k => item[idName] === k.id);
            return {...newData[0], res_id: item.id, resource_wwid:item.resource_wwid };
        });
    };

    const getUserRelTables = async () => {
        setLoading(true);
        try {
        // Construct URLs
            const urls = [
                `/api/datasrcs/1/schemas/rmr/tables/rmr_resource_role_rel/records?resource_wwid=${params.wwid}`,
                `/api/datasrcs/1/schemas/rmr/tables/rmr_resource_skill_rel/records?resource_wwid=${params.wwid}`,
                `/api/datasrcs/1/schemas/rmr/tables/rmr_resource_technology_rel/records?resource_wwid=${params.wwid}`
            ];

            // Array to store names of IDs in the same order as URLs
            const idNames = ['role_id', 'skill_id', 'technology_id'];

            // Fetch and process data in parallel
            const [ role,skill, tech] = await Promise.all(urls.map((url, index) => 
                fetchDataAndProcess(baseUrl + url, index, idNames[index])
            ));
            
            setDefaultOptions({skills: skill, roles: role, technologies: tech});
            setSelectedOption({skills: skill, roles: role, technologies: tech});

            setLoading(false);
            dispatch(setResourceSkills({skills: skill, roles: role, technologies: tech}));
        } catch (err) {
            const msg = err.request?.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting user skill data, please refresh page or try again later' + msg});
            setLoading(false);
        }
    };
    
    const handleOptionChange = (data, name, action) => {
        // enable save button
        if(!edited) setEdited( prevState => ({...prevState, select: true}));
    
        if(action.action === 'select-option'){
            let k = `"id":${action.option.id},"name":"${action.option.name}"`;
           
            if(JSON.stringify(defaultOptions[name]).includes(k)){
                let newOpt = defaultOptions[name].filter(option => option.id === action.option.id);
                const index = data.indexOf(action.option);
                // only splice array when item is found
                if (index > -1) {
                    // 2nd parameter means remove one item only
                    data.splice(index, 1); 
                }
                data.push(newOpt[0]);
            }
        } 
        setSelectedOption({...selectedOption, [name]:data});
    };    
   
    const handleSort = () => {
        let names = {roles: 'role_id', skills: 'skill_id', technologies:'technology_id'};

        let dat = {skills:{deleted:[], added:[], tableName: 'rmr_resource_skill_rel'},roles:{deleted:[], added:[],  tableName: 'rmr_resource_role_rel' },technologies:{deleted:[], added:[],  tableName: 'rmr_resource_technology_rel'}};
        selectData.forEach(each => {
            selectedOption[each.name].forEach(option=> {
                const newItem = {resource_wwid: params.wwid, [names[each.name]]: option.id};
                if(!JSON.stringify(defaultOptions[each.name]).includes(JSON.stringify(option))){
                    dat[each.name].added.push(newItem);
                }
            });

            /* 
                Checking if the selectedOption[each.name] is not included in the option. 
                If it is not included, it will push the newItem to the deleted array. 
                If the addedItem is included
                in the added array, it will pop the addedItem. 
            */
            defaultOptions[each.name].forEach(option => {
                const newItem = {id: option.res_id, resource_wwid: params.wwid, [names[each.name]]: option.id};
                if(!JSON.stringify(selectedOption[each.name]).includes(JSON.stringify(option)) ){
                    dat[each.name].deleted.push(newItem);
                }
            });
            

        });
         

        return dat;
    };
    
    useEffect(() => {
        getUserRelTables();
    }, [params.wwid]);

    /** 
     * assign functions to call from parent component before and after save
     */
    props.biRef.getSelectResults = handleSort;
    props.biRef.getUserRelTables = getUserRelTables;
    // If the selected options for this category are already at the limit, then disable all other options
    const maxAllowedNumber =3;
    const isOptionDisabled = (_option, name) => selectedOption[name] && selectedOption[name].length >= maxAllowedNumber;

    return (
        <div>
            <ErrorStatus error={error} setError={setError}/>
            <div className="rf-select" data-testid='rf-select-skills'>
            
                {selectData.map(item=> (
                    <div className="rf-select-wrap" key={item.id}>
                        <span className="rf-select-name">{item.name}</span>
                        {!loading ? <Select
                            defaultValue={defaultOptions[item.name]}
                            value={selectedOption[item.name]}
                            onChange={(value, action )=> handleOptionChange(value, item.name, action)}
                            options={item.list}
                            isMulti
                            getOptionValue={option => `${option.id}`}
                            getOptionLabel={option => `${option.name}`}
                            isClearable={false}
                            isOptionDisabled={option => isOptionDisabled(option, item.name)}
                        />: <div className='rf-select-placeholder-div'> <LoadingAnimation type={'dot'}/></div> }
                    </div>
                ))}
            </div>
        </div>
        
    );
};

ResourceSelect.propTypes = {
    setEdited: PropTypes.func,
    biRef:PropTypes.any,
    selectData: PropTypes.array,
    edited: PropTypes.bool,
    baseUrl: PropTypes.string
};
export default ResourceSelect;