import { createSlice } from '@reduxjs/toolkit';

// create a slice of the store's state called 'positionForm' with an initial state object
export const positionFormSlice=createSlice({
    name: 'positionForm',
    initialState:{
        positionConfig:{fetched:false, data:{}},
        positionOptions:{fetched:false},
        positionHeaderOptions:{fetched:false},
        positionStatus:false,
        positionResource:{wwid:null, start_date:null}
    },
    // define the different reducers for the slice
    reducers: {
        // setPositionConfig is used to set the position config data in the positionForm slice
        setPositionConfig: (state, action) => {  
            state.positionConfig= action.payload;
        },
        // setPosOptions is used to set the position options data in the positionForm slice
        setPosOptions:(state, action) => {
            state.positionOptions= action.payload;
        },
        // setPositionHeaderOptions is used to set the position header options data in the positionForm slice
        setPositionHeaderOptions:(state, action) => {
            state.positionHeaderOptions= action.payload;
        },
        // setPositionStatus is used to set the position status in the positionForm slice
        setPositionStatus: (state, action) => {
            state.positionStatus= action.payload;
        },
        // setPositionResource is used to track state of edited resource
        setPositionResource: (state, action) => {
            state.positionResource= action.payload;
        },


    },
});

// export the actions that can be dispatched
export const { setPositionConfig, setPosOptions,setPositionHeaderOptions,setPositionStatus,setPositionResource } = positionFormSlice.actions;

// export the selectors
export const positionConfig = state => state.positionForm.positionConfig;
export const positionOptions = state => state.positionForm.positionOptions;
export const positionHeaderOptions = state => state.positionForm.positionHeaderOptions;
export const positionStatus = state => state.positionForm.positionStatus;
export const positionResource = state => state.positionForm.positionResource;

// export the reducer
export default positionFormSlice.reducer;