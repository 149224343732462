import React, { useState, useEffect } from 'react';
import './resourceForm.css';
import ResourceCapacity from './ResourceCapacity';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector , useDispatch} from 'react-redux';
import { setResourceFormData, resourceFormTables  } from '../../redux/features/resourceFormSlice';
import { getRequest, postRequest, deleteRequest, putRequest } from '../../services/axiosClient';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import ResourceHeader from './ResourceHeader';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import axios from 'axios';
import ResourceSelect from './ResourceSelect';
import ResourceSow from './ResourceSow';
import { user, isAdmin } from '../../redux/features/userSlice';
import logPageView from '../../utils/logPageView';


const ResourceForm = () => {
    const params = useParams();
    const baseUrl = getBaseTspmCoreURL();
    const selectData = useSelector(resourceFormTables);
    const userData = useSelector(user);
    const adminStatus = useSelector(isAdmin);
    const navigate = useNavigate();
    const biRef = {};
    const noteRef = React.createRef('');
    const dispatch = useDispatch();
  
    /* COMPONENT STATE */
    const [resource, setResource] = useState({resourceFetched: false,  resource: [], rowCount:0});
    const [apiResult, setApiResult] =useState({status:false, type: null, message:''});
    const [saving, setSaving] = useState(false);
    const [userAccess, setUserAccess] = useState(false);
    
    const [edited, setEdited] = useState({
        note: false, select: false, capacity: false, allct:false
    });

    /**
     * When the user clicks on the button, navigate to the updateMyResources page and pass the
     * resource.resource.manager_position_id as a parameter.
     */
    const handleViewManager = () =>{
        navigate(`/updateMyResources/positionid/${resource.resource.manager_position_id}`);
    };

    // this api call will get and set which users can edit current users data
    const getSecurityData = async()=>{
        try {
            const res  = await getRequest(baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/resource_form_security?filters=${params.wwid}&filters=${userData.wwid}`);

            //check if returned data have record in it and set access
            if(res.data.rowCount > 0) {
                setUserAccess(true);
            }
           
        } catch (err) {
            /* 
                Checking if the error has a statusText 
                property and if it does it will add it to the
                message. If it doesn't it will just 
                add an exclamation mark. 
            */
            const msg = err.request?.statusText ? ` - ${err?.request.statusText}!` : '!';
            setApiResult({status: true, type: 'fail', message: 'Failed to get user data' + msg});
        }
    };
    
    /**
      getting initial view data
     */
    const getInitialResView = async()=>{
        try {
            const res = await getRequest(baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/individual_resource_view?filters=${params.wwid}`); 
            setResource({resourceFetched: true,  resource: res.data.data[0], rowCount: res.data.rowCount});
        } catch (err) {
            const msg = err.request?.statusText ? ` - ${err?.request.statusText}!` : '!';
            setApiResult({status: true, type: 'fail', message: 'Failed to get user data' + msg});
        }
    };
    
    /**
     * getting table select values, and setting redux state
     * **/
    const getSelectTables = async()=>{
        try {
            const rmr_skill = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_skill/records?active_flag=true'); 
            const rmr_role = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_role/records?active_flag=true'); 
            const rmr_tech = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_technology/records?active_flag=true'); 
            const arr = [
                {   id: 1, name: 'roles', list:rmr_role.data.data },
                {   id: 2, name: 'skills', list:rmr_skill.data.data },   
                {   id: 3, name: 'technologies', list:rmr_tech.data.data }
            ];
            dispatch(setResourceFormData({tableFetched: true, tableArray: arr}));
        } catch (err) {
            const msg = err.request?.statusText ? ` - ${err?.request.statusText}!` : '!';
            setApiResult({status: true, type: 'fail', message: 'Failed to get user data' + msg});
        }
    };
    useEffect(()=>{
        logPageView();
    },[]);
    
    useEffect(() => {
        getInitialResView();

        /** 
         * if user is browsing his/her own page
         * give access to edit page
         * else check if he/she has 
         * access to it
        */
        if(userData.wwid === params.wwid){
            setUserAccess(true);
        } else{
            getSecurityData();
        }
        
        if(!selectData.tableFetched){
            getSelectTables();
        }
        
    }, [params.wwid]);
    
    /**
     * call refresh functions if their respective edit is true
     */
    const refreshAfterSave = edit => {
        if(edit.note){
            getInitialResView();
        }
            
        if(edit.select) {
            biRef.getUserRelTables();
        }
        if(edit.capacity){
            biRef.getResourceCapacity();
        }
        if(edit.allct){
            biRef.getAllctData();
        }

    };
    
    const handleApiCall =  async arrCapacityValues =>{
        setSaving(true);
        const apiCallArray = [];


        // checks SELECT section and adds apiCall array.
        if (edited.select) {
            const arrSelectValues = biRef.getSelectResults();
            const select = ['skills', 'roles',  'technologies'];
            
            select.forEach(result => {
                const {tableName, added, deleted} = arrSelectValues[result];
        
                /* 
                Checking if the added array is not empty. 
                If it is not empty, it will send a post
                request to the URL. 
                */
                if(added.length !== 0) {
                    const send = postRequest(`${baseUrl}/api/datasrcs/1/schemas/rmr/tables/${tableName}/upload`, added);
                    apiCallArray.push(send);
                }
        
                /* 
                checking if deleted array is not empty then
                sending delete api call for each of them 
                */
                if(deleted.length !== 0) {
                    deleted.forEach(item => {
                        const res = deleteRequest(`${baseUrl}/api/datasrcs/1/schemas/rmr/tables/${tableName}/records/${item.id}`);
                        apiCallArray.push(res);
                    });
                }
            });
        }

        // check notes and adds to api call array
        if(edited.note){
            
            const note = noteRef.current.value;
            const noteUpdate = {wwid:params.wwid, notes_actions:note};
            const resNote = putRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_resource/records/${params.wwid}`, noteUpdate);
            apiCallArray.push(resNote);
        }
        

        // check Capacity section, add to api call array
        if(edited.capacity){
            
            arrCapacityValues.forEach(item => {
                if(item.id){
                    const res = putRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_resource_capacity/records/${item.id}`, item);
                    apiCallArray.push(res);
                } else{
                    const res = postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_resource_capacity/records', item);
                    apiCallArray.push(res);
                }
                
            });
            
        }
        

        // check allocation section and add api call array
        if(edited.allct){
            
            const allctData= biRef.getAllocationResult();

            if(allctData.added.length > 0){
                allctData.added.forEach(id => {
                    const res = putRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand/records/${id}`, {resource_wwid:params.wwid});
                    apiCallArray.push(res);
                });
                
            }
            if(allctData.deleted.length > 0){
                allctData.deleted.forEach(id => {
                    const res = putRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand/records/${id}`, {resource_wwid:null});
                    apiCallArray.push(res);
                });
                
            }

        }
        
        
        /* Making an array of API calls and then using axios.all to make all the calls at once. */
        axios.all(apiCallArray).then(() => {
            refreshAfterSave(edited);
            
        }).then(()=>{
            setEdited({
                note: false, select: false, capacity: false, allct:false
            });

            setSaving(false);
            setApiResult({status: true, type: 'success', message: 'Your changes have been successfully saved!'});
        })
            .catch(errors => {
                setApiResult({status: true, type: 'fail', message: 'Failed to save changes, please refresh page and try again!'});
                console.error(errors);
                setSaving(false); 
            });

    };   
 
    /**
     * If the edited object has a capacity property, then get the capacity results from the biRef
     * object, and if there is an error, alert the user, otherwise call the handleApiCall function with
     * the records from the capacity results.
     * 
     * If the edited object does not have a capacity property, then call the handleApiCall function
     * without any parameters.
     */
    const handleSave = ()=>{
        if(edited.capacity){
            const arrCapacityValues = biRef.getCapacityResults();
            if(arrCapacityValues.error){
                alert('Please fill all capacity fields');
            } else {
                handleApiCall(arrCapacityValues.records);
            }
        } else{
            handleApiCall();
        }
    };

    /* Setting a timeout to clear the apiResult state after 4 seconds. */
    useEffect(() => {
        const timeout = setTimeout(() => {
            setApiResult({status: false, type: '', message: ''});
        }, 4000);
    
        return () => {
            clearTimeout(timeout);
        };
    }, [apiResult.status]);
    
    /* 
     * Checking if the resource.rowCount is equal to 0.
     * if it is give error message
     */
    if(resource.rowCount === 0){
        return (
            <div>
                Invalid wwid or resource page does not exist for this wwid: {params.wwid}
            </div>
        );
    }
    
    return (
        <div className="resource-form">
            {resource.resourceFetched ? <ResourceHeader resource={resource.resource} ref={noteRef} setEdited={setEdited} edited={edited.note}/> : <></>}
            <div className="rf-nav-btns">
                <button className="btn btn-blue" onClick={handleViewManager} disabled={resource.resource.manager_position_id ? false : true}>View Manager</button>
                {(userAccess || adminStatus)  && <button className="btn btn-green" onClick={handleSave} disabled={(edited.select || edited.note || edited.capacity || edited.allct) ? false :true}>{saving ? <LoadingAnimation type={'dot'}/> : 'Save'}</button>}
                {apiResult.status ? <span style={apiResult.type === 'success' ? {color:'green'} : {color: 'red'}}>{apiResult.message}</span> : <></>}
                
            </div>
            <div className="rf-info">
                {selectData.tableFetched ? <ResourceSelect setEdited={setEdited} biRef={biRef} selectData={selectData.tableArray}  edited={edited.select}  baseUrl={baseUrl}/> :<LoadingAnimation/>}
            </div>
            <hr />
            <ResourceCapacity setEdited={setEdited} edited={edited.capacity} biRef={biRef} baseUrl={baseUrl} wwid={params.wwid} />
            <ResourceSow wwid={params.wwid} baseUrl={baseUrl} setEdited={setEdited} edited={edited.allct} biRef={biRef}/>
        </div>
    );
};

export default ResourceForm;
