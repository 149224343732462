import React, { useState, useEffect } from 'react';
import './resourceCapacity.css';
import PropTypes from 'prop-types';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import { getRequest } from '../../services/axiosClient';
import { getFirstDayOfDate, sortEffectiveDates } from '../../components/data';
import ErrorStatus from '../../components/common/ErrorStatus';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { InputDatePicker } from '../../components/common/datePicker/CustomDatePicker';
import { parseISO } from 'date-fns';
const hundred = 100;
/**
     * If the current date is less than the previous date, return an error message.
     * @returns An object with two properties: error_status and message.
     */
const compareDates = (current, type, previous, thirdDate)=>{
    const curDate = new Date(current);
    const prevDate = new Date(previous);
    const thrdDate = new Date(thirdDate);
    const status = {error_status : false};

    
    if(type === 'check_start_date' && (curDate < prevDate)){
        status.error_status = true;
        status.message = `Effective date must be after '${previous}' effective date`;
    }
    if(type === 'check_end_date' && (curDate > prevDate)){
        status.error_status = true;
        status.message = `Effective date cannot be later than '${previous}' end date`;
    }
    if(type === 'check_both_date'){
        if(curDate > prevDate){
            status.error_status = true;
            status.message = `Effective date must be before than '${previous}'`;
        } else if(curDate < thrdDate){
            status.error_status = true;
            status.message = `Effective date must be later than '${thirdDate}'`;
        }
    }
    return status;
    
};
/* updating capacitty data with new values, 
    we use index to locate record and name to locate which field to update
    */
const handleData = (e, index, name, preState) => {
    let value = e;
    if (name === 'start_date') {
        value = getFirstDayOfDate(e);
    }

    const prevState = [...preState];
    let type, secondDate, thirdDate;
    
    if (name === 'start_date') {
        const currentRecord = prevState[index];
        const prevRecord = prevState[index - 1];
        
        if (currentRecord.end_date) {
            type = prevRecord ? 'check_both_date' : 'check_end_date';
            secondDate = currentRecord.end_date;
            thirdDate = prevRecord ? prevRecord.start_date : null;
        } else {
            type = 'check_start_date';
            secondDate = prevRecord ? prevRecord.start_date : null;
        }
    
        const result = compareDates(value, type, secondDate, thirdDate);
        if (result.error_status) {
            alert(result.message);
        } else {
            if (index !== 0) prevState[index - 1].end_date = value;
            currentRecord.start_date = value;
        }
    } else {
        if (value > hundred || value < 0) {
            alert('Capacity percentage must be between 0-100');
        } else {
            prevState[index][name] = value / hundred;
        }
    }
    return prevState;
};
/**
     * It takes a list of objects and compares them to a default object. If the object is not in the
     * list, it is added to a new list.
     * @returns An object with two properties: error and records.
     */
const getCapacityResults = (defaultCapacity, capacityData)=>{
    const capacityStr = JSON.stringify(defaultCapacity);
    const apiCallList = {error: false, records: []};
    capacityData?.forEach(item => {
        if(!capacityStr.includes(JSON.stringify(item))){
            if(item.start_date === ''){
                apiCallList.error = true;
            } else {
                apiCallList.records.push(item);
            }
        }
    });
    
    return apiCallList;
};

const getEndDate = endDate => endDate === null ? '' : endDate;
const getSelectValue = value => value === '' ? null : parseISO(value);

const ResourceCapacity = props => {
    const setEdited = props.setEdited;
    const edited = props.edited;
    const wwid = props.wwid;
    const baseUrl = props.baseUrl;
    const [defaultCapacity, setDefaultCapacity] = useState([]);
    const [capacityData, setCapacityData] = useState([]);
    const [loading, setLoading] =useState(false);
    const [error, setError] = useState({status:false, message:''});

    /**
      getting capacity data
     */
    const getResourceCapacity = async()=>{
        setLoading(true);
        try {
            const res = await getRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_resource_capacity/records?resource_wwid=${wwid}`); 

            const defData = res.data.data.map(item => {
                return {...item};
            });
            const sortedData = sortEffectiveDates(defData);

            setDefaultCapacity(sortedData);
            setCapacityData(sortEffectiveDates(res.data.data));
            setLoading(false);
        } catch (err) {
            setLoading(false);
            const msg = err?.request?.statusText ? ` - ${err?.request?.statusText}!` : '!';
            setError({status:true, message:'Error with getting user capacity data, please refresh page or try again later' + msg});
        }
    };


    /*
        - removing last item of capacity array
        - updating end date value of previous record
     */
    const  handleResourceRemove = e =>{
        e.preventDefault();
        
        let oldState = [...capacityData];
        const even = 2;
        oldState[oldState.length-even].end_date = null;
        setCapacityData(oldState.slice(0,-1));
    };


    /**
     * When the user clicks the button, add a new empty record.
     */
    const handleResourceAdd = e =>{
        e.preventDefault();
        
        const newCapacityData = {
            'resource_wwid': wwid,
            'start_date': '',
            'end_date': null,
            'capacity_percentage': 0
        };

        setCapacityData(prevState => ([...prevState, newCapacityData]));
    };

    

    

    /* Comparing the defaultCapacity with the capacityData. If they are the same, then it sets the
    edited state to false. If they are not the same, then it sets the edited state to true. */
    useEffect(() => {
        if(JSON.stringify(defaultCapacity) === JSON.stringify(capacityData)){
            setEdited( prevState => ({...prevState, capacity: false}));
        }  else {
            if(!edited) setEdited( prevState => ({...prevState, capacity: true}));
        }
    }, [capacityData]);
    
    useEffect(()=>{
        getResourceCapacity();
    },[]);

    
    

    const  handleValue = (e, index, name, preState) => {
        const result  = handleData(e, index, name, preState);
        setCapacityData(result);
    };
    
    const getResults=()=>getCapacityResults(defaultCapacity, capacityData);

    // assigning to object to be called in parent component
    props.biRef.getCapacityResults = getResults;
    props.biRef.getResourceCapacity = getResourceCapacity;

    
    return (
        <div>
            
            <div style={{marginBottom: '1rem'}}>
                <p>
          To change the capacity of this resource, click Add New Capacity Record
          and enter the Effective Date for when you would like the new capacity
          to be effective. You can aslo edit existing capacity records if the
          Effective Date or Capacity Percentage has changed.
                </p>
                <p>
          To Start Dates will automatically be set to the first of the month,
          regardless of what day of the month you selected.
                </p>
            </div>
            <ErrorStatus error={error} setError={setError}/>
            <form>
                <table className="rf-table">
                    <thead>
                        <tr>
                            <th>Capacity Percentage</th>
                            <th>Effective Date</th>
                            <th style={{display:'none'}}>End Date</th>
                        </tr>
                    </thead>
                    <tbody data-testid='resource-capacity-date'>
                        {capacityData.map((item, index)=>(   
                                  
                            <tr key={index}>
                                <td>
                                    {!loading ? 
                                        <input
                                            type="number"
                                            name={`capacity-percentage-${index}`}
                                            id="rf-percentage"
                                            value={Math.round(item.capacity_percentage * hundred)}
                                            onChange={e=> handleValue(e.target.value, index, 'capacity_percentage', capacityData)}
                                            data-testid='capacity-percentage'
                                        /> : <LoadingAnimation type={'dot'}/>}
                                </td>
                                <td>
                                    {!loading ? 

                                        <div  style={{width:'150px', margin:'auto'}}>
                                            <DatePicker
                                                // sets the selected date
                                                selected={getSelectValue(item.start_date)} 
                                                // change handler for the date picker
                                                onChange={date => handleValue(date, index, 'start_date', capacityData)} 
                                                // custom input component
                                                // this is only needed for to add test id
                                                customInput={<InputDatePicker testId={'capacity-start-date'} value={item.start_date} isDisabled={false}/>} 
                                                placeholderText='mm/dd/yyyy'
                                                showMonthDropdown 
                                                showYearDropdown 
                                                useShortMonthInDropdown
                                                yearDropdownItemNumber={8}
                                            />
                                        </div>
                                        : <LoadingAnimation type={'dot'}/>}
                                </td>
                                <td style={{display:'none'}}>
                                    {!loading ?
                                        <input type="date" name="end-date" id="rf-date" disabled  value={getEndDate(item.end_date)} /> 
                                        : <LoadingAnimation type={'dot'}/>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="rf-btn">
                    <button className="btn btn-green" onClick={handleResourceAdd} data-testid='add-new-capacity'>Add New Capacity Record</button>
                    {capacityData.length > defaultCapacity.length ? <button className="btn btn-red" onClick={handleResourceRemove} data-testid='remove-capacity'>Remove Last Capacity Record</button> : <></>}
                    
                </div>
            </form>
        </div>
    );
};

ResourceCapacity.propTypes ={
    wwid: PropTypes.string,
    setEdited: PropTypes.func, 
    edited: PropTypes.bool,
    biRef: PropTypes.any,
    baseUrl: PropTypes.string,
    setError: PropTypes.func
};


export default ResourceCapacity;
