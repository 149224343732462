import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useFieldArray , Controller} from 'react-hook-form';
import CustomSelectForHookForm from '../../components/common/customSelect/CustomSelectForHookForm';
import { positionOptions} from '../../redux/features/positionFormSlice';
import { useSelector } from 'react-redux';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import Select from 'react-select';
import CustomDatePicker from '../../components/common/datePicker/CustomDatePicker';

const PositionFormLocation = props => {
    const {control, positionId, defaultData, errors, getValues} = props;

    //setting default indexed loading, it will help reload select based on filtered options after first displayed
    const loadingDefault = defaultData.map(index => ({
        [index]:true
    }));
    const [loading, setLoading] = useState(loadingDefault);
    const [filteredOption, setFilteredOption] = useState([]);
    const options = useSelector(positionOptions);

    // default data
    const defaultLoc = {
        position_id: positionId,
        region_id: 1,
        country_id:'',
        start_date:'',
        end_date:'',
    };
    const { fields, append, remove} = useFieldArray({
        control,
        name:'rmr_position_location',
    });
    

    const handleAdd = e => {
        // Prevent the default behavior of the event 
        //(e.g. form submission)
        e.preventDefault();
      
        // Call the append function with 
        // the defaultLoc as an argument
        append(defaultLoc);
      
        // Filter the options based on the region_id of the defaultLoc
        const newOpt = options.rmr_country.filter(item => item.region_id === defaultLoc.region_id);
      
        // Add the filtered options to 
        // the filteredOption array
        setFilteredOption([...filteredOption, newOpt]);
    };
      
    const handleRemove = e => {
        // Prevent the default behavior 
        // of the event (e.g. form submission)
        e.preventDefault();
      
        // Call the remove function with the length of 
        //the fields array minus 1 as an argument
        remove(fields.length - 1);
      
        // Remove the last element from the 
        // filteredOption array
        const opt = [...filteredOption];
        opt.pop();
        setFilteredOption(opt);
    };
    
    // handling filter of dependent field, 
    // we are setting options of 
    //country field based on region_id
    useEffect(() => {
        const obj = {};
        const newArr = [];
        fields.forEach((field,index)=>{
            obj[index] = false;
            newArr.push(options.rmr_country.filter(item => item.region_id === field.region_id));
        });
        setFilteredOption(newArr);
        setLoading(obj);
    }, []);
    
    const handleChange = async (value, field, index) => {
        // Show loading indicator by updating the state
        setLoading(prevState => {
            const newLoading = { ...prevState };
            newLoading[index] = true;
            return newLoading;
        });
      
        // Filter the options and update the filteredOption state
        const option = [...filteredOption];
        option[index] = options.rmr_country.filter(item => item.region_id === value.id);
        setFilteredOption(option);
      
        // Call the onChange method of the field object and pass the selected value.id as an integer
        field.onChange(parseInt(value.id));
      
        // Hide loading indicator after one second
        const timeOutMilSec = 1000;
        setTimeout(() => {
            setLoading(prevState => {
                const newLoading = { ...prevState };
                newLoading[index] = false;
                return newLoading;
            });
        }, timeOutMilSec);
    };
    
 
    
    return (
        <section className='position-form-section' style={{width:'100%'}}>
           
            <table className='position-table-field'>
                <thead> 
                    <tr> 
                        <th className="">
                        Region
                        </th>
                        <th >
                        Country
                        </th>
                        <th style={{width: '200px'}}>
                        Effective Date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        fields.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    <Controller
                                        name={`rmr_position_location.${index}.region_id`}
                                        control={control}
                                        defaultValue={item.region_id}
                                        rules={{ required:true}}
                                        render={({field}) => (
                                            
                                            <Select        
                                                options={options.rmr_region}
                                                onChange={value => handleChange(value, field,index)}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.id}
                                                ref={field?.ref}
                                                name={`rmr_position_location.${index}.region_id`}
                                                value={options.rmr_region.find(c => c.id === field.value)}
                                                menuPortalTarget={document.body}
                                                menuPlacement="auto"
                                                onBlur={field.onBlur}
                                                inputuRef={field.ref}
                                            />
                                        )}/>
                                    {errors?.rmr_position_location?.[index]?.region_id && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                </td>
                                <td>
                                    {!loading[index] ? <Controller
                                        name={`rmr_position_location.${index}.country_id`}
                                        control={control}
                                        defaultValue={item.country_id}
                                        rules={{ required:true}}
                                        render={field => (
                                            <CustomSelectForHookForm
                                                options={filteredOption[index]}
                                                field={field}
                                                
                                                name={`rmr_position_location.${index}.country_id`} 
                                                defaultKeyNames={{label:'name', value:'id'}}
                                                hideId={true} 
                                            />                                             
                                        )}/> : <div className='select-placeholder' style={{width: 'inherit', height: '38px'}}> <LoadingAnimation type={'dot'}/></div>}
                                    {errors?.rmr_position_location?.[index]?.country_id && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                       
                                </td>
                                <td>
                                    <Controller
                                        name={`rmr_position_location.${index}.start_date`}
                                        control={control}
                                        defaultValue={item.start_date}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <CustomDatePicker
                                                isDisabled={positionId !== null ? false : true}
                                                selectedDate={field.value}
                                                getValues={getValues}
                                                data={{field, tableName:'rmr_position_location', index}}
                                                onChange={false}
                                            />
                                        )}/>
                                    {errors?.rmr_position_location?.[index]?.start_date && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                </td>
                            </tr>
                        
                        ))
                    }
                 
                </tbody>
            </table>
            {positionId && <div className='posForm-btn-group'>
                <button className='btn btn-green' 
                    onClick={handleAdd}
                > Add New Location Record</button>
                {fields.length > defaultData.length  ?  <button className='btn btn-red' 
                    onClick={handleRemove}
                > Remove Last Record</button> :<></>
                }
            </div>}

        </section>
    );
};

PositionFormLocation.propTypes = {
    control: PropTypes.object,
    defaultData: PropTypes.array,
    errors: PropTypes.object,
    defObj: PropTypes.object,
    positionId:PropTypes.any,
    getValues: PropTypes.func,
};

export default PositionFormLocation;