
import userSlice from './features/userSlice';
import resourceFormSlice from './features/resourceFormSlice';
import initiativeSlice from './features/initiativeSlice';
import initiativeFormSlice from './features/initiativeFormSlice';
import positionFormSlice from './features/positionFormSlice';
import homeSlice from './features/homeSlice';
import rmrStateSlice from './features/rmrStateSlice';

// define root reducer
// object
const rootReducer = {
    rmrState: rmrStateSlice,
    user: userSlice,
    resourceForm: resourceFormSlice,
    initiative: initiativeSlice,
    initiativeForm: initiativeFormSlice,
    positionForm: positionFormSlice,
    homeData: homeSlice
};
export default rootReducer;
