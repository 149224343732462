import {Alert } from '@mui/material';
import React from 'react';
import { getBaseTspmCoreURL } from '../utils/getBaseUrls';
import PropTypes from 'prop-types';

const AuthUser = props => {
    const {statusCode, message} = props.error;
    //redirect url
    const url = getBaseTspmCoreURL() + `/api/login?redirectURL=${window.location.href}`;
    const ERROR_CODE = 401;
    
    // if error is 401 then replace browser url
    if (statusCode === ERROR_CODE) {
        window.location.replace(url);
    }

  
    /*Showing message if error code is 401 */
    return (
        <div>
            {statusCode === ERROR_CODE ? 
                <Alert severity="info">
                    Its trying to Sign in, please wait a moment!
                </Alert> 
                : 
                <Alert severity="error">
                    {message}
                </Alert> 
            }
        </div>
    );
};

AuthUser.propTypes = {
    error:PropTypes.object.isRequired
};

export default AuthUser;