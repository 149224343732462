import React,{useCallback, useState} from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest } from '../../../services/axiosClient';
import PropTypes from 'prop-types';
import ErrorStatus from '../ErrorStatus';
import { debounce } from '../../data';

const CustomAsyncSelect = props => {
    
    const [error, setError] = useState({status:false, message:''});
    /* 
    loading options from given url with input text
    */
    const loadOptionData = async (inputText, callback) => {
        
        try {
            const json = await getRequest(props.urlReq + inputText);
            let result;
            if(props.urlReq.includes('employee_status')){
                result = json.data.data.filter(record => record.employee_status === 'ACTIVE');
            } else {
                result = json.data.data;
            }
            callback(result);
        } catch (err) {
            
            const msg = err?.request.statusText ? ` - ${err.request.statusText}!` : '!';
            setError({status:true, message:'Error!, please refresh page or try again later' + msg});
        }
        
    };


    /* A debounce function that is used to prevent the function from being called too often. */
    const delayTime = 400;
    const loadOptionDataDebounced = useCallback(
        debounce(loadOptionData, delayTime),
        []
    );
    
    //getting option label
    const optionLabel = option=> props.hideId ? `${option[props.defaultKeyNames.label]}` :  `${option[props.defaultKeyNames.value]} - ${option[props.defaultKeyNames.label]}`;
   
    if(props.isMulti){
        return (

            <>
                <ErrorStatus error={error} setError={setError} />
                <AsyncSelect
                    onChange={(value, action )=> props.handleChange(value, action)}
                    loadOptions={loadOptionDataDebounced}      
            
                    /* 
                    we are setting label and value fields, dont forget to pass two fields as props
                    ex as props: defaultKeyNames={{label:'name', value:'project_id'}}
                    or you can also change your data in loadOptionData function. in that case remove this two methods.
                */
                    getOptionLabel={option =>  optionLabel(option)}
                    getOptionValue={option => option[props.defaultKeyNames.value]} 
                    name={props.name}
                    isClearable={false}
                    isMulti
                    backspaceRemovesValue={false}
                    defaultValue={props.defValue || ''}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    placeholder={props.placeholder || 'Select...'}
                />
            </>
            
        );
    }
 
    return (<>
        <ErrorStatus error={error} setError={setError} />
        <AsyncSelect
            loadOptions={loadOptionDataDebounced}      
            onChange={(value, action )=> props.handleChange(value, action)}
            getOptionLabel={option =>  optionLabel(option)}
            getOptionValue={option => option[props.defaultKeyNames.value]}
            name={props.name}
            defaultValue={props.defValue || ''}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            value={props.value || null}
            placeholder={props.placeholder || 'Select...'}
        />
    </>
         
    );
};

CustomAsyncSelect.propTypes = {
    urlReq: PropTypes.string.isRequired,
    defValue: PropTypes.any,
    handleChange: PropTypes.func.isRequired,
    defaultKeyNames: PropTypes.object.isRequired,
    name:PropTypes.string.isRequired,
    isMulti: PropTypes.bool,
    hideId: PropTypes.bool,
    value:PropTypes.any,
    clearable:PropTypes.bool,
    placeholder:PropTypes.string,
};

export default CustomAsyncSelect;