import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
    // style
    // definition
    // for 
    // notfound
    // page
    const style = {
        textAlign: 'center',
        marginTop: '50px',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem'
    };

    return (
        <div style={style}>
            <h2>The page you requested was not found.</h2>
            <NavLink
                to='/'
                className="btn btn-blue"       
            >
                Go to Home
            </NavLink>
        </div>
    );
};

export default NotFound;