import { createSlice } from '@reduxjs/toolkit';
// create the resourceForm slice with its initial state
export const resourceFormSlice = createSlice({
    name: 'resourceForm',
    initialState: {
        tables:{
            tableFetched: false,
            tableArray:[]
        },
        resourceSkills:{fetched:false}
    },
    // define the different reducers for the slice
    reducers: {
        // setResourceFormData is used to set the data for the tables in the resourceForm slice
        setResourceFormData: (state, action) => {
            state.tables = action.payload;
        },
        // setResourceSkills is used to set the data for the skills, roles, and technologies in the resourceForm slice
        setResourceSkills: (state, action) => {
            const skills = action.payload.skills.map(sk => sk.name);
            const roles = action.payload.roles.map(role => role.name);
            const technology = action.payload.technologies.map(tech => tech.name);
            state.resourceSkills = {fetched:true,skills: skills, roles:roles, technology:technology};
        },
    },
});

// export the actions that can be dispatched
export const { setResourceFormData, setResourceSkills } = resourceFormSlice.actions;

// export the selectors
export const resourceFormTables = state => state.resourceForm.tables;
export const resourceSkills = state => state.resourceForm.resourceSkills;

// export the reducer
export default resourceFormSlice.reducer;