import React,{useEffect} from 'react';
import {  Controller, useFormContext } from 'react-hook-form';
import CustomAsyncSelectForHookForm from '../../components/common/customSelect/CustomAsyncSelectForHookForm';
import CustomSelectForHookForm from '../../components/common/customSelect/CustomSelectForHookForm';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setInitFormMonth, initFormMonth, setInitFormMonthDefault} from '../../redux/features/initiativeFormSlice';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { InputDatePicker } from '../../components/common/datePicker/CustomDatePicker';
import { parseISO } from 'date-fns';
import { backendUrls } from '../../utils/constants';

/**
     * If the error type is minLength, return text Charachter length cannot exceed. 
     * Otherwise, return text This field is required.
    */
const errorMessage = (error, limit) => {
    if(error === undefined) {
        return '';
    }
    if(error.type === 'maxLength'){
        return (
            <span className='initial-form-error-msg'>
                This field length cannot exceed {limit} charachters
            </span>);
    }
    
    return (
        <span className='initial-form-error-msg'>
            This field is required
        </span>);
};

/**
     * If the value of the is_required property of the object in the config array with the key of
     * colName is 1, return true, otherwise return false.
     */
const getIsRequred = is_required => is_required === 1 ? true: false;

/* 
        defining urls
*/
const urlWwid = backendUrls.rmr_resource;
const urlIniTypes = backendUrls.rmr_initiative_type + '?active_flag=true&sort-name=ASC';

const InitiativeFormHeader = props => {
    const month = useSelector(initFormMonth);
    const {setEdited,  edited, initiative, config} = props;
    const dispatch = useDispatch();
    
    //form state
    const { register, control,
        formState: { errors, isDirty}, 
    } = useFormContext();

    useEffect(() => {
        /*
          set edited true if field is changed 
          and edited is not true
        */

        if(isDirty && !edited) {
            setEdited( prevState => ({...prevState, initiative: true}));
        }
    }, [isDirty]);
   
    useEffect(() => {
        dispatch(setInitFormMonthDefault({start_date:initiative.start_date, end_date:initiative.end_date}));
    }, []);

    const defValue = initiative.owner_wwid ?  [{[config.owner_wwid.foreign_column_name]:initiative.owner_wwid, [config.owner_wwid.ref_display_value]:initiative.owner_wwid_dv}] : [{}];

    /**
     * If the start date is later than the end date, alert the user and don't change the date.
     * If the end date is earlier than the start date, alert the user and don't change the date.
     * If the dates are valid, change the date.
     */
    const handleDataChange = (e, type, field)=>{

        let date = new Date(e);
        const maxStringLength = 10;
        const newDate = date.toISOString().substring(0, maxStringLength);

        if(type === 'end_date'){
            /* This is a function that is called when the end date is changed. It checks if the end
            date is smaller than the start date. If it is, it alerts the user and doesn't change the
            date. If it isn't, it changes the date. */
            if(new Date(month.start_date) > new Date(newDate)){
                alert('End date cannot be smaller than start date');
            } else{
                dispatch(setInitFormMonth({key:type, value:newDate}));
                field.onChange(newDate);
            }
        } else {
            if(new Date(month.end_date) < new Date(newDate)){
                alert('Start date cannot be greater than end date');
            } else{
                dispatch(setInitFormMonth({key:type, value:newDate}));
                field.onChange(newDate);
            }
        } 
    };

    const getSelected = date => date === '' ? null : parseISO(date);
    
    return (<>
        <div data-testid='initiative-form-header-test'>
            
            <div className='initiative-form-wrapper'>
                <label className='initiative-form-label'>
                    <span>Name of Initiative <span style={{color:'red'}}>*</span></span>
                    <input 
                        type="text" 
                        placeholder="Name of Initiative" 
                        {...register('name', {required: getIsRequred(config.name.is_required), maxLength: config.name.character_maximum_length})} 
                        className='initiative-form-name' 
                        defaultValue={initiative.name}
                        data-testid='initiative-form-name'
                    />
                    {errorMessage(errors.name, config.name.character_maximum_length)}
                </label>
                <div className='initiative-form-group'>
                    <label className='initiative-form-label'>
                        <span>Initiative Type <span style={{color:'red'}}>*</span></span>
                        <Controller
                            name="type_id"
                            control={control}
                            defaultValue={initiative.type_id}
                            rules={{ required: getIsRequred(config.type_id.is_required) }}
                            render={field => (
                                <CustomSelectForHookForm  urlReq={urlIniTypes} field={field} defValue={initiative.type_id} name={'type_id'} defaultKeyNames={{label:'name', value:'id'}} hideId={true}/>
                            )}/>
                        {errorMessage(errors.type_id)}
                    </label>
        
                    <label className='initiative-form-label'>
                        <span>Initiative Owner <span style={{color:'red'}}>*</span></span>
                        <Controller
                            name="owner_wwid"
                            control={control}
                            defaultValue={initiative.owner_wwid}
                            rules={{ required: getIsRequred(config.owner_wwid.is_required) }}
                            render={field => (
                                <CustomAsyncSelectForHookForm  
                                    urlReq={urlWwid} 
                                    defValue={defValue} 
                                    
                                    defaultKeyNames={{label:config.owner_wwid.ref_display_value, value: config.owner_wwid.foreign_column_name}} 
                                    field={field} 
                                    name='owner_wwid' 
                                    hideId={false}/>
                            )}/>
                        {errorMessage(errors.owner_wwid)}
                    </label>
                </div>
            </div>
        
        
        
            <div className='initiative-form-wrapper'>
                <label className='initiative-form-label'>
                    <span>Description <span style={{color:'red'}}>*</span></span>
                    <textarea 
                        {...register('description', 
                            {required: getIsRequred(config.description.is_required), 
                                maxLength: config.description.character_maximum_length})} 
                        defaultValue={initiative.description} 
                        name='description'
                        data-testid='initiative-form-description'
                    />
                    {errorMessage(errors.description, config.description.character_maximum_length)}
                </label> 
                <div className='initiative-form-group'>
                    <div className='initiative-form-label' style={{width:'350px'}}>
                        <div  style={{width:'150px'}}>
                            <span>Start Date <span style={{color:'red'}}>*</span></span>
                            <Controller
                                name="start_date"
                                control={control}
                                defaultValue={initiative.start_date}
                                rules={{ required: getIsRequred(config.start_date.is_required)  }}
                                render={({field}) => (
                                    <DatePicker
                                    // sets the selected date
                                        selected={getSelected(month.start_date)} 
                                        // change handler for the date picker
                                        onChange={date => handleDataChange(date, 'start_date', field)} 
                                        // custom input component
                                        // this is only needed for to add test id
                                        customInput={<InputDatePicker testId={'initiative-form-start-date'} value={month.start_date} isDisabled={false}/>} 
                                        placeholderText='mm/dd/yyyy'
                                        showMonthDropdown 
                                        showYearDropdown 
                                        useShortMonthInDropdown
                                        yearDropdownItemNumber={8}
                                    />
                                )}/>
                            {errorMessage(errors.start_date)}
                                
                        </div> </div>
                    <div className='initiative-form-label' style={{width:'350px'}}>
                        <div style={{width:'150px'}}>
                            <span>End Date <span style={{color:'red'}}>*</span></span>
                            <Controller
                                name="end_date"
                                control={control}
                                defaultValue={initiative.end_date}
                                rules={{ required: getIsRequred(config.end_date.is_required)  }}
                                render={({field}) => (
                                    <DatePicker
                                    // sets the selected date
                                        selected={getSelected(month.end_date)} 
                                        // change handler for the date picker
                                        onChange={date => handleDataChange(date, 'end_date', field)} 
                                        // custom input component
                                        // this is only needed for to add test id
                                        customInput={<InputDatePicker testId={'initiative-form-end-date'} value={month.end_date} isDisabled={false}/>} 
                                        placeholderText='mm/dd/yyyy'
                                        showMonthDropdown 
                                        showYearDropdown 
                                        useShortMonthInDropdown
                                        yearDropdownItemNumber={8}
                                    />
                                )}/>
                        
                            {errorMessage(errors.end_date)}
                        
                        </div>
                    </div>
                    
                </div>
            
            </div>
          

        </div>
   
    </>
    );
};

InitiativeFormHeader.propTypes = {
    initiative: PropTypes.object,
    biRef: PropTypes.object,
    edited: PropTypes.bool,
    setEdited: PropTypes.func,
    setMonth: PropTypes.func,
    month: PropTypes.object,
    config:PropTypes.object
};


export default InitiativeFormHeader;