import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isUnauthorized, setUnauthorized } from '../../redux/features/rmrStateSlice';
import CustomPopup from './customPopup/CustomPopup';


const AuthReload = () => {
    const isAuth = useSelector(isUnauthorized);
    const dispatch = useDispatch();

    /**
     * The function refreshPage() will reload the page when called.
     */
    const  refreshPage = ()=> {
        window.location.reload(true);
    };

    // we will also set isUnauthorized state in redux to false,
    const handleClose = () => {
        dispatch(setUnauthorized(false));
    };

    // only show popup if unauthorized
    if(isAuth) {
        return (
            <CustomPopup>
                <p style={{fontSize:18, fontWeight:400, marginBottom:'18px'}}>The system has timed out due to inactivity, please refresh page.</p>
                <div className='rmr-home-popup'>

                    <button className='btn btn-trans' onClick={handleClose} style={{color:'blue'}}>Cancel</button>
                    <button className='btn btn-red' onClick={refreshPage}>Refresh</button>
                </div>
                
            </CustomPopup>
        );
    }

    return null;
};

export default AuthReload;