import { getBaseTspmCoreURL } from './getBaseUrls';
const baseTspmCoreURL = getBaseTspmCoreURL();

const backendUrls = {
    rmr_resource :baseTspmCoreURL + '/api/datasrcs/1/schemas/rmr/tables/rmr_resource/records?fields-all=display_name,username,wwid,employee_status&record-limit=10&search-fields=employee_status&search-term=active&search-fields=display_name,username,wwid&search-term=',
    rmr_initiative_type: baseTspmCoreURL + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_type/records',
    rmr_pay_grade: '/api/datasrcs/1/schemas/rmr/tables/rmr_pay_grade/records',
    rmr_bravo_reportable:'/api/datasrcs/1/schemas/rmr/tables/rmr_bravo_reportable/records',
    rmr_ldp:'/api/datasrcs/1/schemas/rmr/tables/rmr_ldp/records',
    rmr_country: '/api/datasrcs/1/schemas/rmr/tables/rmr_country/records',
    rmr_region: '/api/datasrcs/1/schemas/rmr/tables/rmr_region/records',
    rmr_legacy: '/api/datasrcs/1/schemas/rmr/tables/rmr_legacy/records',
    rmr_disposition: '/api/datasrcs/1/schemas/rmr/tables/rmr_disposition/records',
    rmr_type: '/api/datasrcs/1/schemas/rmr/tables/rmr_type/records',
    rmr_chapterarea_platformgroup: '/api/datasrcs/1/schemas/rmr/tables/rmr_chapterarea_platformgroup/records',
    rmr_chapter_platform: '/api/datasrcs/1/schemas/rmr/tables/rmr_chapter_platform/records',
    rmr_subchapter:  '/api/datasrcs/1/schemas/rmr/tables/rmr_subchapter/records',
    rmr_position: '/api/datasrcs/1/schemas/rmr/tables/rmr_position/records',
    rmr_position_resource: '/api/datasrcs/1/schemas/rmr/tables/rmr_position_resource/records',
    rmr_position_hierarchy: '/api/datasrcs/1/schemas/rmr/tables/rmr_position_hierarchy/records',
    rmr_position_pay_grade: '/api/datasrcs/1/schemas/rmr/tables/rmr_position_pay_grade/records',
    rmr_position_bravo_reportable: '/api/datasrcs/1/schemas/rmr/tables/rmr_position_bravo_reportable/records',
    rmr_position_ldp: '/api/datasrcs/1/schemas/rmr/tables/rmr_position_ldp/records',
    rmr_position_location: '/api/datasrcs/1/schemas/rmr/tables/rmr_position_location/records',
    rmr_position_disposition_function: '/api/datasrcs/1/schemas/rmr/tables/rmr_position_disposition_function/records',
    rmr_position_config: '/api/datasrcs/1/schemas/rmr/tables/rmr_position/config',
    rmr_cost_center: '/api/datasrcs/1/schemas/rmr/tables/rmr_cost_center/records',
    rmr_position_cost_center: '/api/datasrcs/1/schemas/rmr/tables/rmr_position_cost_center/records'
};

export {backendUrls};