import React, {useEffect, useState} from 'react';
import './resource.css';
import ResourceDatagrid from './ResourceDatagrid';
import { useParams, NavLink } from 'react-router-dom';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import { getRequest } from '../../services/axiosClient';
import PropTypes from 'prop-types';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {useSelector} from 'react-redux';
import { user } from '../../redux/features/userSlice';
import ErrorStatus from '../../components/common/ErrorStatus';
import logPageView from '../../utils/logPageView';

const MyResources = props => {
    const params = useParams();

    const {userName,wwid} = useSelector(user);
 
    const baseUrl = getBaseTspmCoreURL();
    const [tableData, setTableData] = useState({fetched:false, allocation:[], recovery:[]});
    const [resource, setResource] = useState({fetched:false, userRes:[]});
    const [resourceUser, setResourceUser] = useState({user_wwid: null, user_position_id:null});
    const [error, setError] = useState({status:false, message:''});

    const getAllocationRecovery = async id=>{
        
        try {
            const resView = await getRequest(baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/manager_view_allocation?filters=${id}`);
            const resRecovery = await getRequest(baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/manager_view_appdev?filters=${id}`);
            
            // if returned data have error res.data.data will be undefined and we will set emptyy array
            setTableData({fetched:true, allocation:resView.data.data || [], recovery:resRecovery.data.data || []});
        } catch (err) {
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting user resource data, please refresh page or try again later' + msg});
        }
    };

    const getUserResource = async type=>{
        const urlData = `/api/datasrcs/1/folder/rmr/customQuery/my_resource_view_${type}?filters=${params[type]}`;
        
        try {
            const res = await getRequest(baseUrl + urlData); 
            
            /* Checking if the response from the API is empty or not. If it is empty, it will set the
            redux myResourceUser state to null. If it is not empty, it will set the state to the response.  
            this will be used to set if user have no resources to manage*/

            
            if(res.data.rowCount === 0){
                setResourceUser({user_wwid:null, user_position_id:null});
                setResource({fetched:true, userRes:[]});
                return;
            }  
            const userData = {
                user_wwid : res.data.data[0].manager_wwid,
                user_name: res.data.data[0].manager_name,
                user_position_id: res.data.data[0].manager_position_id,
                next_level_manager: res.data.data[0].next_level_manager,
                next_level_manager_wwid: res.data.data[0].next_level_manager_wwid,
                next_level_manager_position_id:res.data.data[0].next_level_manager_position_id
            };
                //calling function with position id
            if(type === 'wwid'){getAllocationRecovery(userData.user_position_id);}
                

            // setting user info into redux
            setResourceUser(userData);
            const resour = res.data.data.map(item=> {
                return { 
                    resource_name: item.resource_name,  
                    resource_position:item.resource_position, 
                    resource_wwid:item.resource_wwid, 
                    notes_actions: item.notes_actions === null ? '' : item.notes_actions,
                    has_resources_flag: item.has_resources_flag
                };

            });

            setResource({fetched:true,  userRes:resour});
            
  
        } catch (err) {
            const msg = err?.request?.statusText ? ` - ${err?.request?.statusText}!` : '!';
            setError({status:true, message:'Error with getting user resource data, please refresh page or try again later' + msg});
        }
    };

    useEffect(() => {
        if(props.type === 'wwid'){
            getUserResource('wwid');
        } else{
            getUserResource('positionid');
            getAllocationRecovery(params.positionid);
        }
        logPageView();
    }, [params]);

    return (
        <>
            {resourceUser.user_position_id !== null ? 
                <div className="my-resources-container">
                    <ErrorStatus error={error} setError={setError}/>
                    <h2>Manager Resource View : {resourceUser?.user_name}</h2>
                    <div className="my-resources-controls">
                    
                        {resourceUser.next_level_manager_position_id  ? <NavLink
                            to={`/updateMyResources/positionid/${resourceUser?.next_level_manager_position_id}`}
                            key={resourceUser.next_level_manager_position_id}
                            className="btn btn-green"
                           
                        >
                            <ArrowUpwardIcon/> Up to {resourceUser?.next_level_manager}
                        </NavLink> : <span></span>} 
                        
                    
                        <div className="my-resources-info">
                            <p style={{fontWeight: 'bold'}}>
            Monthly Initiative Allocation and App Dev Billable Recoverability
            Percentage
                            </p>{' '}
                            <p>
            Total Allocations represent all initiative demand that resource is
            fullfilling
                            </p>
                            <p>
                                <span style={{color: 'red', margin: 0, fontWeight: 'bold'}}>
                                    {'<90% '}
                                </span>
                                <span style={{color: 'green', fontWeight: 'bold'}}>
                                    {'Between 90% and 110% '}
                                </span>
                                <span style={{color: 'purple', fontWeight: 'bold'}}>
                                    {'>110%'}
                                </span>
                                <span style={{fontStyle: 'italic'}}>
                                    { ' '}Italics indicate pending SOWs
                                </span>
                            </p>
                        </div>
                    </div>
            
                    {(tableData.fetched && resource.fetched) ? <ResourceDatagrid tableData={tableData} type={props.type} userRes={resource.userRes} /> : <LoadingAnimation/>}
                        
                </div> : <div style={{marginLeft: '2rem'}} className='centered-info'>{
                    wwid === params[props.type] ? 
                        <><strong style={{textTransform:'uppercase'}}>{userName}</strong> does not have any resources to manage.</>  
                        :<><strong>{params[props.type]}</strong> {props.type === 'wwid' ? 'wwid' : 'position id'} does not have any resources to manage or it is not valid.</> 
                }  
                </div>

                
            }<br/>
        </>
    );
};

MyResources.propTypes = {
    type: PropTypes.string,
};

export default MyResources;
