import { createSlice } from '@reduxjs/toolkit';

// create a slice of the store's state called 'user' with an initial state object
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        value: {},
        isAdmin:false
    },
    // define the different reducers for the slice
    reducers: {
        // setUser is used to set the user data and isAdmin status in the user slice
        setUser: (state, action) => {
            // store the user data in session storage
            sessionStorage.setItem('user', JSON.stringify({userName: action.payload.user.userName}));
            // set the user data in the state
            state.value = action.payload.user;
            // set the isAdmin status in the state
            state.isAdmin = action.payload.isAdmin;
        },
    },
});

// export the actions that can be dispatched
export const { setUser } = userSlice.actions;

// export the selectors
export const user = state => state.user.value;
export const isAdmin = state => state.user.isAdmin;

// export the reducer
export default userSlice.reducer;