import React,{useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {DataTable} from '@jauj/reactpkg-tsis-datatable';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { NavLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const ResourceAllocation = props => {
    const {data,year,demand,edited, setEdited,resSkills} = props;
  
    //month obj
    const month = {1:`${year} Jan`,2: `${year} Feb`,3: `${year} Mar`,4: `${year} Apr`, 5:`${year} May`, 6:`${year} Jun`,
        7: `${year} Jul`, 8:`${year} Aug`, 9:`${year} Sep`, 10:`${year} Oct`,11: `${year} Nov`,12: `${year} Dec`};

    /* Setting the state of the component. */
    const [columns, setColumns] = useState();
    const [columnDemand, setColumnDemand] = useState();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [demandRows, setDemandRows] = useState([]);
    const [ids,setIds] = useState([]);
    const [hideResource, setHideResource] = useState(false);

    const handleDelete = (e,id)=>{
        e.preventDefault();
        let obj = {};
        setRows(prevState => {
            return prevState.filter(item =>{
                if(item.initiative_demand_id ===id){
                    obj.row = item;
                }
                return item.initiative_demand_id !==id;
            });
        });
        setDemandRows(prevState => {
            const newState = [...prevState];
            newState.push(obj.row);
            return newState;
        });
        if(!edited) setEdited( prevState => ({...prevState, allct: true}));
    };

    const handleAdd = (e,id)=>{
        e.preventDefault();
        let obj = {};
       
      
        setDemandRows(prevState => {
            return prevState.filter(item => {
                if(item.initiative_demand_id === id){
                    obj =item;
                }
                return item.initiative_demand_id !==id;
            });
        });
        setRows(prevState => {
            const newState = [...prevState];
            newState.push(obj);
            return newState;
        });  
        if(!edited) setEdited( prevState => ({...prevState, allct: true}));
      
    };

    const setRowData = async (alctData, demandData, monthArrs)=>{

        setLoading(true);
        //column definitions for allocations
        const colData =[
            {
                accessorFn : row => row.initiative_demand_id,
                header: '',
                id: 'initiative_demand_id',
                disableFilter: true,
                minWidth: 20,
                width:20,
                cell:info => (
                    <button className='btn btn-trans'
                        onClick={e=>handleDelete(e,info.getValue())} 
                        style={{paddingLeft:'5px', paddingRight:'5px'}}
                        title="Delete allocation"
                        data-testid='allocation-delete-btn'
                    >
                        <DeleteForeverIcon />
                    </button>
                ),
            }];

        // column definitions for demands
        const colDemand =[
            {
                accessorFn : row => row.initiative_demand_id,
                header: '',
                id: 'initiative_demand_id',
                disableFilter: true,
                minWidth: 20,
                width:20,
                cell:info => (
                    <button className='btn btn-trans'
                        onClick={e=>handleAdd(e,info.getValue())} 
                        style={{paddingLeft:'5px', paddingRight:'5px', fontWeight:'500'}}
                        title="Add allocation"
                        data-testid='allocation-add-btn'
                    >
                        <strong>Add</strong> 
                    </button>
                ),
            }];

        const getMatching = (type, dat) => resSkills[type].includes(dat) ? <span className='resource-matching-skills'>{dat}</span> : dat;

        // definin custom table column definitions
        const colCommonData = [
            {   
                accessorKey: 'initiative_id',    
                disableFilter: true,
                header: 'Initiative ID',
                minWidth: 130,
                footer:''
            },
            {
                
                accessorKey: 'initiative',
                disableFilter: true,
                header: 'Initiative',
                minWidth: 130,
                cell:info => (
                    <NavLink to={`/initiativeForm/${info.row.original.initiative_id}`} className={'initiative-link'} style={{fontWeight:'400'}}>{info.getValue()}</NavLink>
                ),
                footer:''
            },{
                
                accessorKey: 'project_id',
                disableFilter: true,
                header: 'Project ID',
                minWidth: 100,
                footer:'Summary'
            },{
                
                accessorKey: 'role',
                disableFilter: true,
                header: 'Role',
                minWidth: 50,
                cell: info => (
                    getMatching('roles',info.getValue())
                ),
                footer:''
            },{
                
                accessorKey: 'skill',
                disableFilter: true,
                header: 'Skill',
                minWidth: 50,
                cell: info => (
                    getMatching('skills',info.getValue())
                ),
                footer:''

            },{
                
                accessorKey: 'technology', 
                disableFilter: true,
                header: 'Technology',
                minWidth: 150,
                colspan:2,
                cell:info => (
                    getMatching('technology',info.getValue())
                ),
                footer:(
                    <div className='resource-table-footer'>
                        <span>Allocation Hrs</span>
                        <span>Capacity Hrs</span>
                        <span>Percent Allocation</span>
                    </div>
                )
            }];

        const newAlctData = [];
        const newDemandData = [];
        const hashObj ={};
        const hashObjDemand= {};


        
        
        /* Creating a new array with unique id, initiative,  project_id. for allocation data */
        alctData.forEach(row => {
            
            // here we are setting row data except month, for month we will handle it differently
            if(!hashObj[row.initiative_demand_id]) {
                newAlctData.push({
                    initiative_demand_id:row.initiative_demand_id, 
                    initiative:row.initiative, 
                    project_id:row.project_id, 
                    role:row.role, 
                    skill:row.skill, 
                    technology:row.technology, 
                    initiative_id:row.initiative_id
                });
                // add id to hash map 
                hashObj[row.initiative_demand_id] = 1;
            }
        });

        // we do same creation for demand allocation
        demandData.forEach(row => {
            // here we are setting row data except month, for month we will handle it differently
            if(!hashObjDemand[row.initiative_demand_id]) {
                newDemandData.push({
                    initiative_demand_id: row.initiative_demand_id, 
                    initiative:row.initiative, 
                    project_id:row.project_id, 
                    role:row.role, 
                    skill:row.skill, 
                    technology:row.technology,
                    initiative_id:row.initiative_id
                });
                hashObjDemand[row.initiative_demand_id] = 1;
            }
        });

        const capacityHrs = {};

        const totalCols = 13;
        const maxValue = 138.7;
        for (let k =1; k< totalCols; k++) {
            capacityHrs[k] = maxValue;
        }
        newAlctData.forEach(row => {
            /* Adding the month to the row. from start of initial month to end of year */
            for (let s = 1; s < totalCols; s++) {
                const  asd = monthArrs[s];
                row[asd] = 0;
            }

            /* Adding the hours to the added month */
            alctData.forEach(item => {
                if(item.initiative_demand_id === row.initiative_demand_id){
                    row[monthArrs[item.mo]] = item.allocation_hrs;
                    capacityHrs[item.mo] = item.capacity_hrs;
                }
            });
        });
        newDemandData.forEach(row => {
            /* Adding the month to the row. from start of initial month to end of year */
            for (let p = 1; p < totalCols; p++) {
                const akp = monthArrs[p];
                row[akp] = 0;
            }

            /* Adding the hours to the added month */
            demandData.forEach(item => {
                if(item.initiative_demand_id === row.initiative_demand_id){
                    row[monthArrs[item.mo]] = item.hrs;
                }
            });
        });
        
        //sonar
        const hundred = 100;
        const ninety = 90;
        const hundredTen = 110;
        // here we are setting columnd definitions and calculatiing sum and percent
        for (let i = 1; i < totalCols; i++) {
            const monthName = monthArrs[i];
            let sum = 0;

            // setting sum for total field
            newAlctData.forEach(item => {
                sum = parseFloat(item[monthName]) + sum;
            });
            
            const percent = Math.round((sum / maxValue)*hundred);
            
            const getColor = num =>  {
                const checkOverHund = num > hundredTen ? '#BC67FA' : 'green';
                return num < ninety ? 'red' : checkOverHund;
            };

    
            //defining default column info, sum is for footer area
            const colinfo = {
                id: monthName,
                accessorKey: monthName,
                disableFilter: true,
                header: (
                    <div style={{
                        width:'60px', 
                        height:'55px', 
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {monthName}
                    </div>
                ),
                minWidth: 60,
                width:60,
                footer: (
                    <div className='resource-table-footer'>
                        <span style={{color:getColor(percent)}}>{sum}</span>
                        <span >{capacityHrs[i]}</span>
                        <span style={{color:getColor(percent)}}>{percent}%</span>
                    </div>
                )
            }; 
            
            /* adding column definition to common defined array, 
            since demand and allocation have same fields 
            we will use column info we create for allocation for demand table too*/
            colCommonData.push(colinfo);
        }
        
        // using same column definition for demand and allocation
        setColumns([...colData,...colCommonData]);
        setColumnDemand([...colDemand,...colCommonData]);

        setRows(newAlctData);
        setDemandRows(newDemandData);

        // setting ids to use for save to get which ids are deleted or added
        setIds(newAlctData.map(id => id.initiative_demand_id));
        setLoading(false);
    };

    useEffect(() => {
        setRowData(data.data, demand.data, month);
    }, [data.data, demand.data]);
  
    /**
     * Compares the current rows ids with the default rows ids and returns an object with the added and
     * deleted rows.
     * @returns An object with two properties, added and deleted.
     */
    const getData = ()=>{
        const newRows = [...rows];
        const currentIds = [];
        
        const added = [];
        const deleted = [];
        newRows.forEach(row => {
            if(!ids.includes(row.initiative_demand_id)){
                added.push(row.initiative_demand_id);
            }
            currentIds.push(row.initiative_demand_id);
        });
        ids.forEach(id=> {
            if(!currentIds.includes(id)){
                deleted.push(id);
            }
        });  
        return {added:added, deleted:deleted};
    };

    props.biRef.getAllocationResult = getData;
    /**
     * Hide resources
     */
    const handleHideResource = e =>{
        e.preventDefault();
        setHideResource(prevState => !prevState);
    };
    return (
        <div className='resource-sow-section' data-testid='resource-sow'>
           
            <div className='resource-sow-table'>
                <div className='resource-sow-section-title'>RMR Initiative Allocations</div>
                {loading ? <LoadingAnimation type={'dot'} /> : 
                    <div>
                    
                        <DataTable
                            {...{
                                tableData:rows,
                                columnConfig:columns, 
                                disableDownload:true,
                                showFooter:true,
                                disableSearch:true
                            }}
                        />
                        <div className='resource-allct-tooltip'>Percent Allocation Legend: <span style={{color:'red'}}>{'< 90%'}</span> <span style={{color:'green'}}>Between 90% and 110%</span> <span style={{color:'#BC67FA'}}> {'> 110%'}</span>  </div>
                        <br /><br />
                        
                        <div className='resource-alct-section-title'>Recommended Demand Opportunities</div>
                        {demandRows.length > 0 ? <>
                            {hideResource  ? 
                                <button 
                                    onClick={handleHideResource} 
                                    className='btn btn-trans'
                                    title="Show Recommended Demand Opportunities" 
                                    style={{padding:0}} > <AddIcon/>
                                </button> :
                                <button 
                                    onClick={handleHideResource} 
                                    className='btn btn-trans' 
                                    title="Hide Recommended Demand Opportunities" 
                                    style={{padding:0}}><RemoveIcon/>
                                </button> }

                            <div style={{display: hideResource ? 'none' : 'block'}} data-testid='demand-table'>
                            
                                <DataTable
                                    {...{
                                        tableData:demandRows,
                                        columnConfig:columnDemand, 
                                        disableDownload:true,
                                        showFooter:false,
                                        disableSearch:true
                                    }}
                                />
                            </div></>
                            : 
                            <div>
                                    There are zero demand opporunities that match the Roles, Skills and Technologies of this resource.
                            </div>} 
                        
                    </div>
                }
            </div>
            
        </div>
    );
};

ResourceAllocation.propTypes = {
    data: PropTypes.object.isRequired,
    year: PropTypes.number.isRequired,
    demand:PropTypes.object.isRequired,
    setEdited: PropTypes.func.isRequired, 
    edited: PropTypes.bool.isRequired,
    biRef: PropTypes.object.isRequired,
    resSkills: PropTypes.object.isRequired,
};

export default ResourceAllocation;