import React, {useEffect, useState} from 'react';
import { getRequest } from '../../services/axiosClient';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {  haveResourcePage , setHaveResourcePage} from '../../redux/features/homeSlice';
import ErrorStatus from '../../components/common/ErrorStatus';

const HomeResources = props => {
    
    const dispatch = useDispatch();
    const userResView = useSelector(haveResourcePage);
    const {userWwid, url } = props;
    const [error, setError] = useState({status:false, message:''});

    const getInitialResView = async()=>{
        try {
            // check if user have resource page 
            const res = await getRequest(url);
         
            /* Checking if the response from the API is empty or not. If it is empty, it will set the
            state to false, if it is not empty, it will set the state to true. */
            res.data.rowCount === 0 ? dispatch(setHaveResourcePage({fetched:true, resourcePage:false})) : dispatch(setHaveResourcePage({fetched:true, resourcePage:true}));
        } catch (err) {
            // setting haveResourcePage state to false
            dispatch(setHaveResourcePage({fetched:false, resourcePage:false}));
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Unable to get user link, please try later' + msg});
           
        }
    };

    useEffect(() => {
        /* Checking if the userResView.fetched 
        is false, if it is false, 
        it will call the
        getInitialResView function. */
        if(!userResView.fetched){
            getInitialResView();
        }
        
    }, []);
    

    return (
        <section className='rmr-home-resource'>
            <div className='rmr-home-header'>
                Resources
            </div>
            <ErrorStatus error={error} setError={setError}/>
            <div className='rmr-home-link'>
                {userResView.resourcePage ? <Link className='link-regular' to={`/resourceForm/${userWwid}`}>View/Update My Profile</Link> : 
                    <p>You do not manage any recoverable resources and therefore have no action to take.</p>} 
            </div>
        </section>
    );
};

HomeResources.propTypes = {
    userWwid: PropTypes.string,
    url: PropTypes.string,
};

export default HomeResources;