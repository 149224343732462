import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import { getRequest } from '../../../services/axiosClient';
import PropTypes from 'prop-types';
import ErrorStatus from '../ErrorStatus';

const CustomSelectForHookForm = props => {
    const [options, setOptions] = useState([]);
    const [error, setError] = useState({status:false, message:''});
    const field = props.field?.field;
    const value = props.field?.field.value;
    const uniqueIdName = props.defaultKeyNames.value;
    const uniqueDisplayName = props.defaultKeyNames.label;
    
    /**
     * GetSelectOptions is an async function that makes a get request 
     * to the urlReq prop, and sets the
     * options state to the data returned from the request.
     */
    const getSelectOptions= async ()=>{
        try {

            const res= await getRequest(props.urlReq); 
            setOptions(res.data.data);
            
        } catch (err) {
            //setting error
            const msg = err?.request.statusText ? ` - ${err.request.statusText}!` : '!';
            setError({status:true, message:'Error!, please refresh page or try again later' + msg});
            
        }
    };

    useEffect(() => {
        //check if urlReq prop is given
        // if it does get select options from api call
        // else set options from given options prop.
        if(props.urlReq){
            getSelectOptions();
        } else {
            setOptions(props.options);
        }
    }, []);
   
    //getting option label
    const optionLabel = option=> props.hideId ? `${option[uniqueDisplayName]}` :  `${option[uniqueIdName]} - ${option[uniqueDisplayName]}`;
   
    const handleChange = val=> {
        // when changing value assign the value
        // only if value is valid
        if(val){
            field.onChange(val.id);
        }
        // this will be triggered when area is cleared
        else {
            field.onChange(null);
        }
    };


    return (
        <>
            <ErrorStatus error={error} setError={setError} />
            <Select        
                options={options}
                onChange={val => handleChange(val)}
                getOptionLabel={option => optionLabel(option)}
                getOptionValue={option => option[uniqueIdName]}
                ref={field?.ref}
                name={props.name}
                value={options?.find(c => c[uniqueIdName]=== value)}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                onBlur={field.onBlur}
                inputuRef={field.ref}
                isClearable={props.isClearable || false}
            />
        </>
        
    );
};

CustomSelectForHookForm.propTypes = {
    urlReq: PropTypes.string,
    defValue: PropTypes.any,
    handleChange: PropTypes.func,
    options: PropTypes.array,
    field: PropTypes.any,
    name: PropTypes.string,
    defaultKeyNames: PropTypes.object,
    hideId: PropTypes.bool,
    isClearable: PropTypes.bool,
    
};


export default CustomSelectForHookForm;