export const getBaseURL = () => {
    const { hostname, port, protocol } = window.location;

    const hostName = port ? `${hostname}:4000` : `${hostname}`;
    const apiHostName = hostName.replace(
        'rmr.jnj.com',
        'api.rmr.jnj.com'
    );

    return hostName == 'localhost:4000'
        ? `${protocol}//${hostName}`
        : `${protocol}//${apiHostName}`;

};

export const getBaseTspmCoreURL = () => {
    // retrieve the hostname, port, and protocol of the current location
    const { hostname, port, protocol } = window.location;
    
    // format the hostname to include the port if it exists
    const hostName = port ? `${hostname}:4000` : `${hostname}`;
    
    // replace the hostname with the API hostname
    const apiHostName = hostName.replace(
        'rmr.jnj.com',
        'tsis.jnj.com'
    );

    // check if the hostname is 'localhost:4000'
    // if it is, return the protocol and hostname
    // otherwise, return the protocol and API hostname
    return hostName == 'localhost:4000'
        ? `${protocol}//${hostName}`
        : `${protocol}//${apiHostName}`;

};