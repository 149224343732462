import React,{useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { getRequest } from '../../services/axiosClient';
import Select from 'react-select';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import ResourceSowSection from './ResourceSowSection';
import ResourceAllocation from './ResourceAllocation';
import { useSelector } from 'react-redux';
import { resourceSkills } from '../../redux/features/resourceFormSlice';
import ErrorStatus from '../../components/common/ErrorStatus';

const ResourceSow = props => {
    const {wwid, baseUrl} = props;
    const [options, setOptions] = useState();
    const resSkills = useSelector(resourceSkills);
    /* COMPONENT STATE */
    const [year, setYear] =useState(new Date().getFullYear());
    const [sow, setSow] = useState({fetched:false, billable:{}, nonBillable:{}, pending:{}});
    const [allocation, setAllocation] = useState({fetched:false,allocation:{}, demand:{}});
    const [error, setError] = useState({status:false, message:''});

    /**
        getting SOW data with changed year parameter.
    */
    const getSowData = async nYear => {
        try {
            const resBillable = await getRequest(baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/resource_view_sow_details?filters=${wwid}&filters=Billable&filters=${nYear}`);
            const resNonBillable = await getRequest(baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/resource_view_sow_details?filters=${wwid}&filters=Non-Billable&filters=${nYear}`);
            const resPending = await getRequest(baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/resource_view_sow_details?filters=${wwid}&filters=Pending&filters=${nYear}`);
            setSow({fetched:true, billable:resBillable.data, nonBillable:resNonBillable.data, pending:resPending.data});
        } catch (err) {
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting user SOW data, please refresh page or try again later' + msg});
        }
    };
 
    const getAllocationData = async nYear => {
        setAllocation({fetched:false,allocation:{}, demand:{}});
        const newYear = nYear ? nYear : year;
        try {
            const resAllct = await getRequest(baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/resource_view_allocation?filters=${wwid}&filters=${newYear}`);
            const resAllctDemand = await getRequest(baseUrl + `/api/datasrcs/1/folder/rmr/customQuery/resource_view_demand_opportunity?filters=${wwid}&filters=${newYear}`);
            setAllocation({fetched:true, allocation:resAllct.data, demand:resAllctDemand.data});
        } catch (err) {
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting user data, please refresh page or try again later' + msg});
        }
    };

    /* 
        setting options to 3 years and getting SOW data;
    */
    useEffect(() => {
        const newDate = new Date().getFullYear();
        const newOptions = [];
        getSowData(newDate);
        getAllocationData(newDate);
        const two = 2;
        for(let i = -1; i < two; i++){
            const data = {value:newDate + i, label:newDate + i };
            newOptions.push(data);
        }
        setOptions(newOptions);
    }, []);
    

    // getting SOW data on each date change
    const handleYearChange = e =>{
        if(e.value !== year){
            setYear(e.value);
            setSow({fetched:false});
            getSowData(e.value);
            getAllocationData(e.value); 
        }
    };
    
    
    props.biRef.getAllctData = getAllocationData;
    return (
        <div>
            <div className='resource-sow-select'>
                <span className='resource-sow-select-title'>Select Year:</span>
                <Select
                    options={options}
                    onChange={handleYearChange}
                    value={options?.find(c => c.value === year)}
                />
            </div>
            {(resSkills.fetched && allocation.fetched) ? <ResourceAllocation data={allocation.allocation} year={year} demand={allocation.demand} setEdited={props.setEdited} edited={props.edited} biRef={props.biRef} resSkills={resSkills}/> : <LoadingAnimation type={'dot'}/>}
            <hr />
            <ErrorStatus error={error} setError={setError}/>
            {
                sow.fetched ? 
                    <div>
                        <ResourceSowSection data={sow.billable} type='Billable' year={year}/>
                        <hr/>
                        <ResourceSowSection data={sow.nonBillable} type='AS Internal' year={year}/>
                        <hr/>
                        <ResourceSowSection data={sow.pending} type='Pending' year={year}/>
                    </div>
                    : <LoadingAnimation type={'dot'}/>
            }
        </div>
    );
};

ResourceSow.propTypes = {
    wwid: PropTypes.string,
    baseUrl: PropTypes.string,
    setEdited: PropTypes.func, 
    edited: PropTypes.bool,
    biRef: PropTypes.any,
};

export default ResourceSow;