import React,{useEffect, useState} from 'react';
import { useSelector , useDispatch} from 'react-redux';
import {setInitiativeTable, initTable} from '../../redux/features/initiativeSlice';
import { getRequest } from '../../services/axiosClient';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import {DataTable} from '@jauj/reactpkg-tsis-datatable';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import AddIcon from '@mui/icons-material/Add';
import './initiative.css';
import { NavLink } from 'react-router-dom';
import ErrorStatus from '../../components/common/ErrorStatus';
import logPageView from '../../utils/logPageView';
const columns = [{
    accessorFn : row => row.initiative_id,
    header: 'Id',
    id: 'initiative_id',
    minWidth: 80,
    cell:info => (
        <NavLink to={`/initiativeForm/${info.getValue()}`} className={'initiative-link'}>{info.getValue()}</NavLink>
    ),
},{
    accessorKey:'owner_name', 
    header: 'Owner Name',
    minWidth: 130,
},{
    accessorKey:'initiative_type', 
    header: 'Type',
    minWidth: 100,
},{
    accessorKey:'initiative_name', 
    header: 'Name',
    minWidth: 100,
    cell:info => (
        <NavLink to={`/initiativeForm/${info.row.original.initiative_id}`} className={'initiative-link'} style={{fontWeight:'400'}}>{info.getValue()}</NavLink>
    ),
},{
    accessorKey:'initiative_description', 
    header: 'Description',
    minWidth: 100,
},{
    accessorKey:'start_date', 
    header: 'Start Date',
    minWidth: 88,
    dataType:'date',
    filterFn:'dateFilter'
},{
    accessorKey:'end_date', 
    header: 'End Date',
    minWidth: 88,
    dataType:'date',
    filterFn:'dateFilter'
},{
    
    accessorKey:'projects', 
    header:'Projects',
    minWidth: 100,
}
];



const Initiatives = () => {
    const dispatch =useDispatch();
    const {fetched, data} = useSelector(initTable);
    const [error, setError] = useState({status:false, message:''});

    const getInitiativeTable = async()=>{
        try {
            const res = await getRequest(getBaseTspmCoreURL() + '/api/datasrcs/1/folder/rmr/customQuery/initiative_page_table'); 
            dispatch(setInitiativeTable({fetched: true, data: res.data.data, colNames: res.data.columns}));
            
        } catch (err) {
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting initiative table, please refresh page or try again later' + msg});
        }
    };

    useEffect(() => {
        if (!fetched) {
            getInitiativeTable();
        }
        logPageView();
    }, []);
    

    return ( 
        <div className='initiative-main'>
            <div className='initiative-header'>
                <h1>Initiatives</h1>
                <NavLink
                    to={'/initiativeForm/new'}
                    className="btn btn-green"
                >
                    <AddIcon/>Create New Initiative
                </NavLink>
            </div>
        
            <ErrorStatus error={error} setError={setError}/>
            {fetched && !error.status ? 
                <DataTable
                    {...{
                        tableData:data,
                        columnConfig:columns, 
                        disableDownload:false,
                        defaultFileName:'Initiatives', 
                        showFooter:false,
                    }}
                />: <LoadingAnimation type={'dot'} />}
        </div>);
};

export default Initiatives;