import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import InitiativeFormHeader from './InitiativeFormHeader';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import {postRequest, deleteRequest, putRequest} from '../../services/axiosClient';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import InitiativeFormProject from './InitiativeFormProject';
import axios from 'axios';
import { useForm, FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setInitFormSaveStatus, initFormSaveStatus, initDemandIsValid } from '../../redux/features/initiativeFormSlice';
import { setInitiativeTable } from '../../redux/features/initiativeSlice';
import InitiativeDemand from './InitiativeDemand';

const InitiativeFormProvider= props => {
    const {initiative, config, initiativeId }= props;
    const navigate = useNavigate();
    const baseUrl = getBaseTspmCoreURL();
    const dispatch = useDispatch();
    const biRef = { };
    const demandRef = { };

    const apiResult = useSelector(initFormSaveStatus);
    const demandIsValid = useSelector(initDemandIsValid);
    
    /* COMPONENT STATE */
    const [edited, setEdited] = useState({initiative:false, project:false, demand:false});
    const [saving, setSaving] = useState(false);

    const methods = useForm({
        defaultValues:initiative,
        mode:'onBlur'
    });

    //reading dirty values from form state
    const {dirtyFields} = methods.formState;   
    
    const dirtyValues = (dirtyFieldss, allValues) => {
        // NOTE: Recursive function.
       
        // If *any* item in an array was modified, the entire array must be submitted, because there's no
        // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
        if (dirtyFieldss === true || Array.isArray(dirtyFieldss)) {
            return allValues;
        }
      
        // Here, we have an object.
        return Object.fromEntries(
            Object.keys(dirtyFieldss).map(key => [
                key,
                dirtyValues(dirtyFieldss[key], allValues[key])
            ])
        );
    };

    const onSubmit = data => {
        const changedValues = dirtyValues(dirtyFields, data);
        
        /* Checking if the project or initiative section is edited. If it is edited, it will check if
       the demand section is valid. If it is valid, it will call the handleSaveApiCall function. */
        if((edited.project || edited.initiative) && demandIsValid){
            handleSaveApiCall(changedValues);
        } 

        if(edited.demand){
            demandRef.submitDemand();
        } 
    };
    
    const handleSaveApiCall = async data => {
        setSaving(true);
        const apiCallArray = [];

        // checks project section and adds apiCall array.
        if(edited.project){
            
            const arrSelectValues = biRef.getProjectOptions();
            const addedItems = arrSelectValues.added;
            const deletedItems = arrSelectValues.deleted;
            
            /* Checking if the addedItems array is not empty. If it is not empty, it will send a post
            request to the URL. */
            if(addedItems.length !== 0){
                const send = postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_project_initiative_rel/upload', addedItems);
                apiCallArray.push(send);
            }

            /* checking if deleted array is not empty then
            sending delete api call for each of them */
            if(deletedItems.length !== 0){
                deletedItems.forEach(item => {
                    const res = deleteRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_project_initiative_rel/records/${item.id}`);
                    apiCallArray.push(res);
                });
            }
        }
 
        /* This is checking if the initiative section is edited. If it is edited, it will check if the
        initiativeId is new. If it is new, it will send a post request to the URL. If it is not new,
        it will send a put request to the URL. */
        if(edited.initiative){
            if(initiativeId !== 'new'){
                const res = putRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_initiative/records/${initiativeId}`, data);
                apiCallArray.push(res);
            } else{
                try {
                    const res = await postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative/records', data);
                    const id = res.data.data[0].id;
                    navigate(`/initiativeForm/${id}`);
                } catch (err) {
                    dispatch(setInitFormSaveStatus({status: true, type: 'fail', message: 'Failed to save changes, please refresh page and try again - ' + err?.response?.statusText || '!'}));
                    setSaving(false); 
                }
            }
        }

        /* Making an array of API calls and then using axios.all to make all the calls at once. */
        axios.all(apiCallArray).then(()=>{
            if(edited.project) {
                biRef.getProjectDefaultValues();
            }
            setEdited({...edited,
                project: false, initiative: false, 
            });
        }).then(()=>{
            setSaving(false);
            dispatch(setInitFormSaveStatus({status: true, type: 'success', message: 'Your changes have been successfully saved!'}));
            dispatch(setInitiativeTable({fetched:false, data:[]}));
        })
            .catch(errors => {
                dispatch(setInitFormSaveStatus({status: true, type: 'fail', message: 'Failed to save changes, please refresh page and try again!'}));
                console.log(errors);
                setSaving(false); 
            });
    };
 
    // sonar
    const fiveTho = 5000;
    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch(setInitFormSaveStatus({status: false, type: '', message: ''}));
        }, fiveTho);
        return () => {
            clearTimeout(timeout);
        };
    }, [apiResult.status]);

    return (
        <div>
            <FormProvider {...methods}>
                <form data-testid='initiative-form'>
                    <div className="rf-nav-btns">
                        <button data-testid={'initiative-form-save-btn'} className="btn btn-green" onClick={methods.handleSubmit(onSubmit)} disabled={(edited.initiative || edited.project || edited.demand) ? false :true}>{saving ? <LoadingAnimation type={'dot'}/> : 'Save'}</button>
                        {apiResult.status ? <span style={apiResult.type === 'success' ? {color:'green'} : {color: 'red'}}>{apiResult.message}</span> : <></>}
                    </div>
                    <InitiativeFormHeader initiative={initiative}  biRef={biRef}  edited={edited.initiative} setEdited={setEdited} config={config} />
                    {initiativeId !== 'new' ? <InitiativeFormProject biRef={biRef} edited={edited.project} setEdited={setEdited} initiativeId={initiativeId}/> : null}
                </form>
            </FormProvider>
            {initiativeId !== 'new' ? <InitiativeDemand demandRef={demandRef} edited={edited.demand} setEdited={setEdited}/> : null}
        </div>
    );
};

InitiativeFormProvider.propTypes = {
    initiative: PropTypes.object,  
    config:PropTypes.object,
    initiativeId: PropTypes.string,
};
export default InitiativeFormProvider;