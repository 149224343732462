import React, {useCallback, useState} from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest } from '../../../services/axiosClient';
import PropTypes from 'prop-types';
import ErrorStatus from '../ErrorStatus';
import { debounce } from '../../data';



const CustomAsyncSelectForHookForm = props => {
    const field = props.field.field;
    const valueD = props.field.field.value;
    //component state
    const [error, setError] = useState({status:false, message:''});
    /* 
        getting requested data from url
        we will also use input text as query
    */
    const getOptions = async (input, callback) => {
        try {
            const json = await getRequest(props.urlReq + input);
            let result;
            if(props.urlReq.includes('employee_status')){
                result = json.data.data.filter(record => record.employee_status === 'ACTIVE');
            } else {
                result = json.data.data;
            }
            callback(result);
            
        } catch (err) {
            const msg = err?.request.statusText ? ` - ${err.request.statusText}!` : '!';
            setError({status:true, message:'Error!, please refresh page or try again later' + msg});
        }
        
        
    };

    /* 
        A debounce function to prevent multiple calls to the API
        when fast typing. 
    */
    const timeToDelay = 500;
    const getOptionData = useCallback(
        debounce(getOptions, timeToDelay),
        []
    );

    //getting option label
    const setLabel = option=>{
        const selectLabel = option[props.defaultKeyNames.label] ? option[props.defaultKeyNames.label] : '';
        const selectValue = option[props.defaultKeyNames.value] ? option[props.defaultKeyNames.value] : '';
        return  props.hideId ? `${option[props.defaultKeyNames.label]}` :  `${selectValue} - ${selectLabel}`;
    };

    const handleChange = val=> {
        // when changing value assign the value
        // only if value is valid
        if(val){
            field.onChange(val[props.defaultKeyNames.value]);
        }
        // this will be triggered when area is cleared
        else {
            field.onChange(null);
        }
        
    };
    
    return (<>
        <ErrorStatus error={error} setError={setError} />
        {!props.isMulti ? <AsyncSelect
            onChange={value => handleChange(value)}
            loadOptions={getOptionData}      
            cacheOptions
            getOptionLabel={option =>  setLabel(option)}
            getOptionValue={option => option[props.defaultKeyNames.value]}
            ref={field?.ref}
            name={props?.name}
            value={props.defValue?.find(c => c[props.defaultKeyNames.value] === valueD)}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            isClearable={props.isClearable || false}
            
        /> : <AsyncSelect
            loadOptions={getOptionData}      
            onChange={value=> field.onChange([...value])}
            getOptionLabel={option =>  setLabel(option)}
            getOptionValue={option => option[props.defaultKeyNames.value]}
            ref={field?.ref}
            name={props.name}
            value={valueD}
            
            isMulti
            backspaceRemovesValue={false}
            menuPlacement="auto"
            menuPortalTarget={document.body}
        /> }</>
    );
};

CustomAsyncSelectForHookForm.propTypes = {
    urlReq: PropTypes.string,
    defValue: PropTypes.any,
    defaultKeyNames: PropTypes.object,
    field:PropTypes.any,
    name:PropTypes.string,
    isMulti: PropTypes.bool,
    hideId: PropTypes.bool,
    isClearable: PropTypes.bool,
};

export default CustomAsyncSelectForHookForm;