import { createSlice } from '@reduxjs/toolkit';
// create a slice of the store's state called 'rmrState' with an initial state object
export const rmrStateSlice = createSlice({
    name: 'rmrState',
    initialState: {
        activeTab: 'home',
        isUnauthorized:false,
    },
    // define the different reducers for the slice
    reducers: {
        // setActiveTab is used to set the active tab in the rmrState slice
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        // setUnauthorized is used to set the unauthorized state in the rmrState slice
        setUnauthorized: (state, action) => {
            state.isUnauthorized = action.payload;
        },
    },
});

// export the actions that can be dispatched
export const { setActiveTab, setUnauthorized } = rmrStateSlice.actions;

// export the selectors
export const selectTab = state => state.rmrState.activeTab;
export const isUnauthorized= state => state.rmrState.isUnauthorized;

// export the reducer
export default rmrStateSlice.reducer;
