import React from 'react';
import PropTypes from 'prop-types';
const hundred = 100;
const checkKey = (key, value) =>key.includes('pct') ? Math.round(value * hundred) + '%' : value;

const ResourceHeader = React.forwardRef((props, ref) => {
    const { resource, setEdited, edited } = props;

    /**
     * If the value of the textarea is equal to the 
     * value of the notes_actions 
     * property of the resource
     * object, then set the note property of the 
     * edited object to false. 
     * Otherwise, check if edited is not true,
     * then set it true
     */
    const handleChange = e => {
        if(e.target.value === resource.notes_actions){
            setEdited( prevState => ({...prevState, note: false}));
        }  else {
            if(!edited) setEdited( prevState => ({...prevState, note: true}));
        }
    };

    const resourceProperties = [
        {header: 'Resource Name', key: 'resource_name'},
        {header: 'Resource WWID', key: 'resource_wwid'},
        {header: 'Position ID', key: 'position_id'},
        {header: 'Manager', key: 'manager'}, 
    ];
    const resourcePropertiesRight = [
        {header: 'Disposition', key: 'disposition'},
        {header: 'Type', key: 'type'},
        {header: 'C Area/P Group', key: 'chapterarea_platformgroup'},
        {header: 'Chap/Platform', key: 'chapter_platform'},
        {header: 'Sub Chapter', key: 'subchapter'},
        {header: 'App Dev Target', key: 'app_dev_recov_tgt_pct'},
        {header: 'AM Target', key: 'am_recov_tgt_pct'},
        
    ];
    
    return (
        <>
            <h2 className='rf-header-title'>Update Individual Resource</h2>
            <div className='rf-header-container'>
                <div>
                    {resourceProperties.map(({ header, key }) => (
                        <div key={key}  className='rf-header-row'>
                            <span className='rf-header-label'>{header}:</span>
                            <span>{checkKey(key, resource[key])}</span>
                        </div>
                    ))}
                
                    <div className='rf-header-textarea'>
                        <span className='rf-header-text-label'>Notes:</span>
                        <textarea 
                            className="rf-note-textarea" 
                            ref={ref} 
                            defaultValue={resource.notes_actions} 
                            onChange={handleChange} 
                            data-testid="note-textarea" 
                        />
                    </div>
                </div>
                <div>
                    {resourcePropertiesRight.map(({ header, key }) => (
                        <div key={key}  className='rf-header-row'>
                            <span className='rf-header-label'>{header}:</span>
                            <span>{checkKey(key, resource[key])}</span>
                        </div>
                    ))}
                </div>
                
                
            </div>
        </>
        
    );
});



ResourceHeader.displayName = 'ResourceHeader';
ResourceHeader.propTypes = {
    resource: PropTypes.object,
    setEdited: PropTypes.func,
    edited:PropTypes.bool
    
};

export default ResourceHeader;