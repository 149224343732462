import { createSlice } from '@reduxjs/toolkit';

// create a slice of the store's state called 'initiative' with an initial state object
export const initiativeSlice=createSlice({
    name: 'initiative',
    initialState:{
        
        // the initial state of the initiativeTable object
        initiativeTable: {fetched: false, data:[]},

    },
    // define the different reducers for the slice
    reducers: {
        // setInitiativeTable is used to set the initiativeTable data in the initiative slice
        setInitiativeTable: (state, action) => {
            state.initiativeTable = action.payload;
        }
    },
});

// export the actions that can be dispatched
export const { setInitiativeTable } = initiativeSlice.actions;

// export the selectors
export const initTable = state => state.initiative.initiativeTable;

// export the reducer
export default initiativeSlice.reducer;
