import React, { useState} from 'react';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const InitiativeFormDemandHeader = () => {
    // component state
    // it will hold true/false 
    const [showLess, setShowLess]  = useState(true);

    /**
    * hide/show demand 
    * information
    */
    const handleShowLess = e => {
        e.preventDefault();
        setShowLess(prevState => !prevState);
    };
   
    return (
        <div className='demand-header'>
            <h2>Initiative Demand</h2>
            <br />
            <div >
                <p>
                    Create new demand for this Initiative below by clicking the <strong>Add New Initiative Demand</strong>  button. Entering a Role is <strong>required</strong>, Skills and Technologies are optional, but encouraged if known. 
                </p> 
                <span data-testid='init-demand-text' className="demand-more-info-text" style={showLess ? {height:0} : {height:'auto'}}>
                    <p>Enter in the <strong>hours</strong> for each month that the Demand Role will be allocated to.  
                        The value of hours should range from <strong>0 to 139</strong>, which is the maximum allocation for a resource in a month.  </p>
                    <p>Click the <AddIcon fontSize='12px'/> and <RemoveIcon fontSize='12px'/> icons to show/hide a Resource to the demand and Comments.  
                        The <DeleteForeverIcon fontSize='12px' /> icon will delete the demand record and the <ContentCopyRoundedIcon fontSize='12px'/> 
                         copy icon will create a copy of that demand record. </p><p>If the Expected Start or End Dates are changed, the Initiative Demand 
                            table below will update accordingly. </p><p>Note that if the timeframe is reduced, <strong>any months not in the 
                                new timeframe will be deleted</strong>.</p>
                </span>   
                <span onClick={handleShowLess} className='demand-show-less' data-testid='initiative-form-demand-showless'>
                    {showLess ? 'More Info' : 'Show Less'}
                </span>
            </div>
        </div>
    );
};

export default InitiativeFormDemandHeader;