import { postRequest } from '../services/axiosClient';
import { getBaseTspmCoreURL } from './getBaseUrls';

// logPageView is used to track the url pathing of users
const logPageView =()=>{
    
    // get the base URL for the TSPM core API
    const baseUrl = getBaseTspmCoreURL();
    
    // create a new date object for the timestamp
    let date = new Date();
    // used for slicing date string
    const sliceNumber = -2;
    // format the date string
    const dateStr =
            date.getFullYear() + '-' +
            ('00' + (date.getMonth() + 1)).slice(sliceNumber) + '-' +
            ('00' + date.getDate()).slice(sliceNumber) + ' ' +
            ('00' + date.getHours()).slice(sliceNumber) + ':' +
            ('00' + date.getMinutes()).slice(sliceNumber) + ':' +
            ('00' + date.getSeconds()).slice(sliceNumber) + '.' +
            date.getMilliseconds();

    // get the user name from session storage
    let data = sessionStorage.getItem('user');

    // create the log data object
    const logData = {
        user_name: JSON.parse(data).userName,
        pathname: location.pathname,
        hostname: location.hostname,
        visit_timestamp: dateStr
    };
    
    // only send the data if the user data is loaded
    const sendData= async()=>{
        try {
            await postRequest(baseUrl + '/api/datasrcs/1/schemas/tspmcfg/tables/page_view_log/records',logData);
        } catch (error) {
            console.log(error);
        }
    };
    
    sendData();
};

export default logPageView;