import { createSlice } from '@reduxjs/toolkit';
import { dateRange } from '../../components/data';

export const initiativeFormSlice=createSlice({
    // state name
    name: 'initiativeForm',
    // states
    initialState:{
        initFormMonth: {start_date:'', end_date:''},
        initFormMonthArr: [],
        initFormSaveStatus:{status:false, type: null, message:''},
        initDemandIsValid:true,
        initDemandConfig:{fetched:false, data:{}},
        initiativeConfig:{fetched:false, data:{}}
    },
    // defining reducer functions 
    // to set state data
    reducers: {
        setInitFormMonth: (state, action) => {
            const payload = action.payload;
            //setting month array
            state.initFormMonth = {...state.initFormMonth, [payload.key]:payload.value};
            const dates = dateRange(state.initFormMonth.start_date, state.initFormMonth.end_date);
            state.initFormMonthArr =  dates;
        },
        setInitFormMonthDefault: (state, action) => {
            state.initFormMonth = action.payload;
            const dates = dateRange(action.payload.start_date, action.payload.end_date);
            state.initFormMonthArr =  dates;
        },
        setInitFormSaveStatus : (state, action) => {
            state.initFormSaveStatus= action.payload;
        },
        setInitDemandIsValid: (state, action) => {  
            state.initDemandIsValid= action.payload;
        },
        setInitDemandConfig: (state, action) => {  
            state.initDemandConfig= action.payload;
        },
        setInitiativeConfig: (state, action) => {  
            state.initiativeConfig= action.payload;
        },
    },
});

// export the actions that can be dispatched
export const { setInitFormMonth, setInitFormDemand,setInitFormMonthDefault,setInitFormSaveStatus,setInitDemandIsValid, setInitDemandConfig,setInitiativeConfig } = initiativeFormSlice.actions;
// export the selectors
export const initFormMonth= state => state.initiativeForm.initFormMonth;
export const initFormMonthArr= state => state.initiativeForm.initFormMonthArr;
export const initFormSaveStatus= state => state.initiativeForm.initFormSaveStatus;
export const initDemandIsValid= state => state.initiativeForm.initDemandIsValid;
export const initDemandConfig= state => state.initiativeForm.initDemandConfig;
export const initiativeConfig= state => state.initiativeForm.initiativeConfig;
// export the reducer
export default initiativeFormSlice.reducer;