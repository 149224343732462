import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';


const InitiativeFormDemandMonthly = props => {
    const {control, register, monthArr, nestIndex} = props;

    const { fields, append, remove, prepend} = useFieldArray({
        control,
        name: `demand[${nestIndex}].allocat`, 
    });

    
    /*
    with each month range change 
    we add or remove 
    fields to allocation 
    part of form
    */
    useEffect(() => {
        //remove
        const removedValues = [];
        fields.forEach((item,i)=>{
            let status = true;
            monthArr.forEach(month => {
                if(month.ymd === item.ymd){
                    status = false;
                }
            });
            if(status) {

                removedValues.push(i);
                //remove(i);
            }
     
        });
        if(removedValues.length > 0){
            remove(removedValues);
        }
        /// append
        const obj ={};
        fields.forEach(item=>{
            obj[item.ymd] =1;
        });

        // array to hold items that needed to be added in the front of array
        
        const addBefore = [];
        
        // looping through month array and checking where to add allocation data
        for (let value of monthArr) {
            if(!Object.prototype.hasOwnProperty.call(obj, value.ymd)) {
                const d= {
                    'initiative_demand_id': null,
                    'hrs': 0,
                    'mo': value.month,
                    'yr': value.year,
                    'ymd': `${value.year}-${value.month}-01`
                };
                // here we are checking if month date is bigger(later date) than original fields first month 
                // then we add it to end of field array
                // else we will add it to addBefore array which it will be added in front of field array
                if(new Date(value.ymd) > new Date(fields[0]?.ymd)){
                    append(d);
                } else {
                    addBefore.push(d);
                }
            }
        }   
         
        
        /* check if addBefore array have value
            and add it in front of fields array
         */
        if(addBefore.length > 0){
            prepend(addBefore);
        }
    },[monthArr]);

    return (
        <>
            {fields.map((item,i) => (
                <td key={item.id}>
                    <input
                        type='number'
                        name={`demand[${nestIndex}].allocat[${i}].hrs`}
                        {...register(`demand[${nestIndex}].allocat[${i}].hrs`,{required:false, min:0, max:139})} 
                        defaultValue={item.hrs}
                        className='demand-hours'
                    />
                </td>
            ))}
        </>
    );
};


InitiativeFormDemandMonthly.propTypes = {
    allocation: PropTypes.array,
    control: PropTypes.object,
    register: PropTypes.func,
    monthArr: PropTypes.array,
    nestIndex: PropTypes.number,
};
export default InitiativeFormDemandMonthly;