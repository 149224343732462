import React,{useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, Controller } from 'react-hook-form';
import CustomSelectForHookForm from '../../components/common/customSelect/CustomSelectForHookForm';
import { positionOptions} from '../../redux/features/positionFormSlice';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import CustomDatePicker from '../../components/common/datePicker/CustomDatePicker';

const PositionFormDisposition = props => {
    const {control, defaultData, errors, positionId, getValues} = props;

    //setting default indexed loading, 
    //it will help reload select based on 
    //filtered options after first displayed
    const loadingDefault = defaultData.map(index => ({
        [index]:true
    }));
    // redux select options
    const options = useSelector(positionOptions);
    const [loading, setLoading] = useState(loadingDefault);
    const [dispositionOption, setDispositionOption] = useState([]);
  

    // react hook form
    const { fields, append, remove} = useFieldArray({
        control,
        name:'rmr_position_disposition_function',
    });
    
    // default data
    const defaultDis= {position_id: positionId, legacy_id: 1, disposition_id: '', type_id: '', 
        app_dev_recov_tgt_pct: 0, am_recov_tgt_pct: 0, start_date: '', end_date: null};
   


    /**
     * Check if the value that is 
     * greater than 100 or less than 0, 
     * alert if it is out of range.
     */
    const handleRecovChange = (value, field, type, index) => {
        // Store the value of 100 as a constant for easier use
        const percent = 100;
      
        // Get the value of the other field to check the total of both fields
        // `getValues` is not imported or not in props, so you'll need to pass it as an argument or import it
        const otherValue = getValues(`rmr_position_disposition_function.${index}.${type}`);
      
        // Calculate the total of both fields by multiplying `otherValue` by 100 and adding it to `value`
        const total = parseFloat(otherValue) * percent + parseInt(value);
      
        // Check if the value entered is greater than 100 or less than 0
        if (value > percent || value < 0) {
            // If the value is outside the range, show an error message
            alert('Capacity percentage must be between 0-100');
        } else {
            // If the value is within the range, check if the total of both fields is greater than 100
            if (total > percent) {
            // If the total is greater than 100, show an error message
                alert('The sum of the App Dev and AM Recovery Targets exceeds 100%. The sum must be 100% or less.');
            } else {
            // If the total is 100 or less, update the field with the new value
                const fixed = 2;
                const newValue = (value / percent).toFixed(fixed);
                field.onChange(newValue);
            }
        }
    };
   

    const handleAdd = e=>{
        e.preventDefault();
        append(defaultDis);

        // handling filter of dependent field
    
        const disOption = options.rmr_disposition.filter(item => item.legacy_id === defaultDis.legacy_id);
        setDispositionOption([...dispositionOption, disOption]);
      

    };
    
    const handleRemove = e=>{
        e.preventDefault();
        remove(fields.length-1);

        // handling filter of dependent field
       
        const opt1 = [...dispositionOption];
     
        opt1.pop();
        setDispositionOption(opt1);
       
    };

    // handling filter of dependent field, we are setting options of function and disposition based on legacy id
    const handleChange = async (value, field, index) => {
        // Show loading indicator by updating the state
        setLoading(prevState => {
            const newLoading = { ...prevState };
            newLoading[index] = true;
            return newLoading;
        });
      
        // Filter the rmr_disposition options and update the dispositionOption state
        const option1 = [...dispositionOption];
        option1[index] = options.rmr_disposition.filter(item => item.legacy_id === value.id);
        setDispositionOption(option1);
      
        // Call the onChange method of the field object and pass the selected value.id as an integer
        field.onChange(parseInt(value.id));
      
        // Hide loading indicator after one second
        const timeoutMs = 1000;
        setTimeout(() => {
            setLoading(prevState => {
                const newLoading = { ...prevState };
                newLoading[index] = false;
                return newLoading;
            });
        }, timeoutMs);
    };

    // handling filter of dependent field, 
    // we are setting options of function and 
    // disposition based on legacy id
    useEffect(() => {
        const obj = {};
      
        const dispositionArr = [];
        fields.forEach((field,index)=>{
            obj[index] = false;
           
            dispositionArr.push(options.rmr_disposition.filter(item => item.legacy_id === field.legacy_id));
        });
        setDispositionOption(dispositionArr);
      
        setLoading(obj);
    }, []);

    // round to hundred
    const hundred = 100;
    return (
        <section className='position-form-section' style={{width:'100%'}}>
           
            <table className='position-table-field'>
                <thead> 
                    <tr> 
                        <th className="">Legacy</th>
                        <th>Disposition</th>
                        <th>Type</th>
                        <th>C Area/P Group</th>
                        <th>Chap/Platform</th>
                        <th>Sub Chapter</th>
                        <th>App Dev Target</th>
                        <th>AM Target</th>
                        <th style={{width: '200px'}}>Effective Date</th>
                    </tr>
                </thead>
                <tbody data-testid='rmr_position_disposition_function_table'>
                    {
                        fields.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    <Controller
                                        name={`rmr_position_disposition_function.${index}.legacy_id`}
                                        control={control}
                                        defaultValue={item.legacy_id}
                                        rules={{ required:true}}
                                        render={({field}) => (
                                            
                                            <Select        
                                                options={options.rmr_legacy}
                                                onChange={value => handleChange(value, field,index)}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.id}
                                                ref={field?.ref}
                                                name={`rmr_position_disposition_function.${index}.legacy_id`} 
                                                value={options.rmr_legacy.find(c => c.id === field.value)}
                                                menuPortalTarget={document.body}
                                                menuPlacement="auto"
                                                onBlur={field.onBlur}
                                                inputuRef={field.ref}
                                            />
                                        )}/>
                                    {errors?.rmr_position_disposition_function?.[index]?.legacy_id && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                </td>
                                <td>
                                    {!loading[index] ?<Controller
                                        name={`rmr_position_disposition_function.${index}.disposition_id`}
                                        control={control}
                                        defaultValue={item.disposition_id}
                                        rules={{ required:true}}
                                        render={field => (
                                            <CustomSelectForHookForm
                                            
                                                options={dispositionOption[index]}
                                                field={field}
                                                defValue={item.disposition_id}
                                                name={`rmr_position_disposition_function.${index}.disposition_id`} 
                                                defaultKeyNames={{label:'name', value:'id'}}
                                                hideId={true}
                                            />                                         
                                        )}/> : <div className='select-placeholder' style={{width: 'inherit', height: '38px'}}> <LoadingAnimation type={'dot'}/></div>}   
                                       
                                    {errors?.rmr_position_disposition_function?.[index]?.disposition_id && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                       
                                </td>
                                <td>
                                    {!loading[index] ? <Controller
                                        name={`rmr_position_disposition_function.${index}.type_id`}
                                        control={control}
                                        defaultValue={item.type_id}
                                        rules={{ required:true}}
                                        render={field => (
                                            <CustomSelectForHookForm
                                               
                                                options={options.rmr_type}
                                                field={field}
                                                defValue={item.type_id}
                                                name={`rmr_position_disposition_function.${index}.type_id`} 
                                                defaultKeyNames={{label:'name', value:'id'}}
                                                hideId={true}
                                            />                                             
                                        )}/> : <div className='select-placeholder' style={{width: 'inherit', height: '38px'}}> <LoadingAnimation type={'dot'}/></div>}   
                                    {errors?.rmr_position_disposition_function?.[index]?.type_id && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                       
                                </td>
                                <td>
                                    {!loading[index] ? <Controller
                                        name={`rmr_position_disposition_function.${index}.chapterarea_platformgroup_id`}
                                        control={control}
                                        defaultValue={item.chapterarea_platformgroup_id}
                                        rules={{ required:true}}
                                        render={field => (
                                            <CustomSelectForHookForm
                                               
                                                options={options.rmr_chapterarea_platformgroup}
                                                field={field}
                                                defValue={item.chapterarea_platformgroup_id}
                                                name={`rmr_position_disposition_function.${index}.chapterarea_platformgroup_id`} 
                                                defaultKeyNames={{label:'name', value:'id'}}
                                                hideId={true}
                                            />                                             
                                        )}/> : <div className='select-placeholder' style={{width: 'inherit', height: '38px'}}> <LoadingAnimation type={'dot'}/></div>}   
                                    {errors?.rmr_position_disposition_function?.[index]?.chapterarea_platformgroup_id && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                       
                                </td>
                                <td>
                                    {!loading[index] ? <Controller
                                        name={`rmr_position_disposition_function.${index}.chapter_platform_id`}
                                        control={control}
                                        defaultValue={item.chapter_platform_id}
                                        rules={{ required:false}}
                                        render={field => (
                                            <CustomSelectForHookForm
                                               
                                                options={options.rmr_chapter_platform}
                                                field={field}
                                                defValue={item.chapter_platform_id}
                                                name={`rmr_position_disposition_function.${index}.chapter_platform_id`} 
                                                defaultKeyNames={{label:'name', value:'id'}}
                                                hideId={true}
                                            />                                             
                                        )}/> : <div className='select-placeholder' style={{width: 'inherit', height: '38px'}}> <LoadingAnimation type={'dot'}/></div>}   
                                    {errors?.rmr_position_disposition_function?.[index]?.chapter_platform_id && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                       
                                </td><td>
                                    {!loading[index] ? <Controller
                                        name={`rmr_position_disposition_function.${index}.subchapter_id`}
                                        control={control}
                                        defaultValue={item.subchapter_id}
                                        rules={{ required:false}}
                                        render={field => (
                                            <CustomSelectForHookForm
                                                options={options.rmr_subchapter}
                                                field={field}
                                                defValue={item.subchapter_id}
                                                name={`rmr_position_disposition_function.${index}.subchapter_id`} 
                                                defaultKeyNames={{label:'name', value:'id'}}
                                                hideId={true}
                                            />                                             
                                        )}/> : <div className='select-placeholder' style={{width: 'inherit', height: '38px'}}> <LoadingAnimation type={'dot'}/></div>}   
                                    {errors?.rmr_position_disposition_function?.[index]?.subchapter_id && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                       
                                </td>
                                <td>
                                    <Controller
                                        name={`rmr_position_disposition_function.${index}.app_dev_recov_tgt_pct`}
                                        control={control}
                                        defaultValue={item.app_dev_recov_tgt_pct}
                                        rules={{ required:true}}
                                        render={({field}) => (
                                            <input
                                                type='number'
                                                {...field} 
                                                name={`rmr_position_disposition_function.${index}.app_dev_recov_tgt_pct`}
                                                onChange={e => handleRecovChange(e.target.value, field,'am_recov_tgt_pct',index)}
                                                data-testid='app-dev-recov'
                                                className='demand-hours'
                                                value={Math.round(field.value * hundred)}
                                            />)}/>
                                </td>
                                <td><Controller
                                    name={`rmr_position_disposition_function.${index}.am_recov_tgt_pct`}
                                    control={control}
                                    defaultValue={item.am_recov_tgt_pct}
                                    rules={{ required:true}}
                                    render={({field}) => (
                                        <input
                                            type='number'
                                            {...field} 
                                            name={`rmr_position_disposition_function.${index}.am_recov_tgt_pct`}
                                            onChange={e => handleRecovChange(e.target.value, field,'app_dev_recov_tgt_pct',index)}
                                        
                                            value={Math.round(field.value * hundred)}
                                            className='demand-hours'
                                            data-testid='am_recov_tgt_pct'
                                        />)}/>
                                </td>
                                <td>
                                    
                                    <Controller
                                        name={`rmr_position_disposition_function.${index}.start_date`}
                                        control={control}
                                        defaultValue={item.start_date}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <CustomDatePicker
                                                isDisabled={positionId !== null ? false : true}
                                                selectedDate={field.value}
                                                getValues={getValues}
                                                data={{field, tableName:'rmr_position_disposition_function', index }}
                                                onChange={false}
                                            />
                                        )}/>
                                    {errors?.rmr_position_disposition_function?.[index]?.start_date && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                </td>
                            </tr>
                        
                        ))
                    }
                 
                </tbody>
            </table>
            {positionId && <div className='posForm-btn-group'>
                <button className='btn btn-green' 
                    onClick={handleAdd}
                >Add New Disposition Record</button>
                {fields.length > defaultData.length  ?  <button className='btn btn-red' 
                    onClick={handleRemove}
                >Remove Last Record</button> :<></>
                }
            </div>}

        </section>
    );
};

PositionFormDisposition.propTypes = {
    control: PropTypes.object,
    defaultData: PropTypes.array,
    errors: PropTypes.object,
    defObj: PropTypes.object,
    positionId: PropTypes.any,
    getValues:PropTypes.func,
};

export default PositionFormDisposition;