import { createSlice } from '@reduxjs/toolkit';

// create a slice of the store's state called 'homeData' with an initial state object
export const homeSlice = createSlice({
    name: 'homeData',
    initialState: {
        delegatesToUser:{
            rowCount:0, 
            data:[],
            fetched:false
        },
        supDelegatesToUser:{rowCount:0, data:[], fetched:false},
        haveResourcePage: {
            fetched:false,
            resourcePage:false
        },
        haveDashboard: {
            fetched:false,
            dashboard:false
        }
    },
    // define the different reducers for the slice
    reducers: {
        // setDelegatesToUser is used to set the delegatesToUser data in the homeData slice
        setDelegatesToUser: (state, action) => {
            state.delegatesToUser = action.payload;
        },
        // setSupDelegatesToUser is used to set the supDelegatesToUser data in the homeData slice
        setSupDelegatesToUser:(state, action) => {
            state.supDelegatesToUser= action.payload;
        },
        // setHaveResourcePage is used to set the haveResourcePage data in the homeData slice
        setHaveResourcePage:(state, action) => {
            state.haveResourcePage = action.payload;
        },
        // setHaveDashboard is used to set the haveDashboard data in the homeData slice
        setHaveDashboard:(state, action) => {
            state.haveDashboard = action.payload;
        }

    },
});

// export the actions that can be dispatched
export const { setDelegatesToUser, setSupDelegatesToUser , setHaveResourcePage, setHaveDashboard} =homeSlice.actions;
// export the selectors
export const deleToUser = state => state.homeData.delegatesToUser;
export const supDeleToUser = state => state.homeData.supDelegatesToUser;
export const haveResourcePage = state => state.homeData.haveResourcePage;
export const haveDashboard = state => state.homeData.haveDashboard;
// export the reducer
export default homeSlice.reducer;