import React,{useEffect, useState} from 'react';
import { getRequest } from '../../services/axiosClient';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import {DataTable} from '@jauj/reactpkg-tsis-datatable';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import AddIcon from '@mui/icons-material/Add';
import { NavLink } from 'react-router-dom';
import ErrorStatus from '../../components/common/ErrorStatus';
import logPageView from '../../utils/logPageView';

const columns = [
    {
        accessorFn : row => row.position_id,
        header: 'Position Id',
        id: 'position_id',
        minWidth: 50,
        cell:info => (
            <NavLink to={`/positionForm/${info.getValue()}`} className={'initiative-link'}>{info.getValue()}</NavLink>
        ),
    },{
        accessorKey:'position', 
        header: 'Position',
        minWidth: 130,
        cell:info => (
            <NavLink to={`/positionForm/${info.row.original.position_id}`} className={'initiative-link'} style={{fontWeight:'400'}}>{info.getValue()}</NavLink>
        ),
    },{
        accessorKey:'resource_wwid', 
        header: 'Position WWID',
        minWidth: 120,
    },{
        accessorKey:'resource_name', 
        header: 'Resource Name',
        minWidth: 100,
    },{
        accessorKey:'position_status', 
        header: 'Position Status',
        minWidth: 100,
    },{
        accessorKey:'legacy', 
        header: 'Legacy',
        minWidth: 80,
    },{
        accessorKey:'disposition', 
        header: 'Disposition',
        minWidth: 80,
    },{
        accessorKey:'app_dev_recov_tgt_pct', 
        header:'App Dev Target %',
        minWidth: 100,
        dataType:'numeric',
        filterFn:'numericFilter'
    },{
        accessorKey:'am_recov_tgt_pct', 
        header:'AM Target %',
        minWidth: 100,
        dataType:'numeric',
        filterFn:'numericFilter'
    }
];

const Admin = () => {
    const baseUrl = getBaseTspmCoreURL();
    const [position, setPosition] = useState({fetched:false});
    const [error, setError] = useState({status:false, message:''});
    const manageRmrDataLink = `${baseUrl}/data_maintenance/51`;

    /**
     getting admin page table from custom query
     */
    const getAdminPageTable = async()=>{
        try {
            const res = await getRequest(baseUrl + '/api/datasrcs/1/folder/rmr/customQuery/admin_page_table'); 
            setPosition({fetched: true, data: res.data.data, colNames: res.data.columns});
            sessionStorage.setItem('position', JSON.stringify({fetched: true, data: res.data.data, colNames: res.data.columns}));
        } catch (err) {
            const msg = err?.request.statusText ? ` - ${err.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting position table, please refresh page or try again later' + msg});
            
        }
    };

    useEffect(() => {
        // Get saved data from sessionStorage
        let data = sessionStorage.getItem('position');
        /*
        parsing data
        */ 
        data =  JSON.parse(data);

        /*
         set state if data and data fetched
        */
        if (data && data.fetched) {
            
            setPosition(data);
        } else {
            /*
            if data is not fetched or not found
            in session storage
            call getAdminPageTable function
            */
            getAdminPageTable();
        }
        logPageView();
    }, []);
    
  
    return <div className='initiative-main'>
        <div className='initiative-header'>
            <h1>Manage Positions</h1>
            <NavLink
                to={'/positionForm/new'}
                className="btn btn-green"
            >
                <AddIcon/>  Create New Position
            </NavLink>
        </div>
        <ErrorStatus error={error} setError={setError} />
        {position.fetched ? 
            <DataTable
                {...{
                    tableData:position.data,
                    columnConfig:columns , 
                    disableDownload:false,
                    defaultFileName:'Positions', 
                    showFooter:false,
                }}
            />: <LoadingAnimation type={'dot'} />}
        <div>
            <a href={manageRmrDataLink} target={'_blank'} rel="noreferrer" className='link-regular' >Manage RMR Data</a>
            
        </div>
    </div>;
};

export default Admin;