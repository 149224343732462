import React from 'react';
import './main.css';
import { Outlet } from 'react-router-dom';
import Nav from '../../components/nav/Nav';
import AuthReload from '../../components/common/AuthReload';

const Main = () => {
    // this component wraps all of application
    // routes will be rendered in outlet
    return (
        <div>
            <Nav />
            <AuthReload/>
            <div className="rmr-page-content" data-testid='main-page'>
                <Outlet />
            </div>
        </div>
    );
};

export default Main;
