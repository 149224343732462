import React, {useEffect, useState} from 'react';
import { deleteRequest, getRequest, postRequest } from '../../services/axiosClient';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import PropTypes from 'prop-types';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomPopup from '../../components/common/customPopup/CustomPopup';
import CustomAsyncSelect from '../../components/common/customSelect/CustomAsyncSelect';
import { Link } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { deleToUser, supDeleToUser,setDelegatesToUser, setSupDelegatesToUser } from '../../redux/features/homeSlice';
import ErrorStatus from '../../components/common/ErrorStatus';
import { backendUrls } from '../../utils/constants';


const urlWwid = backendUrls.rmr_resource;
const HomeDelegates = props => {
    const {userWwid} = props;
    const baseUrl = getBaseTspmCoreURL();
    const dispatch = useDispatch();
    const supDelegatesToUser = useSelector(supDeleToUser);
    const delegatesToUser = useSelector(deleToUser);

    //Component state//
    const [openPopup, setOpenPopup] = useState({popupStatus:false, record:{}});
    const [selectedDelegate, setSelectedDelegate] = useState({status:false, record:null});
    const [error, setError] = useState({status:false, message:''});
    
    
 
    // function to get user delegates and save in redux store
    const getDelegatesToUser = async()=>{
        try {
            const res = await getRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_supervisor_delegate/records?display-value=true&supervisor_wwid=${userWwid}`);
            const resDelegate = await getRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_supervisor_delegate/records?display-value=true&delegate_wwid=${userWwid}`);
            dispatch(setDelegatesToUser({data:res.data.data, fetched:true, rowCount:res.data.rowCount}));
            dispatch(setSupDelegatesToUser({data:resDelegate.data.data, fetched:true, rowCount:resDelegate.data.rowCount}));
        } catch (err) {
            const msg = err?.request?.statusText ? ` - ${err?.request?.statusText}!` : '!';
            setError({status:true, message:'Unable to get delegate list' + msg});
        }
    };

    useEffect(() => {
        // get data if it is not fetched, this is to prevent to fetch data every time you visit home page
        if(!supDelegatesToUser.fetched && !delegatesToUser.fetched){
            getDelegatesToUser();
        }
    }, []);
    
    // handling delete delegate and setting popup close
    const handleDelete = async record=>{
        try {
            await deleteRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_supervisor_delegate/records/${record.id}`);
            getDelegatesToUser();
        } catch (err) {
            const msg = err?.request?.statusText ? ` - ${err?.request?.statusText}!` : '!';
            setError({status:true, message:'Unable to delete delegate, please try later' + msg});
        }
        setOpenPopup({popupStatus:false, record:{}});
        
    };

    //close popup
    const handleClose = ()=>{
        setOpenPopup({popupStatus:false, record:{}});
    };

    const handleOptionChange = value=>{
        
        //if selected delegate is yourself give alert
        if(value.wwid === `${userWwid}`) {
            alert('You cannot be your delegate!');
            setSelectedDelegate({status:false, record:null});
        } else {
            // setting selected delegate
            setSelectedDelegate({status:true, record:value});
        }
        
    };

    /**
      Adding delegate
     */
    const handleAdd = async()=>{

        // delegate object
        const delegateObj = {
            'supervisor_wwid': userWwid,
            'delegate_wwid': selectedDelegate.record.wwid,
        };

        try {
            await postRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_supervisor_delegate/records/', delegateObj);
            // getting delegates
            getDelegatesToUser(); 
        } catch (err) {
            const msg = err?.request?.statusText ? err?.request?.statusText : '';
            setError({status:true, message:'Unable to add delegate, please try later - ' + msg});
            
        }
        // resetting selected delegate
        setSelectedDelegate({status:false, record:null});
    };

    /**
     * When the user clicks the cancel button, set the selectedDelegate state to false and record to null.
     */
    const handleCancel = ()=>{
        setSelectedDelegate({status:false, record:null});
    };

    return (
        <section className='rmr-home-delegate'>
            <div className='rmr-home-header'>
                Delegates
            </div>
            <p>Delegates are employees in the organization that you can assign to manage resources in your reporting line on your behalf.</p>
            <ErrorStatus error={error} setError={setError}/>
            {openPopup.popupStatus && 
                <CustomPopup title={'Are you sure you want to delete?'}>
                    
                    <div className='rmr-home-popup'>
                        <button data-testid='cancel-btn' className='btn btn-trans' onClick={handleClose} style={{color:'blue'}}>Cancel</button>
                        <button data-testid='delete-btn' className='btn btn-red' onClick={()=>handleDelete(openPopup.record)}>Delete</button>
                    </div>
                
                </CustomPopup>}
            {delegatesToUser.rowCount !== 0 ? 
                <div>
                    <div className='rmr-home-delegate-list'>
                        <p style={{fontWeight:500, marginBottom:'5px'}}>List of Delegates</p>
                        {delegatesToUser.data.map(item => (
                            <div key={item.id} className='rmr-home-delegate-list-item'>
                                <button className='btn btn-trans'
                                    onClick={()=>setOpenPopup({record:item, popupStatus:true})} 
                                    style={{paddingLeft:'5px', paddingRight:'5px'}}
                                    title="Delete Delegate"
                                >
                                    <DeleteForeverIcon fontSize='small' />
                                </button>
                                <span>{item.delegate_wwid_dv}</span>
                            </div>)
                        )}
                    </div>
                </div> : <p>You do not have any delegates.</p>
            }
            <div className='rmr-home-add-container'>
                <span className='rmr-home-add-title'>
                    Add Delegates
                </span>
                <div className='rmr-home-add-search'>
                    <CustomAsyncSelect 
                        urlReq={urlWwid} 
                        defaultKeyNames={{label:'display_name', value:'wwid'}} 
                        name='resource_wwid'  
                        isMulti={false}     
                        handleChange={handleOptionChange}
                        value={selectedDelegate.record}
                        placeholder='Search Delegate...'
                    />
                </div>
                
                <span style={{width:'100px'}} className='rmr-home-add-btns'>
                    <button className='btn btn-green rmr-home-add-btn'  onClick={handleAdd} style={{display:selectedDelegate.status ? 'block' : 'none'}}><CheckIcon/></button>
                    <button className='btn btn-red rmr-home-add-btn' onClick={handleCancel} style={{display:selectedDelegate.status ? 'block' : 'none'}}><ClearIcon/></button>
                </span>
                
            </div>
            <p>You can also be assigned as a delegate to someone else in the organization, which means that you can manage resources in their reporting line on their behalf.</p>
            {supDelegatesToUser.rowCount !== 0 ? 
                <div>
                    <div className='rmr-home-delegate-list'>
                        <p style={{fontWeight:500, marginBottom:'5px'}}>List of People that you are a Delegate for</p>
                        {supDelegatesToUser.data.map(item => (
                            <div key={item.id} className='rmr-home-delegate-list-item' style={{marginLeft: '5px'}}>
                                
                                <Link className='link-regular' to={`/updateMyResources/wwid/${item.supervisor_wwid}`}>{item.supervisor_wwid_dv}</Link>
                            </div>)
                        )}
                    </div>
                </div> : <p>You are not a delegate for any other person.</p>
            }
        </section>
    );
};
HomeDelegates.propTypes = {
    userWwid: PropTypes.string,
};

export default HomeDelegates;