import React, {useEffect, useState} from 'react';
import InitiativeFormDemand from './InitiativeFormDemand';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getRequest } from '../../services/axiosClient';
import { resourceFormTables, setResourceFormData} from '../../redux/features/resourceFormSlice';
import { setInitDemandConfig, initDemandConfig, initFormMonthArr} from '../../redux/features/initiativeFormSlice';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import InitiativeFormDemandHeader from './InitiativeFormDemandHeader';
import PropTypes from 'prop-types';
import ErrorStatus from '../../components/common/ErrorStatus';


const InitiativeDemand = props => {
    const params = useParams();
    const dispatch = useDispatch();
    const selectData = useSelector(resourceFormTables);
    const config = useSelector(initDemandConfig);
    const baseUrl = getBaseTspmCoreURL();

    /* COMPONENT STATE */
    const [demands, setDemands] = useState({fetched: false, data:[]});
    const [demandsMerge, setDemandsMerge] = useState({fetched:false,  data:[] });
    const [error, setError] = useState({status:false, message:''});
    
    const monthArr = useSelector(initFormMonthArr);
    const getSelectTables = async()=>{
        try {
            const rmr_skill = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_skill/records?active_flag=true'); 
            const rmr_role = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_role/records?active_flag=true'); 
            const rmr_tech = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_technology/records?active_flag=true'); 
            const arr = [
                {   id: 1,
                    name: 'roles',
                    list:rmr_role.data.data},
                {   id: 2,
                    name: 'skills',
                    list:rmr_skill.data.data},   
                {   id: 3,
                    name: 'technologies',
                    list:rmr_tech.data.data}
            ];
            dispatch(setResourceFormData({tableFetched: true, tableArray: arr}));
        } catch (err) {
            const msg = err?.request?.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting user skill data, please refresh page or try again later' + msg});
        }
    };

    const getAllocation = async ids=>{

        try {
            const res = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand_monthly_allocation/records?&initiative_demand_id=' + ids);
            
            const newDemand = [];
            const newDemands = demands.data.map(item=> (
                {...item, ids: item.id}
            ));
         
            newDemands.forEach(element => {
                const sortedAllocation = res.data.data.filter(item => item.initiative_demand_id === element.id)
                    .sort(function(a,b){
                        return new Date(a.ymd) - new Date(b.ymd);
                    });
                newDemand.push({...element, allocat:sortedAllocation});
            
            });
            setDemandsMerge({fetched:true, data:newDemand});
           
        } catch (err) {
            const msg = err?.request?.statusText ? ` - ${err.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting allocation data, please refresh page or try again later' + msg});
        }
    };

    const getDemands = async() => { 
        setDemands({fetched:false, data:[]});
        setDemandsMerge({fetched:false, data:[]});
        try {
            const res = await getRequest(baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand/records?display-value=true&initiative_id=${params.initiativeId}`); 
            setDemands({fetched:true, data: res.data.data});
        } catch(err) {
            const msg = err?.request?.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting demands data, please refresh page or try again later' + msg});
        }
    };

    /**
     getting config file of demand table 
     */
    const getConfigDemands = async() => { 
        try {
            const resConfig = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative_demand/config'); 
            dispatch(setInitDemandConfig({fetched:true, data: resConfig.data.columnConfig}));
        } catch(err) {
            const msg = err?.request?.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting demand table config data, please refresh page or try again later' + msg});
        }
    };

    useEffect(() => {
        if(!selectData.tableFetched){
            getSelectTables();
        }
        getDemands();

        if(!config.fetched){
            getConfigDemands();
        }
        
    }, []);

    useEffect(() => {
        if(demands.fetched){
            if(demands.data.length !== 0) {
                const ids = demands.data.map(item => item.id);
                getAllocation(ids.join(','));
            } else {
               
                setDemandsMerge({fetched:true, data:[]});
            }
        }
    }, [demands.fetched]);

    return (
        <div>
            <InitiativeFormDemandHeader/>
            <ErrorStatus error={error} setError={setError} />
            {demandsMerge.fetched & selectData.tableFetched & config.fetched ?  
                <InitiativeFormDemand 
                    monthArr={monthArr}
                    demands={demandsMerge.data} 
                    selectData={selectData}
                    initiativeId={params.initiativeId}
                    config={config.data}
                    demandRef={props.demandRef}
                    edited={props.edited} 
                    setEdited={props.setEdited}
                    getDemands={getDemands}
                /> : 
                <LoadingAnimation type={'dot'}/>}
        </div>
    );
};

InitiativeDemand.propTypes = {
    demandRef:PropTypes.object,
    edited: PropTypes.bool,
    setEdited: PropTypes.func,
};

export default InitiativeDemand;