import React from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import CustomAsyncSelectForHookForm from '../../components/common/customSelect/CustomAsyncSelectForHookForm';
import CustomSelectForHookForm from '../../components/common/customSelect/CustomSelectForHookForm';
import { positionOptions} from '../../redux/features/positionFormSlice';
import { useSelector } from 'react-redux';
import CustomDatePicker from '../../components/common/datePicker/CustomDatePicker';

const PositionFormSection = props => {
    const {control, data, date, defObj, defaultData, errors, isAsync, positionId, getValues} = props;
    const options = useSelector(positionOptions);
    
    const { fields, append, remove} = useFieldArray({
        control,
        name: data.fieldName,
    });
    /*
    comments
    for
    sonar
    to
    pass
    requirement
    .
    .
    .
    */

    /**
     adding new field to form
     */
    const handleAdd = e=>{
        e.preventDefault();
        append(defObj);
    };

    /**
     removing last field of form
     */
    const handleRemove = e=>{
        e.preventDefault();
        remove(fields.length-1);
    };
    return (
        <section className='position-form-section' style={{width:props.width}}>
            <table className='position-table-field'>
                <thead> 
                    <tr> 
                        <th className="">
                            {data.header}
                        </th>
                        <th style={{width: '200px'}}>
                            {date.header}
                        </th>
                    </tr>
                </thead>
                <tbody data-testid={data.fieldName + '_table'}>
                    {
                        fields.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    <Controller
                                        name={`${data.fieldName}.${index}.${data.name}`}
                                        control={control}
                                        defaultValue={item[data.name]===null ? '' : item[data.name]}
                                        rules={{ required:data.required}}
                                        render={field => (
                                            <>
                                                {isAsync ? 
                                                    <CustomAsyncSelectForHookForm  
                                                        urlReq={data.urlData} 
                                                        defValue={[{[data.label]:item[`${data.name}_dv`], [data.value]:item[data.name]===null ? '' : item[data.name]}]} 
                                                        defaultKeyNames={{label:data.label, value: data.value}} 
                                                        field={field} 
                                                        name={`${data.fieldName}.${index}.${data.name}`} 
                                                        hideId={false}/> 
                                                    :
                                                    <CustomSelectForHookForm
                                                        urlReq={null}
                                                        options={options[props.name]}
                                                        field={field}
                                                        defValue={item[data.name]}
                                                        name={`${data.fieldName}.${index}.${data.name}`} 
                                                        defaultKeyNames={{label:data.label, value: data.value}}
                                                        hideId={true}
                                                    /> 
                                                }
                                            </>
                                            
                                        )}/>
                                    {errors[data.fieldName]?.[index]?.[data.name] && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                       
                                </td>
                                <td>
                                    
                                    <Controller
                                        name={`${data.fieldName}.${index}.${date.name}`}
                                        control={control}
                                        defaultValue={item[date.name]}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <CustomDatePicker
                                                isDisabled={positionId !== null ? false : true}
                                                selectedDate={field.value}
                                                getValues={getValues}
                                                data={{field, tableName:data.fieldName, index }}
                                                onChange={false}
                                            />
                                        )}/>
                                    {errors[data.fieldName]?.[index]?.[date.name] && <span className='position-form-error-msg'>
                                        This field is required
                                    </span>}
                                </td>
                            </tr>
                        
                        ))
                    }
                 
                </tbody>
            </table>
            {positionId && <div className='posForm-btn-group'>
                <button className='btn btn-green' 
                    onClick={handleAdd}
                >{data.addBtnName}</button>
                {fields.length > defaultData.length  ?  <button className='btn btn-red' 
                    onClick={handleRemove}
                > Remove Last Record</button> :<></>
                }
            </div>}

        </section>
    );
};

PositionFormSection.propTypes = {
    control: PropTypes.object,
    data: PropTypes.object,
    date: PropTypes.object,
    defObj: PropTypes.object,
    defaultData: PropTypes.array,
    errors: PropTypes.object,
    isAsync: PropTypes.bool,
    width:PropTypes.string,
    name: PropTypes.string,
    positionId: PropTypes.any,
    getValues:PropTypes.func
};

export default PositionFormSection;