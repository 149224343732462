import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { getBaseTspmCoreURL } from '../../utils/getBaseUrls';
import { getRequest} from '../../services/axiosClient';
import InitiativeFormProvider from './InitiativeFormProvider';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import { setInitiativeConfig, initiativeConfig } from '../../redux/features/initiativeFormSlice';
import { useDispatch, useSelector} from 'react-redux';
import ErrorStatus from '../../components/common/ErrorStatus';
import logPageView from '../../utils/logPageView';


const InitiativeForm = () => {
    const params = useParams();
    const baseUrl = getBaseTspmCoreURL();
    const dispatch = useDispatch();
    const config = useSelector(initiativeConfig);

    /* COMPONENT STATE */
    const [formData, setFormData] =useState({fetched:false, 
        initiative:{name: '', type_id: '', owner_wwid: '', description:'', start_date:'', end_date:'', owner_wwid_dv:''}});
    const [error, setError] = useState({status:false, message:''});

    /**
      getting default values of initiative table
     */
    const getInitiativeData = async ()=>{
        const initiativeUrl = baseUrl + `/api/datasrcs/1/schemas/rmr/tables/rmr_initiative/records?display-value=true&id=${params.initiativeId}`;
        try {
            const res= await getRequest(initiativeUrl);
            const data =  res.data.data[0];
            setFormData({fetched:true, initiative:{name: data.name, type_id: data.type_id, owner_wwid: data.owner_wwid, description:data.description, start_date:data.start_date, end_date:data.end_date, owner_wwid_dv: data.owner_wwid_dv}});
        } catch (err) {
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting initiative data, please refresh page or try again later' + msg});
        }
    };

    /**
        getting config files for initiative table
     */
    const getInitiativeConfig = async ()=>{
        const initiativeConfigUrl = baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_initiative/config';
        try {
            
            const resConfig = await getRequest(initiativeConfigUrl);
            const data =  resConfig.data.columnConfig;
            dispatch(setInitiativeConfig({fetched:true, data:data}));
        } catch (err) {
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting initiative config, please refresh page or try again later' + msg});
        }
    };
    

    useEffect(() => {
        //we are getting initiatve value if id exists, if not we will use default empty form data.
        if(params.initiativeId !== 'new' && !formData.fetched){

            getInitiativeData();
        }
        if(!config.fetched){
            getInitiativeConfig();
        }
    }, [params.initiativeId]);
    useEffect(() => {
        logPageView();
    },[]);

    /* Checking if the initiativeId is new. If it is new, it will return the Initiative page with empty fields */
    if(params.initiativeId === 'new'){
        return <>
            <ErrorStatus error={error} setError={setError} /> 
            {config.fetched ? <InitiativeFormProvider initiative={formData.initiative} config={config.data} initiativeId={params.initiativeId}/> : <LoadingAnimation/>}
        </>;
    }
  
    return (
        <div>
            <ErrorStatus error={error} setError={setError} />
            {(formData.fetched && config.fetched) ? <InitiativeFormProvider initiative={formData.initiative} config={config.data} initiativeId={params.initiativeId}/> : <LoadingAnimation/>}
        </div>
    );
};

export default InitiativeForm;