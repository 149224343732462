import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import CustomAsyncSelectForHookForm from '../../components/common/customSelect/CustomAsyncSelectForHookForm';
import CustomSelectForHookForm from '../../components/common/customSelect/CustomSelectForHookForm';
import { Controller} from 'react-hook-form';
import { setPositionHeaderOptions, positionHeaderOptions,setPositionStatus, setPositionResource, positionResource} from '../../redux/features/positionFormSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getFirstDayOfDate } from '../../components/data';
import { getRequest } from '../../services/axiosClient';
import Select from 'react-select';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import ErrorStatus from '../../components/common/ErrorStatus';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { InputDatePicker } from '../../components/common/datePicker/CustomDatePicker';
import { parseISO } from 'date-fns';

/**
     * If the error type is minLength, return text Charachter length cannot exceed. 
     * Otherwise, return text This field is required.
    */
const errorMessage = (error, limit) => {
    if(error.type === 'maxLength'){
        return (
            <span className='position-form-error-msg'>
                This field length cannot exceed {limit} charachters
            </span>);
    }
    if(error.type === 'min'){
        return(
            <span className='position-form-error-msg'>
                Number cannot be less than 0
            </span>);
    }
    return (
        <span className='position-form-error-msg'>
            This field is required
        </span>);
};

const PositionFormHeader = props => {
    const {errors, config, control, position, register, baseUrl,setValue} = props;
    const [effectiveDate, setEffectiveDate] = useState('');
    const options = useSelector(positionHeaderOptions);
    const dispatch = useDispatch();
    const resource = useSelector(positionResource);
    const [error, setError] = useState({status:false, message:''});
    const urlPosDependent = baseUrl  +'/api/datasrcs/1/schemas/rmr/tables/rmr_position/records?fields-all=name,id&record-limit=10&search-fields=name,id&search-term=';
    
   
    /**
     * If the value of the is_required property of the object in the config array with the key of
     * colName is 1, return true, otherwise return false.
     */
    const getIsRequred = colName =>config[colName].is_required === 1 ? true : false;
    
    const defValue = position.dependent_position_id ?  [{[config.dependent_position_id.foreign_column_name]:position.dependent_position_id, [config.dependent_position_id.ref_display_value]:position.dependent_position_id_dv}] : [{}];

    //setting dates of other fields
    const handleDateChange = value => {
        const newDate = getFirstDayOfDate(value);
        setValue('rmr_position_resource.0.start_date', newDate);
        setValue('rmr_position_hierarchy.0.start_date', newDate);
        setValue('rmr_position_pay_grade.0.start_date', newDate);
        setValue('rmr_position_bravo_reportable.0.start_date', newDate);
        setValue('rmr_position_ldp.0.start_date', newDate);
        setValue('rmr_position_location.0.start_date', newDate);
        setValue('rmr_position_disposition_function.0.start_date', newDate);
        setValue('rmr_position_cost_center.0.start_date', newDate);
        setEffectiveDate(newDate);
        dispatch(setPositionResource({...resource, start_date:newDate}));
    };

    const getPositionOptions = async()=>{
        try {
            const resStatus = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_position_status/records?active_flag=true');
            const resDep = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_dependent_tag/records?active_flag=true');
            dispatch(setPositionHeaderOptions({
                fetched:true,
                rmr_position_status:resStatus.data.data, 
                rmr_dependent_tag:resDep.data.data, 
            }));
        } catch (err) {
            const msg = err?.request.statusText ? ` - ${err?.request.statusText}!` : '!';
            setError({status:true, message:'Error with getting position form options, please refresh page or try again later' + msg});
        }
    };
    useEffect(() => {
        if(!options.fetched){
            getPositionOptions();
        }
        if(position.position_status_id === 1){
            dispatch(setPositionStatus(true));
        } else {
            dispatch(setPositionStatus(false));
        }
    }, []);
    
    const handleStatusChange = (value,field) => {
        field.onChange(parseInt(value.id));
        if(value.id === 1){
            dispatch(setPositionStatus(true));
        } else{
            dispatch(setPositionStatus(false));
        }
        
    };

    return (
        <>
            <div className='position-form-table-group-0'>
                <div className='position-form-top' data-testid='position-header-form'>
                    {props.positionId ===null && <div className='position-form-label' style={{width:'150px'}}>
                        <span className='position-form-title'>Effective Date <span style={{color:'red'}}></span></span>
                        <DatePicker
                            // sets the selected date
                            selected={effectiveDate === '' ? null : parseISO(effectiveDate)} 
                            // change handler for the date picker
                            onChange={date => handleDateChange(date)} 
                            // custom input component
                            // this is only needed for to add test id
                            customInput={<InputDatePicker testId={'rmr_position_header'} value={effectiveDate} isDisabled={false}/>} 
                            placeholderText='mm/dd/yyyy'
                            showMonthDropdown 
                            showYearDropdown 
                            useShortMonthInDropdown
                            yearDropdownItemNumber={8}
                        />
                    </div>}
                    <label className='position-form-label' data-testid='rmr_position_name'>
                        <span className='position-form-title'>Position{getIsRequred('name') && <span style={{color:'red'}}>*</span>}</span>
                        <input 
                            type="text" 
                            placeholder="Position Name" 
                            {...register('position.name', {required: getIsRequred('name'), maxLength: config.name.character_maximum_length})} 
                            className='position-form-name' 
                            defaultValue={position.name}
                            name="position.name"
                        />
                        {errors?.position?.name && errorMessage(errors.position.name, config.name.character_maximum_length)}

                    </label>
                </div>
                
                <label className='position-form-label'>
                    <span className='position-form-title'>Comments {getIsRequred('comments') && <span style={{color:'red'}}>*</span>}</span>
                    <textarea 
                        {...register('position.comments', 
                            {required: getIsRequred('comments'), 
                                maxLength: config.comments.character_maximum_length})} 
                        defaultValue={position.comments} 
                        name='position.comments'
                    />
                    {errors?.position?.comments && errorMessage(errors.position.comments, config.comments.character_maximum_length)}
                </label> 
            </div>
            <div className='position-form-table-group'>
                <ErrorStatus error={error} setError={setError}/>
                <div className='position-status'>
                    <label className='position-form-label' data-testid='position-status-id'>
                        <span className='position-form-title'>Position Status {getIsRequred('position_status_id') && <span style={{color:'red'}}>*</span>}</span>
                        {options.fetched ?<Controller
                            name="position.position_status_id"
                            control={control}
                            defaultValue={position.position_status_id}
                            rules={{ required: getIsRequred('position_status_id') }}
                            render={({field}) => (
                                <Select        
                                    options={options.rmr_position_status}
                                    onChange={value => handleStatusChange(value, field)}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    ref={field?.ref}
                                    name='position.position_status_id'
                                    value={options.rmr_position_status.find(c => c.id === field.value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    onBlur={field.onBlur}
                                    inputuRef={field.ref}
                                /> 
                            )}/>: <div className='select-placeholder' style={{width: 'inherit', height: '38px'}}> <LoadingAnimation type={'dot'}/></div>}
                            
                        {errors?.position?.position_status_id && errorMessage(errors.position.position_status_id,'')}
                    </label>
                    <label className='position-form-label'>
                        <span className='position-form-title'> Headcount Request Number {getIsRequred('headcount_request_number') && <span style={{color:'red'}}>*</span>}</span>
                        <input 
                            type="number" 
                            {...register('position.headcount_request_number', {required: getIsRequred('headcount_request_number'), min:0})} 
                            className='position-input-number'
                            defaultValue={position.headcount_request_number}
                            data-testid='headcount-request-number'
                        />
                        {errors?.position?.headcount_request_number && errorMessage(errors.position.headcount_request_number, 'min')}
                    </label>
                </div>
                <div className='position-status'>
                    <label className='position-form-label' data-testid='dependent-id'>
                        <span className='position-form-title'>Dependent Position ID {getIsRequred('dependent_position_id') && <span style={{color:'red'}}>*</span>}</span>            
                        <Controller
                            name="position.dependent_position_id"
                            control={control}
                            defaultValue={position.dependent_position_id}
                            rules={{ required: getIsRequred('dependent_position_id') }}
                            render={field=> (
                                <CustomAsyncSelectForHookForm  
                                    urlReq={urlPosDependent} 
                                    defValue={defValue} 
                                    defaultKeyNames={{label:'name', value: 'id'}} 
                                    field={field} 
                                    name='position.dependent_position_id' 
                                    hideId={false}
                                    isClearable={true}
                                />
                                    
                            )}/>
                       
                   
                        {errors?.position?.dependent_position_id && errorMessage(errors.position.dependent_position_id, '')}
                    </label>
                    <label className='position-form-label'>
                        <span className='position-form-title'>Dependent Tag {getIsRequred('dependent_tag_id') && <span style={{color:'red'}}>*</span>}</span>
                        {options.fetched ? <Controller
                            name="position.dependent_tag_id"
                            control={control}
                            defaultValue={position.dependent_tag_id}
                            rules={{ required: getIsRequred('dependent_tag_id') }}
                            render={ field => (
                                <CustomSelectForHookForm 
                                    options={options.rmr_dependent_tag}
                                    field={field}
                                    defValue={position.dependent_tag_id} 
                                    name={'position.dependent_tag_id'} 
                                    defaultKeyNames={{label:'name', value:'id'}} 
                                    hideId={true}
                                    isClearable={true}
                                />
                            )}/> : <div className='select-placeholder' style={{width: 'inherit', height: '38px'}}> <LoadingAnimation type={'dot'}/></div>}
                        {errors?.position?.dependent_tag_id && errorMessage(errors.position.dependent_tag_id,'')}
                    </label>
                </div>
            </div>
        </>
    );
};
PositionFormHeader.propTypes = {
    errors: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    control: PropTypes.object.isRequired,
    position: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    baseUrl: PropTypes.string.isRequired,
    positionId:PropTypes.any,
    setValue: PropTypes.func.isRequired,
    
};

export default PositionFormHeader;